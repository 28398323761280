import { SOCURE_CODE_DESCRIPTIONS_LIST } from "./socureCodeDescriptions";

export const EN = {
  ACCOUNT: {
    ACCOUNT_DELETED: "Your account has been deleted",
    ACCOUNT_UPDATED: "Account has been updated",
    ASSIGNED_CLIENTS: "Assigned Clients",
    ASSIGNED_WORKFLOWS: "Assigned Workflows",
    CALENDAR_LINK: "Calendar Link",
    CALENDAR_LINK_SUBTITLE:
      "Add the employee's calendar link here, so users can schedule a meeting.",
    COMPANY_NAME_WEBSITE: "Company Name and Website",
    DELETE_ACC_BUTTON_TEXT: "Delete Account",
    DELETE_MODAL_TEXT:
      "Your user and users related with your account will be permanently removed from Lendflow.",
    DELETE_MODAL_TITLE: "Delete account?",
    EMPLOYEE_ROLES: "Employee roles",
    PASSWORD: "Password",
    PASSWORD_CHANGED_SUBTITLE: "Your new password has been successfully saved",
    PASSWORD_CHANGED_TITLE: "Password Changed",
    PENDING_EMAIL:
      "Email change pending. Confirmation email has been sent to {email}.",
    PRIMARY_ACCOUNT: "Primary Account | Primary Accounts",
    SUBTITLE: "This page will allow you to configure your personal settings",
    TITLE: "Account",
    USER_INFO: "User Information",
    USER_INFORMATION: "User Information",
    USER_ROLES: "User roles"
  },
  ACTION: {
    CREATE: "Create"
  },
  ACTIVITY: {
    ACTIVITY_HUB: "Activity Hub",
    COMMENTS: {
      COMMENT_PLACEHOLDER: "Enter Comment",
      EMPTY: "There are no comments posted at the moment.",
      RETURN_PLACEHOLDER: "to reply",
      TITLE: "Comment|Comments"
    },
    CALLS: {
      BAD_NUMBER: "Bad Number",
      DO_NOT_CONTACT: "Do Not Contact",
      MARK: "Mark",
      MARK_NUMBER_AS: "Mark Number As",
      MODAL_BLOCK_BODY_END:
        "would be applied to that number for Lendflow only. You will no longer be able to call this number or remove this status without admin override.",
      MODAL_BLOCK_BODY_START:
        "Are you sure you want to {action} this number as",
      UNMARK: "Unmark",
      UNMARK_NUMBER_AS: "Unmark Number As"
    },
    CONTACTS: {
      BUSINESS: "Business",
      CALLED: "Called:",
      CONTACTS_ATTACHED: "Contacts attached into the deal",
      TO_LOG_A_CALL: "to log a call",
      NO_CONTACT_INFO: "No contact information",
      NO_CONTACT: "No contact | No contacts",
      NO_CONTACTS_ARE_AVAILABLE:
        "@:ACTIVITY.CONTACTS.NO_CONTACTS are available.",
      NO_LOGS_FOR: "No logs for {name}",
      NO_PHONE_NUMBER: "No phone number",
      OWNER: "Owner {number}",
      REACHED: "Reached:",
      UNREACHABLE: "Unreachable:",
      WRITE_NOTES_ABOUT_THIS_CALL: "Write notes about this call"
    },
    EMAIL: {
      FROM: "@:COMMON.EMAIL @:COMMON.FROM"
    },
    FILTERS: {
      ASSIGNEE: {
        TITLE: "Assignee",
        ME: "Me",
        NONE: "No Assignee",
        PLACEHOLDER: "Select Assignee | Assignees Selected"
      },
      CALLS: {
        CALLER: "Caller",
        DATE: "Call Date",
        PLACEHOLDER: "Select Caller | Callers Selected"
      },
      DEAL: {
        TITLE: "Deal",
        PLACEHOLDER: "Select Deal | Deals Selected"
      },
      DUE_AT: "Due at",
      DUE_DATE: "Due Date",
      EMAILS: {
        DATE: "Email Date",
        RECIPIENT: "Recipient",
        RECIPIENT_PLACEHOLDER: "Select Recipient | Recipients Selected",
        SENDER: "Sender",
        SENDER_PLACEHOLDER: "Select Sender | Senders Selected"
      },
      PRIORITY: "Priority",
      SECTION_NAME: "@:COMMON.QUICK @:COMMON.FILTERS",
      SMS: {
        DATE: "SMS Date"
      },
      SORT_FILTERS: "Sort by",
      SORT_ORDER: "Sort order",
      SORT_OPTIONS: {
        CREATED_AT_DESC: "Date created (newest -> oldest)",
        CREATED_AT_ASC: "Date created (oldest -> newest)",
        DUE_DATE_DSC: "Due date (newest -> oldest)",
        DUE_DATE_ASC: "Due date (oldest -> newest)"
      },
      STATUS: "Select Status | Statuses Selected",
      TYPE: "Select Type | Types Selected"
    },
    MANAGE_CALLS: "Manage your calls here",
    MANAGE_COMMUNICATION: "Manage communication activity here",
    MANAGE_TASKS: "Manage your tasks here",
    NEW_ACTIVITY_DETECTED: "New Activity Detected!",
    NO_ACTIVITIES_LOGGED: "No Activities Logged",
    PAUSE_RECORDING: "Pause Recording",
    PLAY_RECORDING: "Play Recording",
    PLAYBACK_SPEED: "Playback Speed",
    SELECT_CALL_DISPOSITION: "Set Call Disposition",
    SMS: {
      FROM: "SMS from",
      MESSAGE_PLACEHOLDER: "Type an SMS here…",
      RETURN_PLACEHOLDER: "To send",
      STATUS: {
        DELIVERED: "Delivered",
        SENDING: "Sending",
        SENT: "Sent",
        FAILED: "Failed"
      },
      SUCCESS_MESSAGE: "SMS has been submitted successfully",
      TO: "SMS to"
    },
    TABS: {
      ALL: "All Activity",
      CALLS: "Calls",
      EMAILS: "Emails",
      TASKS: "Tasks",
      SMS: "SMS"
    },
    TASK: {
      ADD_TASK: "Add Task",
      ASSIGNEE: "Assignee {name}",
      DATE_AND_TIME: "Due Date",
      DEAL_NAME: "Deal name",
      DESCRIPTION_IS_REQUIRED: "Description is required",
      DONE: "Done",
      DUE_DATE_IS_REQUIRED: "Due date is required",
      FROM: "Task from: ",
      IN_PROGRESS: "In Progress",
      NEW_TASK: "New Task",
      TASK_DESCRIPTION: "Task Description",
      TO_DEAL: "Deal",
      TO_DO: "To Do"
    },
    UNSAVED_CHANGES_WARNING: "You have unsaved changes!",
    VIEW_LATEST_UPDATES: "View the latest updates"
  },
  ANALYTICS: {
    DESCRIPTION: "Analytics Service Report",
    EMPTY: "No Reports to show",
    TITLE: "Analytics"
  },
  APPLICATION_STAGES: {
    ADDTIONAL_BUSINESS_INFORMATION: "Additional Business Information",
    BUSINESS_INFORMATION: "Business Information",
    COMPLETE: "COMPLETE",
    COPY_ALL_DATA: "@:COMMON.COPY @:COMMON.ALL @:COMMON.DATA",
    COPY_DATA: "@:COMMON.COPY @:COMMON.DATA",
    PERSONAL_INFORMATION: "Personal Information",
    SAVE_DATA_MESSAGE: "Data has been successfully copied to the file!"
  },
  ATTRIBUTE_BUILDER: {
    ATTRIBUTE: "Attribute",
    ATTRIBUTES: "Attributes",
    ATTRIBUTE_BUILDER: "@:ATTRIBUTE_BUILDER.ATTRIBUTE Builder",
    ATTRIBUTE_ID: "Attribute ID",
    ATTRIBUTE_NAME: "Attribute Name",
    ATTRIBUTE_TEMPLATES: "@:ATTRIBUTE_BUILDER.ATTRIBUTE Templates",
    CLICK_TO_JSON: "@:COMMON.CLICK_TO view JSON",
    CONTACT_YOUR_MANAGER:
      "Contact your Custom Success Manager to discuss plans.",
    CREATE_CUSTOM_DATA_ATTRIBUTE: "Create Custom Attributes",
    CREATE_DESCRIPTION: "Create your custom attributes here.",
    CUSTOM_ATTRIBUTES: "@:GENERAL.CUSTOM @:ATTRIBUTE_BUILDER.ATTRIBUTES",
    DESCRIPTION: "Manage your custom attributes here.",
    DEAL_ID_EVALUATION: "Deal ID or deal name for evaluation",
    EVALUATION_DESCRIPTION:
      "Enter a deal ID or a deal name in the box below to test your attribute and hit the button to see the calculation result",
    FORMULA: "Formula",
    FORMULA_PLACEHOLDER: "Type in attribute, number or operation",
    LEVERAGE_DATA_ATTRIBUTES:
      "Leverage your own data or use data provider results to create custom calculations. If you’d like access, please reach out to your customer success manager.",
    NO_ACTIVE_ATTRIBUTES:
      "@:COMMON.NO @:COMMON.ACTIVE @:ATTRIBUTE_BUILDER.ATTRIBUTES @:COMMON.HERE",
    OFFER_TEMPLATE: "Offer Template",
    OFFER_TEMPLATES: "Offer Templates",
    PLACEHOLDER: "@:COMMON.CLICK_TO start typing or paste your formula here",
    STATIC: "Static",
    SUBMITTED_BY_API: "This value is submitted through API",
    SUBMITTED_VIA: "@:GENERAL.CUSTOM @:ATTRIBUTE_BUILDER.ATTRIBUTE via {type}",
    TOUR: {
      ADD_MORE_ATTRIBUTES: "Add More Attributes",
      ADD_MORE_ATTRIBUTES_DESCRIPTION:
        "Create multiple attributes all within this screen.",
      CREATE_NEW_FORMULA: "Create New Formula",
      CREATE_NEW_FORMULA_DESCRIPTION:
        "Start by pasting in your formula from your spreadsheet and altering from there. Alternatively, start from scratch by typing in a formula and selecting the relevant data provider attributes.",
      HOW_TO_USE: "How To Use Attribute Builder",
      HOW_TO_USE_DESCRIPTION:
        "Create custom calculations using data provider results.",
      PUBLISH_IT: "Publish It!",
      PUBLISH_IT_DESCRIPTION:
        "To use an attribute in an application or as part of decisioning, it must be published."
    },
    USAGE_DESCRIPTION:
      "Create custom calculations using data provider results. Start by pasting in your formula from your spreadsheet and altering from there. Alternatively, start from scratch by typing in a formula and dragging in the relevant data provider attributes.",
    VALIDATION_MESSAGES: {
      needContext: "Context required for attribute",
      notEmpty: "Formula cannot be empty",
      notEndWithOperator: "Formula cannot end with operator",
      notStartWithOperator: "Formula cannot start with operator",
      notTwoOperandsInARow: "Formula cannot have two operands in a row",
      notTwoOperatorsInARow: "Formula cannot have two operators in a row"
    }
  },
  AUDIT: {
    CREATED: "Created",
    LAST_UPDATE: "Last Update",
    LAST_UPDATED: "Last Updated"
  },
  AUTH: {
    CHECK_MAIL:
      "Due to security reasons we need to verify your identity and approve your access to Lendflow. Please review the email that was just sent to you for instructions on next steps.",
    CHECK_PASS_RESET: "Check your mailbox for password reset link",
    CLICK_HERE_LOGIN: "Click here to sign in to your account",
    CREATE_ACCOUNT: "Create Account",
    CREATE_LENDFLOW_ACCOUNT: "Create your Lendflow account",
    CREATE_PASSWORD_SUBTITLE: "Create a new password for your account",
    CREATE_PASSWORD_TITLE: "Create Your Password",
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    EMAIL_SENT: "Thank you for registering with Lendflow",
    ENTER_EMAIL: "Please enter your email to continue",
    FAILED: "Your email/password combination is incorrect",
    FORGOT_PASS: "Forgot your password?",
    FORGOT_PASS_SUBTITLE:
      "Enter your  verified email address and we will send you a password reset link",
    FORGOT_PASS_TITLE: "Forgot your password?",
    GET_STARTED: "Get Started",
    GO_LOGIN: "Go to Login",
    HAVE_AN_ACCOUNT: "Have an account?",
    INVALID_PERMISSION_REQUEST: "Invalid permission request",
    LOGIN: "LOGIN",
    LOGIN_PAGE: "login page",
    LOGIN_PROMOTIONAL_FUNDER_TEXT:
      "Increase your revenue by providing capital through Lendflow Platform!",
    LOGIN_PROMOTIONAL_TEXT:
      "The easiest way to embed lending services into your product.",
    LOGIN_WITH: "Log in with",
    LOGOUT: "Log out",
    REGISTER: "Register",
    REMEMBER_ME: "Remember Me",
    RENEW_PASSWORD_SUBTITLE: "Please create a new password",
    RENEW_PASSWORD_TITLE: "Your password has expired",
    RESET_INVALID: "Reset token or email invalid.",
    RESET_PASSWORD: "Reset Password",
    RESET_PASSWORD_SUBTITLE: "Enter your new password",
    RESET_PASSWORD_TITLE: "Reset your Password",
    RESET_SUCCESS_1: "Password Changed",
    RESET_SUCCESS_2: "Your password has been changed successfully",
    RETURN_TO: "Return to ",
    SEND_PASS_RESET: "Send Password Reset Email",
    SIGN_IN: "Sign in",
    VERIFICATION_EMAIL_SENT:
      "Verification email has been sent to provided email",
    VPN_REQUIRED:
      "You are unable to access this site because you are currently not connected to VPN. Please connect to VPN to access this site."
  },
  BORROWER_PLATFORM: {
    DEAL_STATE: "Deal State",
    REDIRECT_LINK: "Redirect Link",
    REDIRECT_TYPE: "Redirect Type",
    OFFER_AMOUNT: "Offer Amount",
    SHOW_DETAILS: "Show details",
    STAGE_TOOLTIP: "Stage Tooltip",
    STATES: {
      APPLICATION_COMPLETE: "Application Complete",
      APPLICATION_INCOMPLETE: "Application Incomplete",
      DEAD: "Dead",
      FUNDED: "Funded",
      NO_OFFERS: "No Offers",
      NO_PLACEMENTS: "No Placements",
      OFFER_ACCEPTED: "Offer Accepted",
      OFFER_RECEIVED: "Offer Received",
      OFFER_STIPS_COMPLETE: "Offer Stips Complete",
      OFFER_STIPS_INCOMPLETE: "Offer Stips Incomplete",
      PLACEMENT_SUBMITTED: "Placement Submitted",
      SCORECARDS: "Scorecards",
      UNDERWRITING: "Underwriting"
    },
    STATE_DESCRIPTION: "State Description",
    STATE_TITLE: "State Title",
    TERM_OF_LOAN: "Term of Loan",
    TERM_TIMEFRAME: "Term Timeframe",
    TITLE: "Borrower Platform",
    TOUR: {
      COMBINE_STAGES: "Combine Stages",
      COMBINE_STAGES_DESCRIPTION:
        "If you prefer not to display all your workflow stages to the applicant, you can merge multiple stages into one which will be displayed on the Borrower Platform. Simply hover over the stages you want to merge and click the 'Merge' button.",
      CONFIGURE_STAGES:
        "Configure the stages and text displayed to the applicant on the Borrower Platform.",
      DISPLAY_CUSTOM_VERSION: "Display Custom Version",
      DISPLAY_CUSTOM_VERSION_DESCRIPTION:
        "After you configure a custom borrower platform template, toggle the switch on to activate it."
    },
    WEEKLY: "Weekly"
  },
  BUSINESS_PROFILES: {
    ADD_NEW_BUSINESS: "Add New Business",
    ADD_BUSINESS: "Add Business",
    ANALYST: "Analyst",
    BANK_DATA: "Bank Data",
    BUSINESS: {
      ADDITIONAL_BUSINESS_INFO: {
        BUSINESS_MANAGEMENT_SOFTWARE: "Business Management Software",
        FISCAL_YEAR_END: "Fiscal Year End (Month)",
        INDUSTRY: "Industry",
        NAICS_CODE: "NAICS code",
        NUM_OF_EMPLOYEES: "Number of Employees",
        SIC_CODE: "SIC code",
        SUB_INDUSTRY: "Sub Industry",
        TITLE: "Additional Business Information",
        WEBSITE: "Business Website"
      },
      BUSINESS_INFO: {
        BUSINESS_NAME: "Business Name",
        BUSINESS_START_DATE: "Business Start Date: (yyyy-mm-dd)",
        BUSINESS_STRUCTURE_TYPE: "Business Structure Type",
        COUNTRY_ERROR:
          "One or more of the specified addresses lack a designated country. Please select one of the options:",
        DBA: "DBA",
        EIN: "EIN",
        SECONDARY_ADDRESS: "Secondary Address",
        STATE_OF_INCORPORATION: "State of Incorporation",
        TITLE: "Business Information"
      },
      CHILD_TYPE: "{childType} type",
      CREATE_BSINESS_ENTITY: "Create Business Entity"
    },
    BUSINESS_INFO: "Business Info",
    CANT_VIEW_PROFILE: "You don't have permission to view this profile",
    DEALS: "Deals ({dealCount})",
    DEALS_TABLE_HEADER: {
      APP_STARTED: "App Started",
      DEALS: "Deals",
      DEAL_STAGE: "Deal Stage",
      LAST_UPDATED: "Last Updated",
      ORGANIZATION: "Organization",
      WORKFLOW: "Workflow"
    },
    DELETE_OWNER: "Delete Owner",
    DELETE_OWNER_PROMPT: "{owner} will be permanently removed. Are you sure?",
    DOCUMENTS: "Documents",
    FUNDING_ADVISOR: "Onboarding",
    INDIVIDUAL: {
      ADD_NEW_INDIVIDUAL: "Add New Individual",
      ADDITIONAL_INFORMATION: "Additional individual information",
      CITIZEN_CLASS: "Citizen Class",
      CREDIT_PERMISSION: "Credit Permission",
      CREATE_INDIVIDUAL_ENTITY: "Create Individual Entity",
      DRIVERS_LICENSE_NUMBER: "Driver's License Number",
      DRIVERS_LICENSE_STATE: "Driver's License State",
      INDIVIDUAL_INFORMATION: "Individual information",
      JOB_TITLE: "Job Title",
      LANGUAGE: "Language",
      PERSONAL_INCOME: "Personal Income",
      SSN: "Social Security Number",
      VISIT_INDIVIDUAL_PROFILE: "View {individual} Profile"
    },
    MAIN_ROUTE_NAME: "Businesses",
    NAME: "Business",
    NO_RESULTS_BODY:
      "List of businesses appears on this page when your clients fill in the first step of Lendflow widget or when you add a new deal.",
    NO_RESULTS_TITLE: "Business list is empty",
    OWNERSHIP: {
      ADD_OWNER: "Add Owner",
      DELETE_REQUIRED_MSG: "At least one owner is required",
      EDIT_OWNERSHIP: "Edit Ownership",
      HEADER: {
        BUSINESSES_COUNT: "Businesses",
        CURRENT_PERCENTAGE: "Current % ownership",
        DEALS_COUNT: "Deals",
        NEW_PERCENTAGE: "New % ownership",
        OWNED_BUSINESSES: "Owned Businesses",
        OWNERS: "Owners",
        PERCENTAGE: "Company Ownership",
        TYPE: "Type"
      },
      MANAGE_OWNERSHIP: "Manage Ownership",
      NO_OWNERS: "No Owners attached here",
      OVER_100: "Ownership over 100%",
      TITLE: "Owners"
    },
    PERSONAL_INFO: "Personal Info",
    SEARCH_ANALYST: "Search Analyst",
    SEARCH_FUNDING_ADVISOR: "Search Onboarding",
    SEARCH_TERM: "for businesses",
    SEARCH_UNDERWRITER: "Search Processor",
    SEARCH_WORKFLOW: "Search Workflow",
    TABLE_HEADER: {
      APPS: "Apps",
      ASSIGNEE: "Assignee",
      ASSIGNEES: "Assignees",
      BUSINESS_NAME: "Business",
      CITIES: "Cities",
      CITY: "City",
      CLIENT: "Client",
      CLIENTS: "Clients",
      COUNTRIES: "Countries",
      COUNTRY: "Country",
      FIRST_APP_DATE: "1st App Date",
      IMPERSONATE: "Impersonate",
      INDUSTRIES: "Industries",
      INDUSTRY: "General Industry",
      LAST_CALL: "Last Call",
      LAST_UPDATE: "Last Update",
      STATE: "State/Province",
      STATES: "States/Provinces"
    },
    TITLE: "Business Profiles",
    UNDERWRITER: "Processor",
    WORKFLOW: "Workflow"
  },
  CLIENT_CREDENTIALS: {
    ACCOUNT: "@:ACCOUNT.TITLE",
    ACCOUNT_OPENING_KEY: "Account Opening Key",
    ADDRESS_RISK_KEY: "Address Risk Key",
    ALLOW_SUBMISSION_ON_DECLINE: "Allow Submission on Decline",
    API_KEY: "API Key",
    API_PASSWORD: "API Password",
    API_USERNAME: "API Username",
    APP_TOKEN: "@:COMMON.APPLICATION @:CLIENT_CREDENTIALS.TOKEN",
    APPLY_CREDENTIALS_ALSO_TO: "Apply credentials also to:",
    AUTHORIZATION: "Authorization Token",
    BROKER_ID: "Broker ID",
    CAN_POSTPONE_APPROVAL: "Can Postpone Approval",
    CERT_PASSWORD: "Cert Password",
    CERTIFICATE_PASSWORD: "Certificate Password",
    CLIENT_ID: "Client ID",
    CLIENT_SECRET: "Client Secret",
    CONFIG_ID: "Config ID",
    DL_PURPOSE: "DL Purpose",
    EIDV_REQUEST_BUSINESS_NAME: "Business Definition",
    EIDV_REQUEST_PERSON_NAME: "Person Definition",
    GLB_PURPOSE: "GLB Purpose",
    GRANT_TYPE: "Grant @:COMMON.TYPE",
    ID: "ID",
    IDENTITY_CHECK_KEY: "Identity Check Key",
    INQUIRY_SUBSCRIBER_PREFIX_CODE: "Inquiry Subscriber Prefix Code",
    INTEGRATION_KEY: "Integration Key",
    IS_ACTIVE: "Is Active",
    IS_NEW_MIDDESK_ACCOUNT: "Account is created before August 2023",
    KEY: "@:COMMON.KEY",
    PARTNER_EMAIL: "@:COMMON.PARTNER @:COMMON.EMAIL",
    PARTNER_KEY: "@:COMMON.PARTNER @:COMMON.KEY",
    PASSWORD: "@:ACCOUNT.PASSWORD",
    PERMISSIBLE_PURPOSE: {
      DPPA: "DPPA Code",
      GLB: "GLB Code",
      VOTER: "VOTER Code"
    },
    PHONE_RISK_KEY: "Phone Risk Key",
    REVERSE_ADDRESS_KEY: "Reverse Address Key",
    REVERSE_EMAIL_KEY: "Reverse Email Key",
    REVERSE_PHONE_KEY: "Reverse Phone Key",
    RISK_INFORM_BUSINESS_DEF_NAME: "Risk Inform Business Definition",
    RISK_INFORM_PERSON_DEF_NAME: "Risk Inform Person Definition",
    SECRET: "Secret",
    SUBSCRIBER_CODE: "Subscriber Code",
    SUBSCRIBER_PASSWORD: "Subscriber Password",
    TOKEN: "Token",
    TRACK_ID: "Track ID",
    TRANSACTIONS_RISK_KEY: "Transaction Risk Key",
    USER_ID: "User ID",
    USERNAME: "Username",
    URL: "@:COMMON.URL",
    VENDOR_ID: "Vendor ID",
    WARNING_CONTACT_MESSAGE:
      "After submitting your credentials, contact the Lendflow and {service} teams to subscribe to webhooks.",
    WARNING_WEBHOOK_CODAT_MESSAGE:
      "Please configure the webhook in the Codat dashboard to finish the integration.",
    WORKFLOW_TEMPLATE_ID: "Workflow Template ID"
  },
  COMBINATION: {
    SEND_EMAIL: "@:COMMON.SEND @:COMMON.EMAIL"
  },
  COMMON: {
    ACCESSIBLE_TO: "Accessible to",
    ACCESS_NOT_ENABLED:
      "Access to this service is not enabled. For support please contact your account manager.",
    ACCESS_RESTRICTED: "Access Restricted",
    ACCEPTED: "Accepted",
    ACCOUNT: "Account | Accounts",
    ACTIONS: "Actions",
    ACTIVATE: "Activate",
    ACTIVE: "Active",
    ACTIVITY: "Activity | Activities",
    ADD: "Add",
    ADDRESS: "Address | Addresses",
    ADD_EMAIL: "Add Email",
    ADD_NEW_CUSTOMER: "Add New Customer",
    ADD_NEW_DEBT: "Add New Company Debt",
    ADD_NEW_OFFICER: "Add New Company Officer",
    ADD_NEW_RECORD: "Add New Record",
    ADD_NEW_TRADE_REFERENCE: "Add New Trade Reference",
    ADD_ON_PRODUCT: "Add-on Product | Add-on Products",
    ADD_PHONE: "Add Phone",
    ADMIN: "Admin",
    ADVANCED: "Advanced",
    AGE: "Age",
    AGO: "ago",
    ALIAS: "Alias | Aliases",
    ALL: "All",
    ALL_OWNERS: "All Owners",
    AMOUNT: "Amount",
    ANALYST: "Analyst",
    AND: "and",
    APPEND_COPY_TEXT: "(copy)",
    APPLICANT: "Applicant | Applicants",
    APPLICATION: "Application",
    APPLY: "Apply",
    AVAILABLE: "Available",
    AVAILABLE_BALANCE: "@:COMMON.AVAILABLE @:COMMON.BALANCE",
    ASSIGN: "Assign",
    ASSIGNEE: "Assignee",
    ASSIGNMENT: "Assignment",
    ATTENTION: "Attention",
    AVERAGE: "Average",
    BACK: "Back",
    BALANCE: "Balance",
    BANK: "Bank",
    BETA: "Beta",
    BIRTHDAY: "Date of Birth",
    BLOCKS: "Blocks",
    BORROWER: "Borrower",
    BOTH: "Both",
    BRANDING: "Branding",
    BUILDER: "Builder",
    BUSINESS: "Business",
    BUSINESS_ID: "Business ID",
    BUSINESS_NAME: "Business Name",
    BUSINESS_PHONE: "Business Phone",
    BUTTON: "Button | Buttons",
    CALL: "Call",
    CANCEL: "Cancel",
    CATEGORY: "Category",
    CHANGE: "Change | Changes",
    CHANGE_VALUE: "Change Value",
    CHECKBOX: "Checkbox | Checkboxes",
    CHOOSE: "Choose",
    CITY: "City",
    CLEAR: "Clear",
    CLEAR_ALL: "Clear All",
    CLEAR_FILTER: "Clear Filter | Clear Filters",
    CLICK: "Click",
    CLICK_TO: "Click to",
    CLICK_TO_COPY: "@:COMMON.CLICK_TO Copy",
    CLICK_TO_GENERATE: "Click button to generate new link",
    CLICK_TO_PREVIEW: "@:COMMON.CLICK @.lower:COMMON.TO @.lower:COMMON.PREVIEW",
    CLICK_TO_PREVIEW_EXIT:
      "@:COMMON.CLICK @.lower:COMMON.TO @.lower:COMMON.EXIT @.lower:COMMON.PREVIEW",
    CLIENT: "Client",
    CLIENTS: "Clients",
    CLIENT_NAME: "Client Name",
    CLIENT_OWNER: "Client Owner",
    CLOSE: "Close",
    CODE: "Code | Codes",
    CODE_FOR_WIDGET: "Code for Widget",
    COLLAPSE_TABLES_TOOLTIP: "Collapse All Tables",
    COLOR: "Color",
    COLUMNS: "Column|Columns",
    COMMUNICATION: "Communication",
    COMPANY: "Company",
    COMPANY_EMAIL: "@:COMMON.COMPANY Email",
    COMPANY_NAME: "@:COMMON.COMPANY Name",
    COMPANY_WEBSITE: "@:COMMON.COMPANY Website",
    COMPLETE: "COMPLETE",
    CONFIDENCE: "Confidence",
    CONFIRM: "Confirm",
    CONFIRMATION_QUESTION: "Are you sure?",
    CONFIRM_NEW_PASSWORD: "Confirm New Password",
    CONNECT: "Connect",
    CONSOLE_WARNING: "WARNING",
    CONSOLE_WARNING_DESC_1:
      "Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS",
    CONSOLE_WARNING_DESC_2:
      "Do not enter or paste code that you do not understand",
    CONTACT: "Contact",
    CONTACTS: "Contacts",
    CONTACT_NAME: "Contact Name",
    CONTEXT: "Context",
    CONTINUE: "Continue",
    COPIED: "Copied to clipboard",
    COPY: "Copy",
    COPY_LINK_BELOW: "Copy the link below",
    COPY_TO_CLIPBOARD: "@:COMMON.COPY {type} to Clipboard",
    COUNTRIES: "Countries",
    COUNTRY: "Country",
    COUNTY: "County",
    COPY_LINK: "Copy link",
    COPIED_LINK_FOR: "Link for {name} copied to clipboard",
    CREATE: "Create",
    CREATED_AT: "Created At",
    CREATED_BY: "Created By",
    CURRENT: "Current",
    CURRENT_BALANCE: "@:COMMON.CURRENT @:COMMON.BALANCE",
    CUSTOM: "Custom",
    CUSTOMER: "Customer",
    DATA: "Data",
    DATA_PROVIDERS: "Data Providers",
    DATE: "Date",
    DATES: {
      DATEPICKER_TYPE: {
        RANGE: "Range",
        MONTH: "Month|Months",
        DYNAMIC: "Dynamic"
      },
      LAST_WEEK: "Last Week",
      LAST_MONTH: "Last Month",
      LAST_THIRTY_DAYS: "Last 30 Days",
      LAST_SIX_MONTHS: "Last 6 Months",
      LAST_YEAR: "Last Year",
      LAST_365_DAYS: "Last 365 Days",
      PERIOD: "Period",
      PRIOR: "Prior",
      THIS_WEEK: "This Week",
      THIS_MONTH: "This Month",
      THIS_QUARTER: "This Quarter",
      THIS_YEAR: "This Year",
      TODAY: "Today",
      YESTERDAY: "Yesterday"
    },
    DATE_OF_BIRTH: "Date Of Birth",
    DATE_UPDATED: "Date Updated",
    DAY: "Day | Days",
    DAYS: "Days",
    DEAD: "Dead",
    DEAL: "Deal",
    DEAL_LINK: "Deal Link",
    DEALS: "Deals",
    DECLINE: "Decline",
    DEFAULT: "Default",
    DEFAULT_TEMPLATE: "Default Template",
    DELETE: "Delete",
    DELETED: "Deleted",
    DELETE_CONFIRMATION: "Are you sure you want to delete this {source}?",
    DELETE_OFFER: "Delete Offer",
    DELETE_OFFER_DESCRIPTION: "This offer will be permanently deleted.",
    DELETE_PERMANENTLY: "Delete Permanently",
    DEPOSITS: "Deposits",
    DESCRIPTION: "Description",
    DESELECT_ALL: "Deselect All",
    DETAIL: "Detail | Details",
    DETAILS_ADDED: "Details Added",
    DIGITS: "Digits",
    DISABLED: "Disabled",
    DISCONNECT: "Disconnect",
    DISMISS: "Dismiss",
    DOB: "DOB",
    DOB_FORMAT: "DOB ({format})",
    DOCUMENT: "Document",
    DOCUMENTS: "Documents",
    DOLLAR_SIGN: "$",
    DOMAIN: "Domain | Domains",
    DONE: "Done",
    DOWNLOAD: "Download",
    DOWNLOAD_DOCUMENT:
      "@:COMMON.DOWNLOAD @:COMMON.DOCUMENT | @:COMMON.DOWNLOAD @:COMMON.DOCUMENTS",
    DOWNLOAD_ERROR_FILE: "An error occurred while trying to download the files",
    DOWN_FROM: "Down from\n{dateFrom} - {dateTo}",
    DRAFT: "Draft",
    DROP_TO_UPLOAD: "Drop file to upload",
    DUPLICATE: "Duplicate",
    DYNAMIC_FIELDS: "Dynamic Fields",
    EDIT: "Edit",
    EIN: "EIN",
    EMAIL: "Email | Emails",
    EMAIL_CHANGE_PENDING: "Email change pending.",
    EMAIL_INVALID: "Email is invalid",
    EMAIL_OFFER: "Email Offer",
    EMAIL_SENT: "Email Sent",
    EMBEDDED: "Embedded",
    ENABLED: "Enabled",
    END_DATE: "End Date",
    ENTER_EMAIL: "Enter Email",
    ENTER_NAME: "Enter Name",
    ENTER_URL: "Enter URL",
    EARLIER: "Earlier",
    ERROR: "Error",
    ERROR_MSG_UNAVAILABLE: "Error message unavailable",
    ERROR_MODEL_REQUIRED: "An error has occurred - no model was provided",
    ERROR_OCCURRED: "An error has occurred. Please try again later.",
    ERROR_RANGE_REQUIRED: "Range is required",
    EVALUATE: "Evaluate",
    EVALUATION: "Evaluation",
    EXIT: "Exit",
    EXPAND_TABLES_TOOLTIP: "Expand All Tables",
    EXPERIAN_GDN_AVAILABILITY:
      "Experian GDN has a regular time when the service is down. Every Monday from 00:30 am ET to 06:30 am ET and every Tues-Sun 01:30 am ET to 06:30 am ET",
    EXTERNAL_SERVICE_ERROR:
      "There is currently a problem retrieving data from {service}. For support please contact your account manager.",
    FAIL: "Fail",
    FALSE: "False",
    FAX: "Fax",
    FEDERAL: "Federal",
    FEIN: "FEIN",
    FIELD: "Field",
    FIELD_NAME_REQUIRED: "The name field is required",
    FIELD_NAME_UNIQUE: "{name} must be unique",
    FIELD_REQUIRED: "Field is required",
    FILE: "File",
    FILTER: "Filter",
    FILTERS: "Filters",
    FINISH: "Finish",
    FIRST_NAME: "First Name",
    FOR: "For",
    FORM: "Form | Forms",
    FOR_SEARCH_TERM: "for this search term",
    FROM: "From",
    FULL_NAME: "Full Name",
    FULL_ADDRESS: "Full Address",
    FUNDER: "Funder | Funders",
    FUNDER_ADMIN: "Funder Admin",
    FUNDER_VIEWER: "Funder User",
    FUNDING: "Funding",
    FUNDING_ADVISOR: "@:COMMON.FUNDING Advisor",
    GENERAL: "General",
    GENERAL_INFO: "@:COMMON.GENERAL @:COMMON.INFORMATION",
    GENERATE: "Generate",
    GENERATE_LINK: "Copy @.lower:COMMON.LINK",
    GENERATE_REPORTS: "Generate reports",
    GENERATED: "Generated",
    GENERATING: "Generating",
    GENERIC_ERROR: "Something went wrong",
    GOT_IT: "Got It",
    HERE: "Here",
    HIDE: "Hide",
    HIDE_CHARTS: "Hide Charts",
    HOUR: "Hour|Hours",
    JURISDICTION: "Jurisdiction",
    ID: "ID",
    IDENTIFIER: "Identifier",
    IMPERSONATE: "Impersonate",
    INACTIVE: "Inactive",
    INCOMPLETE: "INCOMPLETE",
    INDIVIDUAL: "Individual",
    INDUSTRY: "Industry",
    INFO: "Info",
    INFORMATION: "Information",
    INFRASTRUCTURE: "Infrastructure for embedded lending services.",
    IN: "in",
    INCOME: "Income",
    INPUT: "Input",
    INSERT: "Insert",
    INSTANT: "Instant",
    INTEGRATION: "Integration",
    INTERNAL: "Internal",
    INTO: "into",
    INVALID: "Invalid",
    INVALID_RANGE: "Invalid range",
    INVALID_URL: "Invalid URL",
    IN_PROGRESS: "In Progress",
    IRS_AVAILABILITY: "IRS availability",
    IRS_AVAILABLE: "IRS is Up",
    IRS_UNAVAILABLE: "IRS is Down",
    ISO: "ISO",
    IS_REQUIRED: "is required",
    ITEMS_PER_PAGE: "Items Per Page",
    JSON: "json",
    KEY: "Key",
    LANGUAGE: "Language",
    LAST: "Last",
    LAST_NAME: "@:COMMON.LAST @:COMMON.NAME",
    LAST_UPDATED: "@:COMMON.LAST @:COMMON.UPDATED",
    LAUNCHED: "Launched: {date}",
    LEGACY: "Legacy",
    LENDER: "Lender",
    LENDFLOW: "Lendflow",
    LENDFLOW_ORGANIZATION: "Lendflow + {name}",
    LIEN: "Lien",
    LINE: "Line | Lines",
    LINK: "Link | Links",
    LOADING_SERVICE: "Loading Service...",
    LOAD_MORE: "Load More",
    LOCATION: "Location",
    LOGO: "Logo",
    LOGO_PREVIEW_TOOLTIP:
      "Here, you can see how the logo appears at different sizes in the interface",
    MAIL: "@:COMMON.EMAIL",
    MANUAL: "Manual",
    MANUAL_ENTERED_VALUE: "@:COMMON.MANUAL entered value",
    MATCH: "Match",
    MAX: "Max",
    MAX_VALUE: "Max Value",
    MESSAGE: "Message | Messages",
    MESSAGE_IS_REQUIRED: "Message is required",
    META: "Meta",
    METADATA: "Metadata",
    MIN: "Min",
    MINIMIZE: "Minimize",
    MINUTE: "Minute|Minutes",
    MIN_VALUE: "Min Value",
    MODE: "Mode",
    MOVE: "Move",
    MONTH: "Month",
    MONTHS: "Months",
    MORE: "More",
    MOVE_TO_BOTTOM: "Move to bottom",
    MOVE_TO_TOP: "Move to top",
    MULTIPLE_SELECTION: "Multiple Selection",
    MULTISELECT: "Enable @:COMMON.MULTIPLE_SELECTION",
    MULTISELECT_OFF: "Disable @:COMMON.MULTIPLE_SELECTION",
    NAICS: "Naics",
    NAME: "Name | Names",
    NESTED: "Nested",
    NETWORK_ERROR: "Network error occurred",
    NETWORK_ERROR_SUBTITLE: "Please try again later.",
    NEW: "New",
    NEW_PASSWORD: "New Password",
    NEW_TEMPLATE: "New Template",
    NEXT: "Next",
    NO: "No",
    NO_CHANGE_FROM: "No change from\n{dateFrom} - {dateTo}",
    NO_DATA: "No Data To Display",
    NO_DATA_AVAILABLE: "No Data Available",
    NO_FILES_ADDED: "No files added",
    NO_ITEMS: "There are no {items}",
    NO_NUMERICAL_ATTRIBUTES: "No Numerical Attributes.",
    NO_OPTIONS_AVAILABLE: "No options available.",
    NO_PERMISSION: "No Permission",
    NO_RESULTS: "No results to show.",
    NONE: "None",
    NOT_APPLICABLE: "-",
    NOT_FOUND: "Not Found",
    NOTE: "Note | Notes",
    NOTICE: "Please Note",
    NUMBER: "Number",
    OBJECT: "Object",
    OFF: "off",
    OFFER: "Offer",
    OFFERS_EMPTY: "There are no offers yet.",
    OLD_PASSWORD: "Old Password",
    ON: "on",
    ONLY: "Only",
    ONBOARDER: "Onboarding",
    OPTIONAL: "Optional",
    OPTION_ICON: "{option} icon",
    OR: "Or",
    ORDER_BY: "Order By",
    ORGANIZATION: "Organization",
    ORIGINAL: "Original",
    OTHER: "Other",
    OUTPUT: "Output",
    OWNER: "Owner",
    PAGE: "Page | Pages",
    PASS: "Pass",
    PARTNER: "Partner",
    PASTE: "Paste",
    PAYMENT: "Payment",
    PDF: "pdf",
    PENDING: "Pending",
    PEOPLE: "People",
    PERIOD: "From {startDate} to {endDate}",
    PERSON: "Person",
    PERSONAL: "Applicant Links",
    PHONE: "Phone | Phones",
    PIN: "Pin",
    PLACEHOLDER: "Placeholder",
    PLATFORM: "Platform",
    POINT: "Point | Points",
    POINTS_REQUIRED: "Points are @.lower:COMMON.REQUIRED",
    POP_OUT: "Pop-out",
    POSTAL_CODE: "Postal Code",
    PREVIEW: "Preview",
    PREVIOUS: "Previous",
    PRESET: "Preset",
    PRIMARY: "Primary",
    PRIMARY_NAME: "@:COMMON.PRIMARY Name",
    PRIMARY_SHORTCUT: "P",
    PRIMARY_SPOKEN_LANGUAGE: "@:COMMON.PRIMARY Language Spoken",
    PRIORITY: "Priority",
    PROCEED: "Proceed",
    PROCESSOR: "Processor",
    PROGRESS: "Progress",
    PROMOTE_TO_OWNER: "Promote to Owner",
    PROPORTION: "Proportion",
    PROVINCE: "Province",
    PUBLISHED: "Published",
    PUBLISH: "Publish",
    PUBLISH_ALL: "@:COMMON.PUBLISH @:COMMON.ALL",
    PUBLISH_CHANGES: "@:COMMON.PUBLISH @:COMMON.CHANGE",
    QUARTER: "Quarter",
    QUICK: "Quick",
    REASON: "Reason",
    RECOMMENDED: "Recommended",
    RECORDS: "Records",
    REDIRECT: "Redirect",
    REFRESH: "Refresh",
    REGION: "Region",
    RELOAD: "Reload",
    REMOVE: "Remove",
    REPLACE: "Replace",
    REPLY: "Reply",
    REPORT: "Report",
    REQUIRED: "Required",
    RESTART: "Restart",
    RESTORE: "Restore",
    RESULT: "Result | Results",
    RETURN: "Return",
    REVIEW: "Review",
    ROLE: "Role",
    ROLES: "Roles",
    RANK: "Rank",
    RUN: "Run",
    RUN_SERVICE: "Run Service",
    SAVE: "Save",
    SAVE_ALL: "@:COMMON.SAVE @:COMMON.ALL",
    SAVE_CHANGES: "Save Changes",
    SAVE_EDITS: "Save Edits",
    SCORE: "Score",
    SCORECARD: "Scorecard",
    SCROLL: "Scroll",
    SDK: "SDK",
    SDKS: "SDKs",
    SEARCH: "Search",
    SECTION_NAME: "Section Name",
    SELECT: "Select",
    SELECTED: "Selected",
    SELECT_ALL: "Select All",
    SELECT_AT_LEAST_ONE_CHECKBOX: "Select at least one checkbox",
    SELECT_BANK: "@:COMMON.SELECT @:COMMON.BANK",
    SELECT_PERIOD: "Select Period",
    SEND: "Send",
    SERVICE: "Service | Services",
    SET: "Set",
    SET_AS_DEFAULT: "Set as Default",
    SET_VALUE: "Set Value",
    SHOW_AS_POPOVER: "Show as Popover",
    SHOW_AS_SIDEBAR: "Show as Sidebar",
    SHOW_CHARTS: "Show Charts",
    SHOW_MORE: "Show More",
    SHOW_LESS: "Show Less",
    SIC: "Sic",
    SIGNAL: "Signal | Signals",
    SKIP: "Skip",
    SMS: "SMS",
    SORT: "Sort",
    SSN: "SSN",
    START_DATE: "Start Date",
    STAGE: "stage",
    STATE: "State | States",
    STATUS: "Status | Statuses",
    STATUS_REFRESHED: "Status Refreshed",
    STEP: "Step | Steps",
    STREET: "Street",
    STREET_NAME: "Street Name",
    STREET_NUMBER: "Street Number",
    STREET_SUFFIX: "Street Suffix",
    SUBDOMAIN: "Subdomain",
    SUBMIT: "Submit",
    SUBMITTED: "Submitted",
    SUB_STATUS: "Sub-Status | Sub-statuses",
    SUBJECT: "Subject",
    SUCCESS: "Success",
    SUMMARY: "Summary",
    SUPER_ADMIN: "Super Admin",
    TAB: "Tab | Tabs",
    TASK: "Task",
    TELEPHONE: "Telephone | Telephones",
    TEMPLATE: "Template | Templates",
    TEMPLATE_ID: "@:COMMON.TEMPLATE @:COMMON.ID",
    TEMPLATE_NAME: "@:COMMON.TEMPLATE @:COMMON.NAME",
    TEXT: "Text",
    TEXT_VIEW: "Text View",
    THIS_YEAR: "This Year",
    TIMESTAMP: "Timestamp | Timestamps",
    TIN: "TIN",
    TITLE: "Title",
    TO: "To",
    TODAY: "Today",
    TOKEN: "Token | Tokens",
    TOTAL: "Total",
    TRADE: "Trade",
    TRANSACTION: "Transaction | Transactions",
    TREE_VIEW: "Tree View",
    TRUE: "True",
    TYPE: "Type",
    TYPE_IS_REQUIRED: "Type is required",
    UCC: "UCC",
    UNASSIGNED: "Unassigned",
    UNAVAILABLE: "Not Available Now",
    UNDERWRITER: "Underwriter",
    UNIT_DESIGNATION: "Unit Designation",
    UNIT_NUMBER: "Unit Number",
    UNKNOWN: "Unknown",
    UNNAMED_DUPLICATE: "(unnamed duplicate)",
    UNPIN: "Unpin",
    UNSELECT: "Unselect",
    UPDATE: "Update | Updates",
    UPDATED: "Updated",
    UPDATED_AT: "@:COMMON.UPDATED At",
    UPDATED_BY: "@:COMMON.UPDATED By",
    UPDATE_DETAILS: "Update Details",
    UPDATE_RECORD: "Update Record",
    UPLOAD: "Upload",
    UPLOAD_FAILED: "Upload Failed",
    UPLOAD_FAILED_SUBTITLE: "Upload of your file failed. Please try again.",
    UP_FROM: "Up from\n{dateFrom} - {dateTo}",
    URL: "URL",
    USAGE: "Usage",
    USD: "USD",
    USER: "User",
    VALID: "Valid",
    VALUE: "Value",
    VALUE_IS_REQUIRED: "Value is required",
    VERIFICATION: "Verification",
    VERSION: "Version",
    VIEW: "View",
    VIEWER: "Viewer",
    VIEW_DOCUMENTS: "@:COMMON.VIEW @:COMMON.DOCUMENTS",
    VISIT: "Visit",
    VISUALIZATION: "Visualization | Visualizations",
    WEBSITE: "Website",
    WEEKDAY: "Weekday",
    WELCOME_TO_LENDFLOW: "Welcome to Lendflow",
    WIDGET: "Widget",
    WITH: "with",
    WORKFLOW: "Workflow|Workflows",
    WORKFLOWS: "Workflows",
    WORKFLOW_BUILDER: "Workflow Builder",
    WORKFLOW_BUILDER_TEMPLATE:
      "Workflow Builder Template|Workflow Builder Templates",
    WORKFLOW_ORCHESTRATION: "Workflow Orchestration",
    WORKFLOW_PROGRESS: "Workflow progress",
    YEAR: "Year",
    YEARS: "Years",
    YES: "Yes",
    YOUR: "Your",
    ZIP: "ZIP",
    ZIP_CODE: "Zip Code"
  },
  COMMUNICATION: {
    TOOL: {
      CLOSE_POPOVER: "Close Popover",
      POPOVER_NOTICE:
        "The communication tool currently appears as a floating popup",
      RESTORE_TOOL: "Move it here",
      RESET_RING_CENTRAL: "Refresh auth token"
    }
  },
  COMPLEMENT: {
    YOU_DONT_HAVE_ANY: "You don't have any {resource}"
  },
  CONTRACTS: {
    CONTRACT: "Contract | Contracts",
    CONTRACT_BUILDER: {
      ADD_ROLE: "Add Role",
      CANNOT_PASTE_HERE: "Cannot paste here",
      CONTRACT_BUILDER: "Contract Builder",
      CONTRACT_FIELDS: "Contract Fields",
      CONTRACT_MAPPING: "Contract Mapping",
      CREATE_CONTRACT: "Create Contract",
      DEFINE_ROLES: "Define Roles",
      DELETE_TITLE: "Delete Contract",
      DESCRIPTION: "Manage your contract templates here",
      DETAIL: "Contract Template Detail",
      DROP_ROLE: "Drop Role Here",
      DOCUSIGN_TEMPLATE_ID: "Docusign Template ID",
      EDIT_TITLE: "Edit Contract: {name}",
      EMPTY: "You don’t have any Contract Templates yet",
      NOTHING_TO_COPY: "Nothing to copy",
      NOTHING_TO_PASTE: "Nothing to paste",
      MISSING_FIELDS: "Role is missing contract fields. 1+ field required",
      ON_PAGE: "On Page",
      PIXELS_FROM_LEFT: "Pixels from left",
      PIXELS_FROM_TOP: "Pixels from top",
      PROPERTIES: "Properties",
      SAVE_TO_UPLOAD: "Save contract template first to upload",
      SIGNATURE_REQUIRED: "Signature required to complete document",
      TEMPLATE: "@:CONTRACTS.CONTRACT @:COMMON.TEMPLATE",
      UPLOAD_CONTRACT: "Upload a contract",
      UPLOAD_ONE_FILE: "Please upload a single PDF file",
      X_POSITION: "X Position",
      Y_POSITION: "Y Position"
    }
  },
  COUNTRIES: {
    CA: "CA",
    US: "US"
  },
  CREDIT: {
    ACTUAL_SCORE: "Actual Credit Score",
    LAST_INQUIRIES: "Inquiries Past 6 Months",
    LIMIT: "Credit Limit",
    PERMISSION: "Credit Permission",
    STATED_SCORE: "Stated Credit Score",
    UTILIZATION: "Credit Utilization"
  },
  CUSTOMIZE: {
    APPLICATION_FOR_CAPITAL: "Application for Capital",
    APPLY_FOR_ALL_STAGES: "Apply for all stages",
    BACKGROUND: "Background",
    BACKGROUND_COLOR: "Background Color",
    BACKGROUND_COLOR_SUBTITLE: "The background color of your widget.",
    BUTTON_COLOR: "Button Color",
    BUTTON_COLOR_SUBTITLE: "The color of buttons in your widget.",
    CONSENT: {
      AT_LEAST_ONE_CONSENT_REQUIRED: "At least one consent is required",
      CONFIRMATION_BLOCK: "Confirmation Block",
      DEFAULT_TEMPLATE: "Default template",
      DELETE_PROMPT_TITLE: "Delete Consent Message",
      DESCRIPTION:
        "Configure the consent message displayed in your widget. Customize the text and choose whether it's required or optional.",
      HIDE_TEXT_IN_SCROLL_AREA: "Hide Text in Scroll Area",
      HIDE_TEXT_IN_SCROLL_AREA_DESCRIPTION:
        "Enable this option to hide text within a scrollable section, making the content less visible until you scroll.",
      INTEGRATION_DESCRIPTION:
        "Choose a consent message template or contract template. The default will be Lendflow's consent message template.",
      INTEGRATION_DROPDOWN_PLACEHOLDER: "Consent Message Template",
      INTEGRATION_TITLE: "Choose Template to Collect Merchant Consent",
      MAKE_DEFAULT: "Set as Default Template",
      MAKE_DEFAULT_DESCRIPTION:
        "The default consent template will automatically apply to all widgets unless a different template is specified in the integration script.",
      MAKE_THIS_DEFAULT: "Set This Template As Default",
      MAKE_THIS_DEFAULT_DESCRIPTION:
        '"{current}" is currently the default Consent Template Message. Would you like to set it to "{new}"?',
      MAKE_REQUIRED: "Make this Field Required",
      MAKE_REQUIRED_DESCRIPTION:
        "Check this box to make the consent checkbox mandatory for borrowers before they can proceed.",
      MESSAGE: "Consent Message",
      PREVIEW_TITLE: "Consent to Funding",
      TEMPLATE_MESSAGE: "Template Message",
      TEMPLATE_NAME: "Template Name",
      TITLE: "Consent Template | Consent Templates",
      TYPE: "Confirmation Type"
    },
    CREATE_NEW_TEMPLATE: "Create new template",
    DELETE_FROM_STAGE: "Delete from this stage",
    DELETE_LOGO: "Delete Logo",
    DELETE_LOGO_PROMPT: "Are you sure you want to delete this logo?",
    DELETE_PHOTO: "Delete Logo",
    DELETE_TEMPLATE: "Delete Template",
    DELETE_TEMPLATE_PROMPT: "Are you sure you want to delete this template?",
    DESCRIPTION: "Description",
    DRAG_HERE_1: "Drag image here or ",
    DRAG_HERE_2: "upload manually",
    EMAIL: {
      SEND_TEST_EMAIL_TO: "For selected template, send test email to:",
      SET_AS_DEFAULT: "Set as default",
      SUBTITLE: "Preview and customize your branded emails",
      TEST_EMAILS_PLACEHOLDER: "Select Emails",
      TITLE: "Email Branding"
    },
    FONT: "Font",
    FONT_COLOR: "Font Color",
    FONT_COLOR_SUBTITLE: "Customize the fonts in your widget.",
    HEADING: "Heading",
    ICON: "Icon",
    INVALID_FORMAT: "Invalid File Format",
    LABEL: "Label",
    PROGRESS_BAR: "Progress Bar",
    REMOVE_FROM_ALL_STAGES_EXEPT_ONE: "Remove from all stages except this one",
    SELECT_COLOR: "Select Your Color",
    SET_AS_PRIMARY: "Set as Primary",
    SET_DEFAULT: "Set as default",
    SUBTITLE:
      "Customize branding which will apply to both the application widget and the borrower platform",
    SUPPORTED_FORMATS: "Supported types are jpeg, jpg, heic and png.",
    TITLE: "Branding",
    UNNAMED_TEMPLATE: "Unnamed Template",
    UNSET_PRIMARY: "Unset Primary",
    UPLOADING_1: "Uploading...",
    UPLOADING_2: "Only a few seconds are left",
    UPLOAD_NEW: "Upload New",
    WIDGET_FOOTER: {
      REQUIRE_TERMS: {
        LABEL: "Enforce the applicant to check the box on the widget",
        DESCRIPTION:
          'Require applicants to check the box agreeing to the terms in the customised footer message. Applicants will not be able to press the "Next" button unless they check the box.'
      },
      SUBTITLE:
        "Add your custom text and include any links e.g. Privacy Policy, Terms of Service.",
      TITLE: "Customize Widget Footer"
    },
    WIDGET_LOGO: "Widget Logo",
    WIDGET_LOGO_SUBTITLE: "Upload your logo. Recommended size is 512px x 512px"
  },
  CUSTOMIZE_EMAIL: {
    APPLY_FOR_CAPITAL: "Apply for capital.",
    BACKGROUND: "Background",
    BUSINESS_ADDRESS: "Your business address",
    BUSINESS_PHONE: "Business Phone",
    CLOSING_PARAGRAPH:
      "You are receiving this email because you submitted an application via our app or website.",
    CONTACT_INFORMATION: "Contact Information",
    CREATE_NEW_TEMPLATE: "Create new template",
    CUSTOMIZE_EMAIL_SECTION_TITLE: "Set Your Email",
    DELETE_TEMPLATE: "Delete Template",
    DELETE_WARNING: "{template} will be permanently removed from Lendflow.",
    EMAIL_BLUEPRINT_INTRO: "Hi [contact.first_name] ,",
    EMAIL_BLUEPRINT_PARAGRAPH1:
      "We noticed that you started your application for financing with [client.name] but we did not receive all of the necessary information to start processing your file.",
    EMAIL_BLUEPRINT_PARAGRAPH2:
      'Please navigate back to the application and click on "Resume a previous application" to continue and complete the application.',
    EMAIL_BLUEPRINT_PARAGRAPH3:
      "Our experienced team of business advisors is here to help you execute on growth opportunities now and will be here to help you continue to grow in the future.",
    FINANCING_PARTNER: "A financing partner you can grow with",
    FOOTER: "Footer",
    FOOTER_SUBTITLE:
      "Add contacts and social media links to the bottom of email",
    FUNDING_TEAM: "The Funding Team",
    FUNDS_TRANSFERED: "Funds are transferred into your bank.",
    HEADER_TEXT_COLOR: "Header Text Color",
    INCOMPLETE_APPLICATION: "Incomplete application",
    LOGO: "Logo",
    LOGO_AND_COLORS: "Logotype & Branding Colors",
    LOGO_AND_COLORS_SUBTITLE: "Your company logo and color scheme",
    LOGO_PLACEHOLDER: "Your Logo",
    NEXT_STEP: "What's the next step?",
    PENDING_REVIEW: "Pending review.",
    PRIMARY_COLOR: "Primary Color",
    READY_FOR_REVIEW: "Offer ready for review.",
    SOCIAL_MEDIA_LINKS: "Social Media Links",
    SOCIAL_MEDIA_LINKS_TOOLTIP:
      "Enter or paste link and system will detect social network automatically",
    THANKS: "Thanks",
    WHAT_TO_EXPECT: "What should I expect?",
    YOUR_BUSINESS_PHONE: "Your business phone"
  },
  DASHBOARD: {
    EMPLOYEE_NO_DATA_1: "Hi, {name}",
    EMPLOYEE_NO_DATA_2:
      "Get up and running with just a few simple steps: get yourself assigned to existing deals and their statistics will be displayed here.",
    NO_APPLICATIONS_TITLE:
      "When first application starts, detailed statistics will be presented here.",
    PERIOD: {
      RANGE: "@.lower:COMMON.PERIOD",
      THIS_YEAR: "@.lower:COMMON.FOR @.lower:COMMON.THIS_YEAR"
    },
    SUBTITLE:
      "Detailed statistics {period}, calculated based on applications started within the selected dates.",
    TITLE: "Dashboard"
  },
  DATE_PICKER_SHORTCUTS: {
    LAST_30_DAYS: "LAST 30 DAYS",
    LAST_365_DAYS: "LAST 365 DAYS",
    LAST_6_MONTHS: "LAST 6 MONTHS",
    LAST_CALENDAR_YEAR: "LAST CALENDAR YEAR",
    LAST_MONTH: "LAST MONTH",
    LAST_WEEK: "LAST WEEK",
    THIS_CALENDAR_YEAR: "THIS CALENDAR YEAR",
    THIS_MONTH: "THIS MONTH",
    THIS_QUARTER: "THIS QUARTER",
    THIS_WEEK: "THIS WEEK",
    TODAY: "TODAY",
    YESTERDAY: "YESTERDAY"
  },
  DEALS: {
    ADVANCED_SEARCH: {
      EMPTY_RECENT_INFO: "Nothing to show",
      FILTERS_HEADING: "I am looking for...",
      RECENT_HEADING: "Recent searches",
      TAGS: {
        ASTERISK: "Wildcard",
        ASTERISK_DESC: `Use "*" to represent any sequence of characters. For example, if you enter "apple*", the search will return results like "apple," "apples," "applet," etc.`,
        DASH: "Exclude words",
        DASH_DESC: `Use "-" to exclude certain words from the search results. For example, if you enter "apple -pie," the results will include items related to "apple" but exclude those related to "pie."`,
        PIPE: "Or operator",
        PIPE_DESC: `Use "{'|'}" as an "or" operator. For example "apple{'|'}pie" will include results with either the words apple or pie.`,
        PLUS: "And operator",
        PLUS_DESC: `Use "+" as an "and" operator. For example "apple+pie" will include results with both the words apple and pie.`,
        QUESTIONMARK: "Replaces a single character",
        QUESTIONMARK_DESC: `Use "?" to represent a single character in your search term. For instance, if you search for "b?t," the results may include "bat," "bet," and "but."`,
        QUOTEMARK: "Exact phrasing",
        QUOTEMARK_DESC: `Use " " to represent exact phrase in the search results. For example, when you enter "John Doe", your results will only include results where the words John and Doe appear together, in that exact order.`,
        TILDE: "Approximate matching",
        TILDE_DESC: `Use "~" for approximate matching, indicating that the search should include similar terms. If you search for "roam~," the results might include variations like "roaming," "roams," and "roamed."`
      }
    },
    APPLICATION_LINKS: {
      ACCOUNTING_CONNECTION: "Accounting Connection",
      APPLICANT_CONSENT_LINK: "Applicant Consent Link",
      APPLICANT_CONSENT_LINK_DESCRIPTION: "Collect Applicant Consent",
      APPLICANT_INFO: "Applicant Information and Consent",
      APPLICANT_INFO_DESCRIPTION:
        "Collect missing applicant information and consent",
      BANK_STATEMENTS: "Bank Statements",
      BORROWER_PLATFORM: "@:COMMON.BORROWER @:COMMON.PLATFORM",
      BORROWER_PLATFORM_DESCRIPTION:
        "Borrower Platform link for this deal only. For access to all user's deals, copy the link from the Integrations page",
      CODAT_LINK: "Codat Link",
      CONTRACT_WITH_IDV: "Application Contract with Identity Verification",
      DOCUSIGN: "DocuSign",
      DOCUSIGN_DESCRIPTION: "DocuSign Link",
      DOCUSIGN_TEMPLATE: "DocuSign Template",
      IDV_DESCRIPTION: "Liveness Link",
      MANUAL_UPLOAD_LINK: "Manual Upload Link",
      PERSONAL_INFO: "Personal Information",
      PERSONAL_INFO_DESCRIPTION: "Personal Information Link",
      PLAID: "Bank Connection",
      PLAID_DESCRIPTION: "Plaid Link",
      RAILZ_LINK: "Railz Link",
      RESUME_APP: "Resume Application",
      RESUME_APP_DESCRIPTION: "Resume Application Link",
      STIPS_TITLE: "Stips Collection",
      TAX_DETAILS_DOCUSIGN_BANK_STATEMENTS:
        "Tax Details, Applicant Consent and Bank Statements",
      TAX_DETAILS_DOCUSIGN_BANK_STATEMENTS_DESCRIPTION:
        "Tax Details, Applicant Consent and Bank Statements Links",
      TITLE: "Application Links"
    },
    BANKRUPTCIES: {
      BUSINESS_TITLE: "Business Bankruptcies"
    },
    BLU_COGNITION: {
      TITLE: "bluCognition"
    },
    BUSINESS_NOTES: {
      EMPTY_STATE_TEXT1: "Your Business Notes will appear here",
      EMPTY_STATE_TEXT2:
        "By default, your notes will be shared with Funders. The note visibility can be configured to internal only if desired, before posting the note"
    },
    BUSINESS_TRANSPORTATION: {
      AUTHORITY_TYPE: "Authority Type",
      CARGO_CARRIED: "Cargo Carried",
      USDOT_NUMBER: "USDOT Number",
      EDIT: "Edit @:DEALS.BUSINESS_TRANSPORTATION.TITLE",
      ENTITY_TYPE: "Entity Type",
      INSURANCE_ON_FILE: "Insurance on File",
      INSURANCE_REQUIRED: "Insurance Required",
      OPERATION_CLASSIFICATION: "Operation Classification",
      POWER_UNITS: "Power Units",
      USDOT_STATUS: "USDOT Status",
      TITLE: "Business Transportation Information"
    },
    CFA: {
      ACCOUNT_CATEGORY: "Account Category",
      ACCOUNT_HOLDER: "Account Holder",
      ACCOUNT_NUMBER: "Account Number",
      ACCOUNT_OWNER: "Account Owner",
      ACCOUNT_STATUS: "Account Status",
      ACCOUNT_TITLE: "Account Title",
      ACCOUNT_TYPE: "Account Type"
    },
    CLEAR_ADVERSE_MEDIA: {
      ADVERSE_MEDIA_MONITOR_SUMMARY: "Adverse Media Monitor Summary",
      FCRA_SECTION: "FCRA Section",
      FULL_TEXT: "Full Text",
      FULL_TEXT_ORIGINAL_LANG:
        "@:DEALS.CLEAR_ADVERSE_MEDIA.FULL_TEXT @:DEALS.CLEAR_ADVERSE_MEDIA.ORIGINAL_LANGUAGE",
      GROUP_ID: "Group ID",
      HERE: "here",
      LANGUAGE: "Language",
      NAME: "Adverse Media",
      ORIGINAL_LANGUAGE: "Original Language",
      RECORD_COUNT: "Record Count",
      RECORD_ID: "Record ID",
      REPORT_ARTICLES_SECTION: "Articles Section",
      RESULTS_FOUND: "The results can be found",
      RESULT_GROUP: "Result Group {group}",
      SCOPE: "Scope",
      SEARCH_ID: "Search ID",
      SNIPPET: "Snippet",
      SNIPPET_ORIGINAL_LANG:
        "@:DEALS.CLEAR_ADVERSE_MEDIA.SNIPPET @:DEALS.CLEAR_ADVERSE_MEDIA.ORIGINAL_LANGUAGE",
      SOME_RECORDS_HIDDEN: "Some Records May Not Be Shown",
      TITLE: "Title",
      TITLE_ORIGINAL_LANG:
        "@:DEALS.CLEAR_ADVERSE_MEDIA.TITLE @:DEALS.CLEAR_ADVERSE_MEDIA.ORIGINAL_LANGUAGE",
      URL: "URL",
      USER_SEARCH_TERMS: "User Search Terms"
    },
    CLEAR_BANKRUPTCY: {
      BANKRUPTCY: "Bankruptcy",
      BUSINESS_SUBTITLE: "Business Bankruptcies",
      DESCRIPTION:
        "Clear delivers critical information from the financial, legal, accounting, intellectual property, science, and media markets.",
      FED_DOCKET_RECORD: "Fed Docket Bankruptcy Record",
      NO_DATA: "No flags were returned for your search subject.",
      PERSONAL_SUBTITLE: "Individual Bankruptcies",
      RECEIVED_DATA: "Received Data",
      TITLE: "Clear"
    },
    CLEAR_KYB: {
      ACTUAL_NUMBER_EMPLOYEES: "Actual Number Employees",
      ADDITIONAL_SIC_CODES: "Additional SIC Codes",
      ADDRESS: "Address",
      ADDRESS_FLAGS: "Address Flags",
      ALL_FLAGS: "All Flags",
      ANNUAL_SALES_LOCAL: "Annual Sales Local",
      ANNUAL_SALES_US: "Annual Sales US",
      AREA_CODE_PHONE_NUMBER: "Area Code & Phone Number",
      AUTHOR: "Author",
      AUTHOR_TYPE: "Author Type",
      BUSINESS_ADDRESS: "Business Address",
      BUSINESS_ADDRESS_USED_AS_RESIDENTIAL_ADDRESS:
        "Business Address Used as Residential Address",
      BUSINESS_NAME: "Business Name",
      BUSINESS_PROFILE: "Business Profile",
      BUSINESS_PROFILES: "Business Profiles",
      BUSINESS_PROFILE_RECORD_UPDATE_DATE:
        "Business Profile Record Update Date",
      CEO_NAME: "CEO Name",
      CITATION: "Citation",
      CITY: "City",
      CLEAR: "Clear",
      CLEAR_UI_SETTINGS: "Clear UI Settings",
      COMPANY_DETAILS_STRUCTURE: "Company Details and Structure",
      COMPANY_DETAILS_STRUCTURE_FLAGS: "Company Details and Structure Flags",
      CORPORATE_FILING: "Corporate Filing | Corporate Filings",
      CORPORATE_FILING_NUMBER: "@:DEALS.CLEAR_KYB.CORPORATE_FILING Number",
      CORP_AGENT_ADDRESS: "Corp Agent Address",
      CORP_AGENT_FULL_NAME: "Corp Agent Full Name",
      CORP_AGENT_INFO: "Corp Agent Info",
      CORP_BUSINESS_STATUS: "Corp Business Status",
      CORP_LIFE_TIME: "Corp Life Time",
      CORP_NUMBER: "Corp Number",
      CORP_OFFICER_ADDRESS: "Corp Officer Address",
      CORP_OFFICER_FULL_NAME: "Corp Officer Full Name",
      CORP_OFFICER_INFO: "Corp Officer Info",
      CORP_OFFICER_SECONDARY_ADDRESS: "Corp Officer Secondary Address",
      CORP_STATUS_DATE: "Corp Status Date",
      COUNTY: "County",
      CROSS_REFERENCE: "Cross Reference",
      CURRENCY: "Currency",
      CUSTOM: "Custom",
      CUSTOM_MESSAGE: "Custom message",
      DEBTOR_NAME: "Debtor Name",
      DEFINITION_SETTING: "Definition Setting",
      DOCUMENT_GUID: "Document Guid",
      DOCUMENT_IDENTIFIER_INFO: "Document Identifier Info",
      DUNS_NUMBER: "DUNS Number",
      D_B: "Dun & Bradstreet",
      D_B_COPYRIGHT:
        "Data by Dun & Bradstreet, COPYRIGHT © {date} DUN & BRADSTREET, INC.",
      D_B_MARKET_IDENTIFIER_RECORDS: "D&B Market Identifier Records",
      EFFECTIVE_DATE: "Effective Date",
      EMPLOYEE_TOTAL: "Employee Total",
      EMP_TOTAL_THREE_YEARS_AGO: "Sales 3Yr Ago",
      EMP_TOTAL_YEAR_AGO: "Sales 1Yr Ago",
      EXPIRATION_DATE: "Expiration Date",
      FILING_DATE: "Filing Date",
      FILING_DATE_TIME: "Filing Date & Time",
      FILING_DUNS_NUMBER: "Filing DUNS Number",
      FILING_NUMBER: "Filing Number",
      FILING_OFFICE: "Filing Office",
      FILING_OFFICE_ADDRESS: "Filing Office Address",
      FILING_STATE: "Filing State",
      FILING_TYPE: "Filing Type",
      FIRST_REPORTED_DATE: "First Reported Date",
      FOREIGN_OWNED: "Foreign Owned",
      FULL_FILING_NUMBER: "Full Filing Number",
      INDUSTRY_CLASSIFICATION_CODE: "Industry Classification Code",
      INDUSTRY_GROUP: "Industry Group",
      KYB: "KYB",
      LAST_REPORTED_DATE: "Last Reported Date",
      LATEST_SALES: "Latest Sales",
      LAUNCHED: "LAUNCHED",
      LEAD_PARAGRAPH: "LEAD PARAGRAPH",
      LEGAL_BUSINESS_NAME: "Legal Business Name",
      LINE_OF_BUSINESS: "Line of Business",
      MAILING_ADDRESS: "Mailing Address",
      MAIL_DELIVERABLE: "Mail Deliverable",
      MANAGEMENT_TEAM: "Management Team",
      MINIMUM_THRESHOLD_SCORE: "Minimum Threshold Score",
      MSA_CODE: "MSA Code",
      MSA_NAME: "MSA Name",
      NAICS_CODES: "NAICS Code(s)",
      NAME_TYPE: "Name Type",
      NET_WORTH: "Net Worth",
      NET_WORTH_LOCAL: "Net Worth Local",
      NET_WORTH_US: "Net Worth US",
      NEWS: "News",
      NO_CORPORATE_FILINGS_TIED_TO_BUSINESS:
        "No Corporate Filings Tied to Business",
      OFFICER_AGENT_FULL_NAME: "Officer Agent Full Name",
      OPERATING_STATUS: "Operating Status",
      ORIGINAL_SERVICE_PROVIDER: "Original Service Provider",
      OTHER_BUSINESSES_LINKED_TO_BUSINESS_ADDRESS:
        "Other Businesses Linked to the Business Address",
      OTHER_LISTINGS_LINKED_TO_BUSINESS_PHONE_NUMBER:
        "Other Listings Linked to Business Phone Number",
      PAGE_COUNT: "Page Count",
      PHONE_CONFIDENCE_SCORE: "Phone Confidence Score",
      PHONE_NUMBER: "Phone Number",
      PHONE_RECORD: "Phone Record",
      PHONE_RECORDS: "Phone Records",
      PHONE_TYPE: "Phone Type",
      PRIMARY_ISO_COUNTRY: "Primary ISO Country",
      PRIMARY_NAICS: "Primary NAICS",
      PRIMARY_NAICS_CODE: "Primary NAICS Code",
      PRIMARY_SIC: "Primary SIC",
      PRIMARY_SIC_CODE: "Primary SIC Code",
      PRINCIPALS_EXECUTIVES: "Principals & Executives",
      PRINCIPALS_EXECUTIVES_TIED_TO_BUSINESS:
        "Principals and Executives tied to business",
      PROFIT_LOCAL: "Profit Local",
      PROFIT_US: "Profit US",
      PUBLICATION: "Publication",
      PUBlICATION_DATE: "Publication Date",
      RECORD_TYPE: "Record Type",
      REFERENCE: "Reference",
      RELIABILITY_SCORE: "Reliability Score",
      REPORT: "Report",
      REVISION_DATE: "Revision Date",
      RISK_FLAG_HIT_INDICATOR: "Risk Flag Hit Indicator",
      RISK_FLAG_INDICATOR: "Risk Flag Indicator",
      RISK_FLAG_NAME: "Risk Flag Name",
      RISK_FLAG_SCORE: "Risk Flag Score",
      RISK_INFORM_COMPANY_DETAILS_STRUCTURE:
        "Risk Inform Company Details & Structure",
      RISK_INFORM_DETAILS_PRINCIPALS_EXECUTIVES_TIED_TO_BUSINESS_DETAILS:
        "Risk Inform Details - Principals & Executives Tied to Business",
      RISK_INFORM_DETAILS_STRUCTURE:
        "Risk Inform Details - Company Details & Structure",
      RISK_INFORM_NAME: "Risk Inform Name",
      RISK_INFORM_PRINCIPALS_EXECUTIVES_TIED_TO_BUSINESS_DETAILS:
        "Risk Inform Principals and Executives tied to Business Details",
      RISK_INFORM_SCORE: "Risk Inform Score",
      RISK_INFORM_SINGE_RISK_INDICATORS_DETAILS:
        "Risk Inform Single Risk Indicators Details",
      RISK_INFORM_SINGLE_RISK_INDICATORS:
        "Risk Inform Details - Single Risk Indicators",
      RISK_INFORM_SUMMARY: "Risk Inform Summary",
      RISK_INFORM_VERSION: "Risk Inform Version",
      RISK_INFO_SUMMARY: "Risk Info Summary",
      RUN: "Run",
      SALES_THREE_YEARS_AGO: "Sales 3Yr Ago",
      SALES_YEAR_AGO: "Sales 1Yr Ago",
      SECONDARY_NAICS: "Secondary NAICS",
      SECONDARY_SIC: "Secondary SIC",
      SECTION_NAME: "Section Name",
      SECTION_SCORE: "Section Score",
      SHOW_ALL: "Show All",
      SINGLE_RISK: "Single Risk",
      SINGLE_RISK_INDICATOR: "Single Risk Indicator",
      SINGLE_RISK_INDICATORS: "Single Risk Indicators",
      SMALL_BUSINESS: "Small Business",
      SOURCE: "Source",
      SOURCE_DOCUMENTS_INFO: "Source Documents Info",
      SOURCE_DOCUMENT_GUID: "Source Document GUID",
      SOURCE_NAME: "Source Name",
      STATE: "State",
      STATE_OF_INCORPORATION: "State of Incorporation",
      STREET: "Street",
      SUBTITLE: "KYB",
      TITLE: "Clear",
      TOTAL_SALES: "Total Sales",
      TOTAL_SCORE: "Total Score",
      UCC_FILINGS: "UCC Filings",
      WESTLAW_CITATION: "Westlaw Citation",
      WORD_COUNT: "Word Count",
      WORLDBASE: "Worldbase",
      YEAR_ESTABLISHED: "Year Established",
      YEAR_STARTED: "Year Started",
      ZIP: "ZIP"
    },
    CLEAR_KYC: {
      CLEAR: "Clear",
      CUSTOM: "Custom",
      KYC: "KYC",
      NEWS: "News",
      REAL_TIME_INCARCERATION_RECORDS: "RealTimeIncarcerationRecords",
      SINGLE_RISK_INDICATORS: "Single Risk Indicators",
      SUBTITLE: "KYC",
      SYNTHETIC_IDENTITY: "Synthetic Identity"
    },
    CLEAR_LIENS: {
      CREDITOR: "Creditor",
      CREDIT_HOLDER: "Credit Holder",
      CREDIT_HOLDER_LEVEL: "Credit Holder Level",
      DEBTOR: "Debtor",
      DISPOSITION_INFO: "Disposition Info",
      DISPOSITON_INFO: "Disposition Info",
      DRIVER_LICENSE_INFO: "Driver License Info",
      FILING_OFFICE_DUNS_NUMBER: "Filing Office DUNS Number",
      FILING_OFFICE_NAME: "Filing Office Name",
      FILING_TYPE: "Filing Type",
      LIENS: "Liens",
      LIENS_BUSINESS: "Business Liens",
      LIENS_PERSONAL: "Individual Liens",
      LIEN_AMOUNT: "Lien Amount",
      LIEN_INFO: "Lien Info",
      LIEN_JUDGE_FILING_INFO: "Lien Judge Filing Info",
      NO_LIENS: "No Liens for business {businessName}",
      PERSON_NAME: "Person Name",
      PERSON_PROFILE: "Person Profile",
      REPORTED_DATE: "Reported Date",
      RESULT: "Result",
      SECTION_NAME: "Section Name",
      STATUS: "Status",
      STATUS_INFO: "Status Info"
    },
    CLEAR_MATCH: {
      ADDITIONAL_PHONE_NUM: "Additional Phone Number",
      ADDITIONAL_PHONE_NUMBER: "Additional Phone Number",
      BIRTH_DATE: "Birth Date",
      BUSINESS_ADDRESS_USED_AS_REDIDENTAL_ADDRESS:
        "Business Address Used as Residential Address",
      BUSINESS_MATCH: "Business Match",
      BUSINESS_NAME: "Business Name",
      CLEAR_PROVIDED_DATA: "Clear Provided Data",
      CONTENT_SCORE: "Content Score",
      CONTENT_SOURCE: "Content Source",
      DATA: "Data",
      DEATH: "Death",
      DOC_IDENTIFIER: "Document Identifier",
      DOC_IDENTIFIER_INFO: "Document Identifier Info",
      FEIN_NUMBER: "FEIN Number",
      GROUP_ID: "Group ID",
      INDIVIDUAL_MATCH: "Individual Match",
      KNOWN_ADDRESS: "Known Address",
      LAST_CONFIRMED: "Last Confirmed",
      MATCH: "Match",
      MATCH_TYPE: "Match Type",
      MULTIPLE_SSN: "Multiple SSN",
      NO_ADDITIONAL_DATA_AVAILABLE: "No Additional Data Available",
      NO_SEARCH_RESULTS_FOUND: "No match found for business {businessName}",
      OFAC: "OFAC Listing",
      OFAC_INDICATOR: "OFAC Listing Indicator",
      OFF_AGENT_FIRST_NAME: "Officer Agent First Name",
      OFF_AGENT_LAST_NAME: "Officer Agent Last Name",
      OTHER_BUSINESSES_LINKED_TO_BUSS_ADDRESS:
        "Other Businesses Linked to Business Address",
      OTHER_LISTINGS_LINKED_TO_BUSS_PHONE_NUM:
        "Other Listings Linked to Business Phone Number",
      PERSON_ENTITY: "Person Entity",
      PHONE_NUMBER: "Phone Number",
      PO_BOX_LISTED_AS_ADDRESS: "PO Box Listed as Address",
      PULL_DATA: "Update Record With Clear Business Match data?",
      PULL_DATA_INDIVIDUAL: "Update Record With Clear Individual Match data?",
      RECORD: "Record",
      RESULT: "Result",
      SCORE: "Score",
      SEARCH_RECORD: "Search Record",
      SEARCH_RECORDS: "Search Records",
      SOURCE: "Source",
      SSN_MULTI_INDIVIDUALS: "SSN Matches Multiple Individuals",
      TITLE: "Clear",
      TOTAL_SCORE: "Total Score",
      ZIP_CODE: "ZIP Code"
    },
    CLEAR_UCC: {
      COMPANY_DEBTOR: "Company debtor",
      DEBTOR: "Debtor",
      DOCUMENT_INFO: "Document Identifier Info",
      NO_FILINGS: "No UCC Filings for person",
      REPORT: "Report",
      UCC_FILING: "UCC Filing",
      UCC_FILINGS: "Business UCC filings"
    },
    CLIENT_PHASES: {
      ACCOUNTABLE: "Accountable: {name}",
      APP_COMPLETE: "App complete",
      APP_STARTED: "App started",
      FUNDED: "Funded",
      FUNDER_REVIEW: "Funder review",
      OFFER_OUT: "Offer Out",
      SCORECARDS: "Scorecards"
    },
    CODAT: {
      ACCOUNTING_SERVICE: "Accounting Service",
      ACCOUNTS: "Accounts",
      ACCOUNTS_DESCRIPTION:
        "Accounts are the categories a business uses to record accounting transactions.",
      ADVANCED_MODE: "Advanced mode",
      BALANCE_SHEET: {
        ASSETS: "Assets",
        DESCRIPTION:
          "A balance sheet is a snapshot of a company's accounts at a single point in time. It provides a statement of the assets, liabilities, and equity of an organisation. The balance sheet gives an idea of the company's financial position, in addition to displaying what the company owns and owes.",
        EQUITY: "Equity",
        LIABILITIES: "Liabilities",
        NET_ASSETS: "Net Assets",
        TITLE: "Balance Sheet"
      },
      BANK_ACCOUNTS: "Bank accounts",
      BANK_ACCOUNTS_DESCRIPTION:
        "A list of bank accounts associated with a company and a specific data connection.",
      BANK_TRANSACTIONS: "Bank transactions",
      BANK_TRANSACTIONS_DESCRIPTION:
        "Transactional banking data for a specific company and account.",
      BILLS: "Bills",
      BILLS_DESCRIPTION:
        "A bill is an itemized record of goods purchased from or services provided by a supplier.",
      BILLS_OUTSTANDING: "No. Bills Outstanding",
      COMPANY_INFO: {
        ADDRESSES: "Addresses",
        BILLING: "Billing",
        CREATED_DATE: "Created date",
        DELIVERY: "Delivery",
        DESCRIPTION:
          "Сompany information includes standard details about a linked company, such as their address, phone number, and company registration.",
        FACEBOOK: "Facebook",
        FINANCIAL_YET_STARTED: "Financial year start",
        INSTAGRAM: "Instagram",
        LANDLINE: "Landline",
        LEDGER_LOCK_DATE: "Ledger lock date",
        MOBILE: "Mobile",
        PHONES: "Phones",
        PRIMARY: "Primary",
        REGISTRATION_NUMBER: "Registration Number",
        SOURCE_LINKS: "Source links",
        TAX_NUMBER: "Tax Number",
        TITLE: "Company Info",
        WEBSITE: "Website",
        WEB_LINKS: "Web links",
        XERO: "Xero",
        XERO_REPORTING: "Xero reporting"
      },
      CUSTOMER: {
        DAY_SALES_OUTSTANDING: "Days Sales Outstanding",
        DESCRIPTION:
          "A customer is a person or organisation that buys goods or services.",
        TITLE: "Customers"
      },
      INVOICES_OUTSTANDING: "No. Invoices Outstanding",
      PRESETS: "presets",
      PROFIT_AND_LOSS: {
        COST_OF_SALES: "Cost of Sales",
        CURRENCY: "Currency",
        DESCRIPTION:
          "The purpose of a profit and loss report is to present the financial performance of a company over a specified time period.",
        EXPENSES: "Expenses",
        GROSS_PROFIT: "Gross Profit",
        INCOME: "Income",
        MONTH: "Month",
        NET_OPERATING_PROFIT: "Net Operating Profit",
        NET_OTHER_INCOME: "Net Other Income",
        NET_PROFIT: "Net Profit",
        NET_PROFIT_MARGIN: "Net Profit Margin",
        OPERATING_PROFIT: "Operating Profit",
        PERIODS_TO_COMPARE: "Periods to Compare",
        PERIOD_LENGTH: "Period Length",
        REFRESH: "Refresh",
        TITLE: "Profit and loss"
      },
      SUPPLIERS: {
        DAYS_PLAYABLE_OUTSTANDING: "Days Payable Outstanding",
        DESCRIPTION:
          "A supplier is a person or organisation that provides a product or service. From the Suppliers endpoints, you can retrieve a list of all the suppliers for a company. Suppliers' data links to accounts payable bills.",
        TITLE: "Suppliers"
      },
      TABLE_LABEL: {
        BANK_ACCOUNT: "Bank Account",
        CODE: "Code",
        COMPONENTS: "Components",
        COMPOUND: "Compound",
        CURRENCY: "Currency",
        DESCRIPTION: "Description",
        EFFECTIVE_TAX_RATE: "Effective Tax Rate",
        NAME: "Name",
        RATE: "Rate",
        STATUS: "Status",
        TAX_COMPONENT_NAME: "Tax Component Name",
        TOTAL_TAX_RATE: "Total Tax Rate",
        TYPE: "Type",
        UPTADED_SOURCE: "Updated in Source"
      },
      TAX_RATES: "Tax rates",
      TAX_RATES_DESCRIPTION:
        "Accounting systems typically store a set of taxes and associated rates within the accounting package. This means that users don't have to look up or remember the rates for each type of tax.",
      TITLE: "Codat",
      TOTAL_OWED_BY_CUSTOMER: "Total Owed by Customers",
      TOTAL_OWED_SUPPLIERS: "Total Owed to Suppliers"
    },
    COURT_SEARCH: {
      CASE_DATE: "Case Date",
      CASE_STATE: "Case State",
      COURT_DOMINANT_VALUES: "Court Dominant Values",
      GROUP_ID: "Group ID",
      NOT_FOUND: "Litigation information on {businessName} was not found",
      PERSON: "Person",
      RECORD_COUNT: "Record Count",
      RELEVANCE: "Relevance",
      SEARCH_RESULTS: "Search Results",
      SSN: "Ssn",
      TITLE: "Business Court Search"
    },
    DEAD: {
      DEAL: "Dead Deal",
      DESCRIPTION_PLACEHOLDER:
        "Description (Optional - Limited to 250 Characters)",
      DONE: "Deal is Dead",
      EMAIL_NOTIFICATION_WARNING:
        "Please enable 'dead' status email notifications in workflow settings",
      EMPTY: "Dead reason cannot be empty.",
      IN_PROGRESS: "Moving Deal to Dead...",
      REASON_PLACEHOLDER: "Dead Status",
      SEND_EMAIL: "Send dead email",
      SUB_REASON_PLACEHOLDER: "Dead Sub-Status",
      TITLE: "Why is the Deal Dead?"
    },
    DEAL_DECLINED: "Deal Declined",
    DEAL_NOTES: {
      CHOSE_GROUP_MEMBERS: "Choose group members who can see your notes here",
      EARLIER: "Earlier",
      EMPTY_STATE_TEXT1: "Your deal notes will appear here",
      EMPTY_STATE_TEXT2: "Note anything related to {type} for your {group}.",
      EMPTY_STATE_TEXT3: "colleagues and clients",
      EMPTY_STATE_TEXT3_BUSINESS: "colleagues, clients, and lenders",
      INTERNAL_NOTE: "Internal note",
      INTERNAL_NOTE_DESCRIPTION:
        "If on, note will only be seen by your team members.",
      TITLE: "Deal Notes",
      TYPE_OF_NOTE: "Type of Note",
      UNREAD: "Unread",
      USERS_WHO_SAW_NOTE: "Users who have viewed this note",
      VIEWS: "Views",
      VISIBLE_IN_DEALS_LIST: "Visible in deals list",
      WHO_CAN_SEE_NOTE: "Who can see this note?"
    },
    DEAL_PROGRESS: {
      ACCOUNT_NAME: "Name of the Account: {name}",
      ACCOUNT_TYPE: "Type of Account: {type}",
      ADD_NEW_STIP: "Add New Stip",
      ADD_OFFER: "Add Offer",
      AFFILIATED_BUSINESSES_INFO: {
        ADDITIONAL_INFO: {
          MODAL: {
            TITLE_EDIT: "Edit Additional Affiliated Business Information"
          },
          NO_INFO: "No additional affiliated businesses information",
          TITLE:
            "Additional Affiliated Business Information | Additional Affiliated Businesses Information"
        },
        ADD_NEW: "Add New Affiliated Business Information",
        DELETE: "Delete Affiliated Business Information",
        DELETE_BODY:
          "Are you sure you want to delete this affiliated business information?",
        MODAL: {
          TITLE_EDIT: "Edit Affiliated Business Information"
        },
        NO_INFO: "No affiliated businesses information",
        TITLE:
          "Affiliated Business Information | Affiliated Businesses Information"
      },
      ALLOWED_FILE_TYPES: "Allowed file types",
      APPROVED_OFFERS: "Approved Offers",
      BANKING_COLLECTION: {
        BANK_ACC_DETAILS: "Bank Account Details",
        PLAID_COLLECTION: "Plaid Collection",
        PLAID_CONNECTION: "Plaid Connection",
        PLAID_TOKEN: "Plaid Token",
        ROUTING: "Routing",
        SUBTYPE: "Subtype",
        SUMMARY_OF_ACCOUNTS: "Summary of Accounts",
        TITLE: "Bank Collection"
      },
      BANK_CONNECTION: {
        ACCOUNTS_DATA: "Accounts Data",
        APP_NOT_FINISHED:
          "Application is not finished. Please complete it in order to compute the data.",
        ASSET_REPORT: "Asset Report",
        BEGIN_BALANCE: "Begin Balance",
        CFA_DONE_NO_BANK_ACC:
          "CFA analysis done, but there is no bank accounts found",
        CFA_NOT_DONE:
          "CFA analysis usually takes up to 10 minutes to be done. It started {noOfMinutes} minute(s) ago ... Please, be patient, it's almost done.",
        CHOOSE_A_BANK: "Choose a bank to start processing",
        DATE_ERROR: "Start date has to be before or equal to end date",
        DEPOSITS_CREDITS: "Deposits/Credits",
        DEPOSIT_COUNT: "Deposit Count",
        DOC_SECTION: "documents section",
        END_BALANCE: "End Balance",
        HISTORICAL_BALANCES: "Historical Balances",
        INCLUDE_INSIGHTS: "Include Insights",
        GENERATE_PLAID_LINK: "Generate New Link",
        LOW: "Low",
        MANUAL_UPLOAD:
          "Bank statements were uploaded manually. You can view them in the ",
        MONTHLY_BALANCE: "Monthly Balance",
        MONTHLY_SUMMARY: "Monthly Summary",
        NEG_DAYS: "Negative Days",
        NEG_NO: "Neg #",
        NEW_ASSET_REPORT:
          "@:COMMON.NEW @:DEALS.DEAL_PROGRESS.BANK_CONNECTION.ASSET_REPORT",
        NOT_CONNECTED: "Plaid has not been connected.",
        NO_DATA_RETURNED: "{service} did not return any data.",
        NO_TRANSACTIONS: "There are no transactions to display from Plaid.",
        PLAID_NOT_CALLED: "{type} was not called",
        PLAID_TRANSACTIONS: "Plaid Transactions Report",
        RUN_BOTH:
          "@:COMMON.RUN @.upper:COMMON.JSON + @.upper:COMMON.PDF {type}",
        RUN_OCROLUS_PDF:
          "@:COMMON.RUN @:SERVICES.OCROLUS @.upper:COMMON.PDF {type}",
        RUN_NEW_ASSET_REPORT:
          "@:COMMON.RUN a @:DEALS.DEAL_PROGRESS.BANK_CONNECTION.NEW_ASSET_REPORT",
        RUN_NEW_ASSET_REPORT_MODAL_DESC:
          "Are you sure you would like to create a @.lower:COMMON.NEW @:DEALS.DEAL_PROGRESS.BANK_CONNECTION.ASSET_REPORT?",
        RUN_PLAID_JSON: "@:COMMON.RUN @:SERVICES.PLAID @.upper:COMMON.JSON",
        RUN_OCROLUS_PLAID:
          "@:DEALS.DEAL_PROGRESS.BANK_CONNECTION.RUN_OCROLUS_PDF @:COMMON.AND @:SERVICES.PLAID @.upper:COMMON.JSON",
        PROCESS_PLAID: "Process Ocrolus From Plaid",
        RUN_PLAID_TRANSACTIONS: "Run Plaid Transactions Report",
        RUN_PLAID_ASSET:
          "Run Plaid @:DEALS.DEAL_PROGRESS.BANK_CONNECTION.ASSET_REPORT",
        REFRESH_PLAID_BUTON: "Refresh Plaid",
        REQUEST_MANUAL_UPLOAD:
          "Generate an SDK link above to request a new Plaid connection or manually uploaded bank statements.",
        SUMMARY: "Summary",
        SYNCED_DATA: "Synced Data",
        TITLE: "Bank Data",
        TRANSACTIONS: "Transactions",
        TRANSACTIONS_EMPTY: "There are no transactions yet",
        WIDGET_LINK: "Bank Data Widget Link",
        WITHDRAWALS_COUNT: "Withdrawals Count",
        WITHDRAWALS_DEBITS: "Withdrawals/Debits"
      },
      BANK_DATA: {
        BANK_DATA_TITLE: "Bank Data",
        CREDIT_CATEGORY: "Credit @:COMMON.CATEGORY",
        DATE_TRANSACTED: "Date Transacted",
        FILTER_BY_MONTH: "@:COMMON.FILTER by month",
        GENERATE_STATEMENTS: "Generate PDF Bank Statements",
        INSTITUTION: "Institution",
        ISO_CURRENCY_CODE: "ISO Currency Code",
        MERCHANT_NAME: "Merchant @:COMMON.NAME",
        ORIGINAL_DESCRIPTION: "Original Description",
        PENDING_TRANSACTION_ID:
          "@:COMMON.PENDING @:COMMON.TRANSACTION @:COMMON.ID",
        TRANSACTION_ID: "@:COMMON.TRANSACTION @:COMMON.ID",
        TRANSACTION_TYPE: "@:COMMON.TRANSACTION Type",
        UNOFFICIAL_CURRENCY_CODE: "Unofficial Currency Code"
      },
      BANK_INSTITUTION: "Banking Institution: {name}",
      BUSINESS_INFO: {
        ACTUAL_CREDIT_SCORE: "Actual Credit Score",
        ACTUAL_MONTHLY_REVENUE: "Actual Monthly Revenue",
        ADDITIONAL_BUSINESS_INFO: {
          TITLE: "Additional Business Information",
          TITLE_EDIT: "Edit Additional Business Information"
        },
        ADDITIONAL_BUSINESS_INFO_ER: {
          PAYMENT_TYPE: "Payment Type",
          PREFERRED_CONTACT_METHOD: "Preferred Contact Method",
          PREFERRED_INVOICING_METHOD: "Preferred Invoicing Method",
          TAX_EXEMPT: "Tax Exempt",
          TITLE: "Accounts Payable Information",
          TITLE_EDIT: "Edit Accounts Payable Information"
        },
        ADDITIONAL_INFO: "Additional Information",
        ADDRESS: "Business Address",
        ADDRESS_LINE: "Address line {no}",
        ADD_NEW_ADDRESS: "Add New Address",
        AFFILIATED_INDIVIDUALS: {
          ADDRESS: "Address",
          ADD_NEW: "Add Affiliated Individual",
          BUSINESS: "Affiliated Business",
          BUSINESS_LEGAL_NAME: "Affiliated Business Legal Name",
          DATE_OF_BIRTH: "Date of Birth",
          DELETE: "Delete Affiliated Individual",
          DELETE_BODY:
            "Are you sure you want to delete this affiliated individual?",
          DRIVERS_LICENSE_NUMBER: "Driver's License Number",
          DRIVERS_LICENSE_STATE: "Driver's License State",
          EDIT_ACTIVE: "Edit Affiliated Individual",
          EMAIL_ADDRESS: "Email Address",
          FIRST_NAME: "First Name",
          ITIN: "ITIN",
          LAST_NAME: "Last Name",
          NO_BUSINESS: "Please create Affiliated Business before.",
          NO_INDIVIDUALS: "No Affiliated Individuals",
          ONE: "Affiliated Individual",
          SSN: "SSN",
          TELEPHONE: "Phone",
          TITLE: "Affiliated Individuals Information"
        },
        AFFILIATED_INDIVIDUALS_ADDITIONAL_INFORMATION: {
          ACTUAL_CREDIT_SCORE: "Actual Credit Score",
          ADD_NEW: "Add Additional Affiliated Individual",
          CREDIT_LIMIT: "Credit Limit",
          CREDIT_PERMISSION: "Credit Permission",
          CREDIT_UTILIZATION: "Credit Utilization",
          EDIT_ACTIVE: "Edit Additional Affiliated Individual",
          INQUIRIES_SIX_MONTHS: "Inquiries 6 Months",
          LEGAL_IMMIGRATION_STATUS: "Legal Immigration Status",
          NO_INDIVIDUALS: "No Additional Affiliated Individuals",
          ONE: "Additional Affiliated Individual",
          PERSONAL_INCOME: "Personal Income",
          PRIMARY_LANGUAGE_SPOKEN: "Primary Language Spoken",
          STATED_CREDIT_SCORE: "Stated Credit Score",
          TITLE: "Additional Affiliated Individual Information",
          TOTAL_DEBT: "Total Debt"
        },
        AVERAGE_BALANCE: "Average Balance",
        BIN: "@:DEALS.EXPERIAN.TITLE @:DEALS.EXPERIAN_MATCH.BIN",
        BN: "Business Number",
        BONDING_AGENT_INFO: {
          COMPANY: "Company",
          COMPANY_NAME: "Company Name",
          CONTACT_FIRST_NAME: "First Name",
          CONTACT_LAST_NAME: "Last Name",
          EDIT: "Edit Bonding Agent Information",
          TITLE: "Bonding Agent Information"
        },
        BUSINESS_DESCRIPTION: "Business Description",
        BUSINESS_EXPERIENCE: "Business Experience",
        BUSINESS_NAME: "Business Name",
        CITY: "City",
        COMPANY_DEBTS: {
          BALANCE_OWED: "Balance Owed",
          DELETE: "Delete Debt",
          DELETE_BODY: "Are you sure you want to delete this debt?",
          EDIT: "Edit Company Debt Information",
          EDIT_ACTIVE: "Edit Company Debt",
          LENDER_LIMIT: "Lender Limit",
          LENDER_NAME: "Lender Name",
          MATURITY_DATE: "Maturity Date",
          NATURE_OF_DEBT: "Nature of Debt",
          NO_DEBTS: "No company debts",
          ONE: "Company Debt",
          TERM_LENGTH: "Term Length, months",
          TITLE: "Company Debts",
          TYPE_DEBT: "Type of Debt"
        },
        COMPANY_OFFICERS: {
          COMPNAY_OFFICER: "Officer",
          DELETE: "Delete Officer",
          DELETE_BODY: "Are you sure you want to delete this officer?",
          EMAIL: "Email Address",
          FIRST_NAME: "First Name",
          JOB_TITLE: "Officer Title",
          LAST_NAME: "Last Name",
          NO_OFFICERS: "No company officers",
          TITLE: "Company Officers"
        },
        COUNTRY: "Country",
        CREDIT_LIMIT: "Credit Limit",
        CREDIT_UTILIZATION: "Credit Utilization",
        CURRENT_BUSINESS_DEBT: "Current Business Debt",
        CUSTOMER_INFO: {
          AMOUNT: "Approximate Next 12 Months Sales",
          BUSINESS_NAME: "Customer Business Name",
          CUSTOMERS_BUSINESS_NAME: "Customer Business Name",
          EDIT: "Edit Customer Information",
          NO_CUSTOMERS: "No customers",
          PAYMENT_TERM: "Payment Term",
          PAYMENT_TERM_MONTHS: "Payment Term (Days)",
          TITLE: "Customer Information"
        },
        CUSTOM_FIELD: "Custom Field",
        DOT_NUMBER: "DOT #",
        DUNS: "@:ORGANIZATION.BUSINESS_CREDIT_DATA.DUNS",
        EDIT_FUNDING_INFO_TITLE: "Edit Funding Information",
        EIN: "Employer Identification Number",
        EMAIL: "Email",
        ESTIMATED_ANNUAL_REVENUE: "Estimated Annual Revenue",
        EXTERNAL_NAICS_DESCRIPTION: "External NAICS Description",
        EXTERNAL_SIC_DESCRIPTION: "External SIC Description",
        FINANCIAL_PROJECTIONS: "Financial Projections",
        FISCAL_YEAR_END: "Fiscal Year End",
        FOOTER_TEXT: "Footer text",
        FORMATION_DATE: "Formation Date",
        FRANCHISE: "Franchise",
        FUNDING_AMOUNT_REQUESTED: "Funding Amount Requested",
        FUNDING_INFO_TITLE: "Funding Information",
        GENERATING_REVENUE: "Generating Revenue",
        HAS_ACCOUNTING_SOFTWARE: "Business Management Software",
        HAS_WEBSITE: "Has Website?",
        INCOMPLETE_EMAIL: "Incomplete Email",
        INCOMPLETE_PHONE: "Incomplete Phone",
        INDUSTRY_OTHER: "Industry Details",
        INDUSTRY_TYPE: "General Industry",
        INQUIRIES: "Inquiries 6 Months",
        INVOLVED_IN_BANKRUPTCY: "Involved in Bankruptcy",
        LEGAL_NAME: "Business Legal Name",
        NAICS: "NAICS code",
        NOTES: "Business Notes",
        NO_OF_EMPLOYEES: "Number of Employees",
        OPERATING_EXPENSE: "Operating Expense",
        PRIMARY_ADDRESS: "Primary Address",
        SAVE_NEW_ADDRESS: "Save New Address",
        SIC_CODE: "SIC Code",
        START_DATE: "Business Start Date",
        STATE: "State",
        STATED_ANNUAL_REVENUE: "Stated Annual Revenue",
        STATED_INDUSTRY: "Stated Industry",
        STATED_MONTHLY_REVENUE: "Stated Monthly Revenue",
        STATED_NUMBER_OF_EMPLOYEES: "Stated Number of Employees",
        STATE_OF_INCORPORATION: "State of Incorporation",
        STRUCTURE_TYPE: "Business Structure Type",
        SUB_INDUSTRY: "Sub-Industry",
        TITLE: "Business Information",
        TITLE_EDIT: "Edit Business Address & Summary",
        TRADE_REFERENCE: {
          BUSINESS_ADDRESS: "Business Address",
          BUSINESS_CONTACT_FIRST_NAME: "Business Contact First Name",
          BUSINESS_CONTACT_LAST_NAME: "Business Contact Last Name",
          BUSINESS_EMAIL: "Business Email",
          BUSINESS_NAME: "Business Name",
          BUSINESS_PHONE: "Phone",
          CREDIT_LIMIT: "Credit Limit",
          CREDIT_TERM: "Credit Terms",
          DATE_ACCOUNT_OPENED: "Date Account Opened",
          DATE_OF_LAST_TRANSACTION: "Date of Last Transaction",
          DAYS_BEYOND_TERMS: "Days Beyond Terms",
          DELETE: "Delete Trade Reference",
          DELETE_BODY: "Are you sure you want to delete this trade reference?",
          EDIT_ACTIVE: "Edit Trade Reference",
          HIGHEST_PREVIOUS_BALANCE: "Highest Previous Balance",
          NO_DATA: "No Trade References",
          NUMBER_OF_CREDIT_TRANSACTIONS: "Number of Credit Transactions",
          NUMBER_OF_LATE_PAYMENTS: "Number of Late Payments",
          ONE: "Trade Reference",
          OPEN_AR_BALANCE: "Open AR Balance",
          PAST_DUE_BALANCE: "Past Due Balance",
          TITLE: "Trade References"
        },
        USE_OF_FUNDS: "Used Funds For",
        USE_OF_FUNDS_DESCRIPTION: "Use of Funds Description",
        VETERAN: "Veteran",
        W2_EMPLOYEES_2020: "W2 Number Of Employees 2020",
        W2_EMPLOYEES_2021: "W2 Number Of Employees 2021",
        WEBSITE: "Business Website",
        WHEN_FUNDS_NEEDED: "When Funds Needed",
        WRITTEN_BUSINESS_PLAN: "Written Business Plan",
        ZIP: "ZIP"
      },
      CLIENT_FEEDBACK: "Client Feedback",
      COMPLETED_STIPS: "Completed Stips",
      CONFIRM_FUNDING: "Are you sure all Funding values are correct?",
      CONFIRM_FUNDING_DETAILS: "Confirm Funding Details",
      CONFIRM_FUNDING_TITLE: "Confirm Funding",
      CONFIRM_OFFERS_DELETE: "If you delete offers you won`t recover it!",
      CONFIRM_OFFER_DELETE: "If you delete offer you won`t recover it!",
      CONTACT_MERCHANT: "Contact the merchant to have them sign contract.",
      COPY_APPLICATION_LINK: "Copy Resume Application Link",
      CREATE_OFFER: "Create Offer",
      CUSTOMER_REPORTED_TIME_IN_BUSINESS: "Customer reported Time in Business",
      DELETE_OFFER: "Do you want to delete this offer?",
      DELETE_OFFERS: "Do you want to delete these offers?",
      EDIT_FUNDING_DETAILS: "Edit Funding Details",
      EDIT_OFFER: "Edit Offer",
      EDIT_REQ_STIPS: "Edit Stips",
      ESTIMATED_FUNDING_RANGE: "Estimated Funding Range",
      FUNDER_NOT_FOUND: "Funder not found.",
      FUNDING_AMOUNT: "Funding Amount",
      FUNDING_CALENDAR: "Funding Calendar",
      FUNDING_DETAILS: "Funding Details",
      FUNDING_RATES: "Funding Rates",
      FUNDING_REQUEST: "Prequal Funding Request",
      FUNDING_SUMMARY: "Funding Summary",
      GENERATE_SW_LINK: "Generate Link",
      INCOMPLETED_STIPS: "Incomplete Stips",
      INFORMATION: "Information",
      LAST_REFRESH: "Date of Last Refresh: {date}",
      LOAN_SERVICING: "Loan Servicing",
      MCA_TRACK_DESCRIPTION: "Offer details will be submitted to MCA Track.",
      MONTHLY_REVENUE: "Prequal Monthly Revenue",
      NO_BANK_ACCOUNTS: "No bank accounts detected",
      NO_BUSINESS_FOUND_EXPL_1:
        "Business match was not found with the information provided.",
      NO_BUSINESS_FOUND_EXPL_2:
        "Please input or edit the application information and then re-run the search.",
      NO_EIN:
        "EIN is not set. Service can return inaccurate data if EIN is not sent.",
      NO_INDIVIDUAL_FOUND_EXPL_1:
        "Individual match was not found with the information provided.",
      NO_INDIVIDUAL_FOUND_EXPL_2:
        "Please input or edit the deal information and then re-run the search.",
      NO_PLACEMENTS: "No placements available",
      NO_REQUIRED_STIPS: "There are no stips",
      OFFERS: "Offers",
      OFFER_DETAILS: "Offer Details",
      PERSONAL_CREDIT_SCORE: "Personal Credit Score",
      PERSONAL_INFO: {
        ADDRESS: "Address",
        ADD_NEW_OWNER: "Add New Individual",
        COMPANY_OWNERSHIP: "Company Ownership",
        CREDIT_PERMISSION: "Credit Permission",
        DBA: "Doing Business as",
        DELETE_OWNER_BODY: "Are you sure you want to delete this individual?",
        DELETE_OWNER_TITLE: "Delete Individual",
        DOB: "Date of Birth",
        DRIVERS_LICENSE_NUMBER: "Driver's License Number",
        DRIVERS_LICENSE_STATE: "Driver's License State",
        EDIT_PERSONAL_INFO: "Edit Individual Information for {name}",
        EMAIL: "Email",
        FULL_NAME: "Full Name",
        HOME_ADDRESS: "Home Address",
        INCOME_AND_DEBT_INFO: "Income and Debt Information",
        ITIN: "Individual Taxpayer Identification Number",
        LEGAL_IMMIGRATION_STATUS: "Legal Immigration Status",
        NAME: "Name",
        OWNERSHIP_PERCENTAGE: "Ownership Percentage",
        PERSONAL_INCOME: "Personal Income",
        PHONE: "Phone Number",
        PRIMARY_CONTACT_DESCRIPTION:
          "Assign contact responsible for the deal. Current primary contact",
        PRIMARY_PHONE: "Primary Phone Number",
        SELECT_PERCENTAGE: "Select percentage of company this user owns",
        SET_PRIMARY_CONTACT: "Set as primary contact",
        SIN: "Social Insurance Number",
        SSN: "Social Security Number",
        TITLE: "Personal Information",
        TOTAL_DEBT: "Total Debt"
      },
      PLACEMENTS: "Placements",
      PREQUAL_DETAILS: "Prequal details",
      REQUIRED_STIPS: "Stips",
      REQUIRED_STIPS_DEAL: "Stips for Deal",
      ROLLBACK_PROGRESS: "Rollback Progress",
      SELECT_CLOSING_STATUS: "Select closing status",
      SELECT_OFFER_TO_UPDATE: "Select offer(s) and send to applicant:",
      SEND_BACK_TO: "Send back to {status}",
      SEND_CONTRACT: "Send Contract",
      SEND_CONTRACT_SUCCESS: "Contract sent successfully",
      SEND_TO_FUNDER: "Send to Funder",
      SEND_VIA_EMAIL: "Send via Email",
      SERVICING_WIDGET: "Servicing Widget",
      SERVICING_WIDGET_LINK: "Stips Collection Link",
      SET_OFFER_DETAILS: "Set Offer Details",
      SET_REQUIRED_STIPS: "Set Stips",
      SIGNED: {
        ADD_MANUALLY: "Add Manually",
        COMPANY_OWNERSHIP: "COMPANY OWNERSHIP: {percent}%",
        GENERATE_NEW_LINK: "Generate New Link",
        NOT_SIGNED: "Application is not signed!",
        REQ_IN_WIDGET: "Request in widget",
        RESIGN_LINK: "Document re-sign link:",
        SIGN_LINK: "Document sign link:",
        TITLE: "Signed Applications",
        UPLOAD_NEW_SIGNED: "Upload new signed document"
      },
      SUBMIT_DETAILS: "Submit Details",
      SUBMIT_TO_MCA: "Submit to MCA Track",
      SUBMIT_TO_SERVICER: "Submit to Servicer",
      UNDERWRITING: {
        ACCOUNTING: "Accounting",
        ACCOUNTING_DATA: "Accounting",
        BANKRUPTCIES: "Bankruptcies",
        BUSINESS_CREDIT: "Business Credit",
        BUSINESS_LIENS: "Business Liens",
        BUSINESS_MATCH: "Business Match",
        CFA: "Cash Flow Analysis",
        CFA_ERROR: "An error occurred during the CFA analysis. - {error}",
        CFA_IN_PROGRESS:
          "Analysis is still in progress. It started {timePassed}... Check this section again in few minutes.",
        CFA_PLEASE_WAIT: "Please wait while we are updating data for you!",
        CFA_PROCESSING: "Processing...",
        CFA_SUSPICIOUS_ACTIVITY: "Suspicious Activity",
        CFA_SUSPICIOUS_ALERT_MESSAGE:
          "File tampering detected - tampering on pages:",
        CFA_TITLE: "Cash Flow Analysis",
        CFA_WAIT_TIME: "Avg. waiting time 3-5 min.",
        CFF_TITLE: "Cash Flow Features",
        CLICK_TO_RUN_CFA: "Click on the button to run Cash Flow Analysis",
        CODAT_DESCRIPTION:
          "Codat is an API platform that provides real-time connectivity to the financial software used by small businesses.",
        DOCUMENT_VERIFICATION: {
          ADDING_FILE: "Adding File...",
          BACK: "Back",
          CHOOSE_FILE: "choose a file",
          CHOOSE_FROM_SYSTEM: "Choose File From System",
          DRAG_DROP: "Drag & Drop here",
          FILE_TYPE: "File Type",
          FRAUD: "Fraud",
          FRONT: "Front",
          LAST_UPDATE: "Last Update",
          NAME: "Name",
          PROCESSING: "We're Processing User's Data",
          RUN: "Run",
          TABS: {
            ALL: "All",
            APPLICATION_STIPS: "Application Stips",
            AP_DOCUMENTS: "AP Documents",
            BANK_STATEMENTS: "Bank statements",
            CFA_DATA: "CFA Data",
            CONTRACTS: "Contracts",
            FINANCIAL_DOCUMENTS: "Financial Documents",
            FUNDING_INVOICES: "Funding Invoices",
            INQUIRIES: "Inquiries",
            INSURANCE_CERTIFICATES: "Insurance Certificates",
            OFFER_STIPS: "Offer Stips",
            PLAID_STATEMENTS: "Plaid Statements",
            RECOVERED_DOCUMENTS: "Recovered documents",
            SIGNED_APPLICATION: "Signed application",
            TAX_EXEMPTIONS: "Tax Exemptions",
            TAX_RETURNS: "Tax Returns",
            VERIFICATION_DOCUMENTS: "Verification Documents"
          },
          TITLE: "Document Verification",
          UPLOAD: "Upload",
          UPLOADED_DATE: "Uploaded Date",
          UPLOAD_COMPLETED: "Document Upload Completed",
          UPLOAD_DOCUMENT: "Upload Document",
          UPLOAD_FROM_COMPUTER: "Upload File From Computer",
          VERIFICATION_FAILED: "Verification Failed",
          VERIFICATION_PASSED: "Verification Passed"
        },
        DOC_VERIFICATION: "Document Verification",
        ECOMMERCE: "Ecommerce",
        ET_TITLE: "Enriched Transactions",
        FRAUD: "Fraud",
        FRAUD_SIGNALS_TITLE: "Fraud Signals",
        FSR: "FSR",
        INDIVIDUAL_MATCH: "Individual Match",
        INSCRIBE: {
          ACCOUNT_TYPE: "Account Type",
          ADD_NEW_DOCUMENT: "Add New Document",
          AVERAGE_DAILY_BALANCE: "Average Daily Balance",
          BEGINNING_BALANCE: "Beginning Balance",
          BEGINNING_DATE: "Beginning Date",
          CHARGES: "Charges",
          COLLECT_SESSION: "Collect Session",
          CONFIDENCE: "Confidence",
          CREDITS_COUNT: "Credits Count",
          CREDITS_TOTAL_GROSS: "Credits Total Gross",
          CUSTOMER_ID: "Customer ID",
          CUSTOMER_NAME: "Customer Name",
          DATA_TYPE: "Data Type",
          DEBITS_COUNT: "Debits Count",
          DEBITS_TOTAL_GROSS: "Debits Total Gross",
          ENDING_BALANCE: "Ending Balance",
          ENDING_DATE: "Ending Date",
          FORMATTED: "Formatted/Structured",
          INSTITUTION_NAME: "Institution Name",
          IS_FRAUDULENT: "Is Fraudulent",
          LENDING_ACTIVITY: "Lending Activity",
          MATCH_STATUS: "Match Status",
          MAXIMUM_BALANCE: "Maximum Balance",
          MAXIMUM_MINUS_MINIMUM_BALANCE: "Maximum Minus Minimum Balance",
          MINIMUM_BALANCE: "Minimum Balance",
          NORMALIZED: "Normalized",
          NSF: "NSF",
          NUMBER_OF_DAYS_WITH_NEGATIVE_BALANCE:
            "Number Of Days With Negative Balance",
          OVERDRAFTS: "Overdrafts",
          PAGE_NUMBER: "Page Number",
          PAYMENTS: "Payments",
          QUALITY_SCORE: "Quality Score",
          RAW: "Raw",
          RECOVERED_DOCUMENT: "Recovered Document",
          REFUNDED: "Refunded",
          REFUND_TRANSACTION_ID: "Refund Transaction ID",
          REPAYMENTS: "Repayments",
          REVENUE_ANNUAL: "Revenue Annual",
          REVENUE_FOR_PERIOD: "Revenue For Period",
          RUN_API: "Run Service",
          SALARY: "Salary",
          SALARY_ANNUAL: "Salary Annual",
          SALARY_FOR_PERIOD: "Salary For Period",
          SUBMITTED_DOCUMENT: "Submitted Document",
          SUBTITLE: "Document Verification",
          TITLE: "Inscribe",
          TOTAL_NET: "Total Net",
          TOTAL_PAGES: "Total Pages",
          TRANSACTION_CONFIDENCE: "Transaction Confidence",
          TRANSACTIONS_MATCH_BALANCES: "Transactions Match Balances",
          TRANSACTIONS: "Transactions",
          TRANSFERS_COUNT: "Transfers Count",
          TRANSFERS_CREDITS_TOTAL_GROSS: "Transfers Credits Total Gross",
          TRANSFERS_DEBITS_TOTAL_GROSS: "Transfers Debits Total Gross",
          TRANSFERS_TOTAL_NET: "Transfers Total Net",
          TRUST_SCORE: "Trust Score",
          VARIANTS: "Variants"
        },
        INTELLISCORE: "Intelliscore",
        INTELLISCORE_V3: "Intelliscore V3",
        JUDGEMENTS: "Judgements",
        KYB: "KYB",
        KYC: "KYC",
        LIENS: "Liens",
        LITIGATION: "Litigation",
        MATCH: "Match",
        NO_DATA: "You don't have any data for this section yet",
        OCROLUS: {
          ADDED_FACTORING_TXNS: "Added Factoring Txns",
          ADDED_SPECIFIC_TERMS_TXNS: "Added Specific Terms Txns",
          ALL_NEGATIVE_COUNT: "All Negative Count",
          ALL_NEGATIVE_VALUE: "All Negative Value",
          ALT_LENDING_CREDITS_COUNT: "Alt Lending Credits Count",
          ALT_LENDING_CREDITS_VALUE: "Alt Lending Credits Value",
          ALT_LENDING_DEBITS_COUNT: "Alt Lending Debits Count",
          ALT_LENDING_DEBITS_VALUE: "Alt Lending Debits Value",
          ALTERNATIVE_LENDER_CREDITS_COUNT_BY_MONTH:
            "Alternative Lender Credits Count By Month",
          ALTERNATIVE_LENDER_CREDITS_SUM_BY_MONTH:
            "Alternative Lender Credits Sum By Month",
          ALTERNATIVE_LENDER_DEBITS_COUNT_BY_MONTH:
            "Alternative Lender Debits Count By Month",
          ALTERNATIVE_LENDER_DEBITS_SUM_BY_MONTH:
            "Alternative Lender Debits Sum By Month",
          ALTERNATIVE_LENDER_TXNS: "Alternative Lender Txns",
          ANALYZED_WITH_CLASS: "Analyzed With {class}",
          AVERAGE_DAILY_BALANCE_BY_MONTH: "Average Daily Balance by Month",
          AVERAGE_DAILY_BALANCE_WEEKDAYS_BY_MONTH:
            "Average Daily Balance Weekdays by Month",
          AVERAGE_DEPOSIT_BY_MONTH: "Average Deposit by Month",
          AVERAGE_TRANSACTION_SIZE_BY_MONTH:
            "Average Transaction Size by Month",
          AVG_ALT_LENDER_PAYMENT: "Avg Alt Lender Payment",
          AVG_PER_COUNTERPARTY: "Avg Per Counterparty",
          AVG_AMOUNT: "Avg Amount",
          AVG_DAILY_BALANCE: "Avg Daily Balance",
          AVG_DAILY_BALANCE_WEEKDAY: "Avg Daily Balance Weekday",
          AVG_DAILY_CASH_FLOW: "Avg Daily Cash Flow",
          AVG_DAILY_CASH_FLOW_WEEKDAY: "Avg Daily Cash Flow Weekday",
          AVG_DEPOSIT: "Average Deposit",
          AVG_ESTIMATED_MONTHLY_REVENUE: "Avg Estimated Monthly Revenue",
          AVG_MCA: "Avg Merchant Cash Advance",
          BANK_ACCOUNT_CATEGORY: "Bank Account Category",
          BANK_ACCOUNT_HOLDER: "Bank Account Holder",
          BANK_ACCOUNT_NUMBER: "Bank Account Number",
          BANK_ACCOUNT_TYPE: "Bank Account Type",
          BANK_ACCOUNT_PK: "Bank Account PK",
          BANK_BRANCH: "Bank Branch",
          BEGIN_BALANCE: "Begin Balance",
          BBOX: "Bbox",
          BOOK_DASHBOARD_URL: "Book Dashboard URL",
          BOOK_SUMMARY: "Book Summary",
          BOOK_SUMMARY_LEGACY:
            "@:DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.BOOK_SUMMARY - @:COMMON.LEGACY",
          BOOK_SUMMARY_V2:
            "@:DEALS.DEAL_PROGRESS.UNDERWRITING.OCROLUS.BOOK_SUMMARY - V2",
          BOOK_UUID: "Book UUID",
          CASH_BALANCE_TREND: "Cash Balance Trend",
          CASH_EXPENSE_DAYS_BY_MONTH: "Cash Expense Days by Month",
          CASH_FLOW_AT_A_GLANCE: "Cash Flow at a Glance",
          CASH_FLOW_TREND: "Cash Flow Trend",
          COUNTERPARTY: "Counterparty | Counterparties",
          CREDIT_BUILDER: "Credit Builder",
          CREDIT_CARD: "Credit Card",
          CREDIT_TRANSFER: "Credit Transfer",
          DAILY_BALANCE: "Daily Balance",
          DAILY_MONTHLY_STATS: "Daily/Monthly Stats",
          DEBIT_CARD: "Debit Card",
          DEBIT_TRANSFER: "Debit Transfer",
          DEBT_CONSOLIDATOR: "Debt Consolidator",
          DEBT_CONSOLIDATOR_TXNS: "Debt Consolidator Txns",
          DEDUCTED_ALTERNATIVE_LENDER_TXNS: "Deducted Alternative Lender Txns",
          DEDUCTED_SPECIFIC_TERMS_TXNS: "Deducted Specific Terms Txns",
          DEDUCTED_TRANSFER_DEPOSIT_TXNS: "Deducted Transfer Deposit Txns",
          DEPOSITS: "@:COMMON.DEPOSITS",
          DEPOSIT_COUNT: "Deposit Count",
          DEPOSIT_MAX: "Deposit Max",
          DEPOSIT_MIN: "Deposit Min",
          DEPOSIT_SUM: "Deposit Sum",
          DETECT_STATUS: "Detect Status",
          DISPLAY_NAME: "Display Name",
          DISTRIBUTION: "Distribution",
          DOCUMENT_ANALYSIS: "Document Analysis",
          DOCS_PROCESSED: "Docs Processed",
          END_BALANCE: "End Balance",
          EQUIPMENT_LEASE: "Equipment Lease",
          ERROR_MESSAGE: "Error Message | Error Messages",
          ESTIMATED_DAILY_REVENUE_BY_MONTH: "Estimated Daily Revenue by Month",
          ESTIMATED_EXPENSE_TXNS: "Estimated Expense Txns",
          ESTIMATED_REVENUE_TXNS: "Estimated Revenue Txns",
          ESTIMATED_REVENUE: "Estimated Revenue",
          ESTIMATED_REVENUE_BY_MONTH: "Estimated Revenue by Month",
          ESTIMATED_REVENUE_CALC_TXNS: "Estimated Revenue Calc Txns",
          FACTOR_TXNS: "Factor Txns",
          FACTORING: "Factoring",
          FEDERAL_TAX: "Federal Tax",
          FINTECH_MCA_SOURCES: "Fintech MCA Sources",
          FINTECH_LOAN: "Fintech Loan",
          FINTECH_LOAN_SOURCES: "Fintech Loan Sources",
          FORM_AUTHENTICITY: "Form Authenticity",
          FORM_DASHBOARD_URL: "Form Dashboard URL",
          FORM_TYPE: "Form Type",
          FORM_UUID: "Form UUID",
          IMAGE_BASED_PDF: "Image Based PDF",
          INSURANCE_CREDITS: "Insurance Credits",
          INSURANCE_DEBITS: "Insurance Debits",
          INTERBANK_TXNS: "Interbank Txns",
          LIST_OF_DAYS_NEGATIVE_BALANCE_BY_MONTH:
            "List of Days Negative Balance by Month",
          LIST_OF_WEEKDAYS_NEGATIVE_BALANCE_BY_MONTH:
            "List of Weekdays Negative Balance by Month",
          LISTS: "Lists",
          MERCHANT_CASH_ADVANCE_PAYMENTS_SUM_BY_MONTH:
            "Merchant Cash Advance Payments Sum by Month",
          MERCHANT_SERVICE_TRANSFER: "Merchant Service Transfer",
          MAX_DEPOSIT_BY_MONTH: "Max Deposit by Month",
          MAX_WITHDRAWALS_BY_MONTH: "Max Withdrawals by Month",
          MERCHANT_CASH_ADVANCE_TXNS: "Merchant Cash Advance Txns",
          MIN_DEPOSIT_BY_MONTH: "Min Deposit by Month",
          MIXED_UPLOADED_DOC_PK: "Mixed Uploaded Doc PK",
          MIXED_UPLOADED_DOC_UUID: "Mixed Uploaded Doc UUID",
          MONTH_PERIOD_1: "1 month",
          MONTH_PERIOD_3: "3 months",
          NEGATIVE_BALANCE_COUNT_BY_MONTH: "Negative Balance Count by Month",
          NEGATIVE_BALANCE_DATES: "Negative Balance Dates",
          NEGATIVE_BALANCE_WEEKDAY_COUNT_BY_MONTH:
            "Negative Balance Weekday Count by Month",
          NEGATIVE_BALANCES: "Negative Balances",
          NEGATIVE_BALANCES_WEEKDAY: "Negative Balances Weekday",
          NEGATIVE_CASH_FLOWS: "Negative Cash Flows",
          NEGATIVE_CASH_FLOWS_WEEKDAY: "Negative Cash Flows Weekday",
          NET_CASH_FLOW_SUM: "Net Cash Flow Sum",
          NET_CASH_FLOWS_BY_MONTH: "Net Cash Flows by Month",
          NON_ESTIMATED_REVENUE_TXNS: "Non Estimated Revenue Txns",
          NSF: "NSF",
          NSF_COUNT: "NSF Count",
          NSF_COUNT_BY_MONTH: "NSF Count by Month",
          NSF_FEE_COUNT: "NSF Fee Count",
          NSF_FEE_SUM: "NSF Fee Sum",
          NSF_RETURNED_OR_NOT_PAID: "NSF Returned or Not Paid",
          NSF_TRANSACTIONS: "NSF Transactions",
          NSF_TRANSACTIONS_PAID_OR_NEGATIVE_BALANCE:
            "NSF Transactions Paid or Negative Balance Count",
          NSF_TRANSACTIONS_PAID_OR_NEGATIVE_BALANCE_COUNT_BY_MONTH:
            "NSF Transactions Paid or Negative Balance Count by Month",
          NSF_TRANSACTIONS_RETURNED_OR_NOT_PAID:
            "NSF Transactions Returned or Not Paid Count",
          NSF_TRANSACTIONS_RETURNED_OR_NOT_PAID_COUNT_BY_MONTH:
            "NSF Transactions Returned or Not Paid Count by Month",
          NUM_COUNTERPARTIES: "Num Counterparties",
          NUM_FINTECH_LOAN_SOURCES: "Num Fintech Loan Sources",
          NUM_FINTECH_MCA_SOURCES: "Num Fintech MCA Sources",
          OUTSIDE_SOURCE_DEPOSITS: "Outside Source Deposits",
          PAGE_DOC_PK: "Page Doc PK",
          PAGE_IDX: "Page Idx",
          PAGE_NUMBER: "Page Number",
          PAYROLL: "Payroll",
          PERIODS: "Periods",
          PK: "PK",
          PPP_LOAN_TXNS: "PPP Loan Txns",
          PRIMARY_RECON_ERROR_REASON: "Primary Recon Error Reason",
          PROBABLE_RECURRING_TXNS: "Probable Recurring Txns",
          RECURRING_VS_EPISODIC_INFLOWS_BY_MONTH:
            "Recurring vs Episodic Inflows By Month",
          RECURRING_VS_EPISODIC_OUTFLOWS_BY_MONTH:
            "Recurring vs Episodic Outflows By Month",
          REJECTION_REASON: "Rejection Reason",
          REVENUE: "Revenue",
          REVENUE_DEDUCTION_OTHER: "Revenue Deduction Other",
          REVERSED: "Reversed",
          ROUND_NUMBER_TXNS: "Round Number Txns",
          RUNNING_DAILY_BALANCE: "Running Daily Balance",
          RUNNING_DAILY_CASH_FLOW: "Running Daily Cash Flow",
          SECONDARY_RECON_ERROR_REASON: "Secondary Recon Error Reason",
          SIGNAL_COUNT: "Signal Count",
          TOTAL_ALTERNATIVE_LENDER_PAYMENTS_SUM_BY_MONTH:
            "Total Alternative Lender Payments Sum by Month",
          TOTAL_DEPOSITS_COUNT_BY_MONTH: "Total Deposits Count by Month",
          TOTAL_TRANSACTIONS_COUNT_BY_MONTH:
            "Total Transactions Count by Month",
          TOTAL_WITHDRAWALS_SUM_BY_MONTH: "Total Withdrawals Sum by Month",
          TRANSACTION_COUNT: "Transaction Count",
          TXN_COUNT: "TXN Count",
          TXN_DATE: "TXN Date",
          TXN_PK: "TXN PK",
          UPLOADED_DOCUMENT_UUID: "Uploaded Document UUID",
          UPLOADED_DOC_PK: "Uploaded Doc PK",
          UPLOADED_DOCS: "Uploaded Docs",
          UPLOADED_DOCUMENT_NAME: "Uploaded Document Name",
          WEEKDAYS_NEGATIVE_COUNT: "Weekdays Negative Count",
          WEEKDAYS_NEGATIVE_VALUE: "Weekdays Negative Value",
          WIRE_TRANSFER: "Wire Transfer",
          WITHDRAWAL_COUNT: "Withdrawal Count",
          WITHDRAWALS: "Withdrawals",
          WITHDRAWALS_MAX: "Withdrawals Max",
          WITHDRAWAL_SUM: "Withdrawal Sum",
          XLSX_NOT_AVAILABLE: "The XLSX file is not available for this book"
        },
        PERSONA: {
          ADD_NEW_DOCUMENT: "Add New Document",
          ANALYZE_DATA: "Analyze Data",
          CORRECT_DATA: "Correct Data",
          DATA_LAST_UPDATED: "Data Last Updated",
          DL: "Drivers License",
          DOCUMENT_UPLOAD_ERROR:
            "This document could not be processed. The following error message was received: {errorMessage}.",
          ID: "State ID",
          NOT_APPLICABLE: "Not Applicable",
          PP: "Passport",
          PULL_DATA: "Update Record with Persona Data?",
          TITLE: "Persona",
          WRONG_DATA: "Wrong Data"
        },
        PERSONAL_CREDIT: "Personal Credit",
        PERSONA_PROCESSING: "We're Processing User's Data",
        RAILZ_DESCRIPTION:
          "Railz is a single API that integrates with the majority of accounting software service providers used by small businesses.",
        RE_RUN_CFA: "Refresh CFA",
        RUN_CFA: "Run CFA",
        SOCIAL_SCORING: {
          BANKRUPTCY_RISK: "Bankruptcy Risk",
          DEFAULT_PAYMENT_RISK: "Default Payment Risk",
          LATE_PAYMENT_RISK: "Late Payment Risk",
          SCORELY_ID: "Scorely ID",
          SUBTITLE: "Social Scoring",
          TITLE: "Scorely"
        },
        SUBMITTED_DATA: "Submitted Data To Run API",
        SUBMIT_TO_PLACEMENT: "Submit to Placement",
        UCCS: "UCC filings"
      },
      UNDERWRITING_STIPS_TAB: "Underwriting Stips",
      WIDGET_LINK: "Widget link"
    },
    DEAL_RESTORED: "Deal Restored",
    DEAL_SUMMARY: "Deal Summary",
    DECLINING_DEAL: "Declining Deal...",
    DELETE_DEAL: "Delete Deal?",
    DELETE_DEAL_EXPL:
      "{businessName}, all it’s progress, data and documents will be permanently removed from the system. This operation is irreversible.",
    DISCOVERY_QUESTIONS: {
      ADD_NEW_QUESTION: "Add New Question",
      ADD_WARNING:
        "In order to save your answer click “Save” at the bottom of the section",
      DELETE_QUESTION: "Delete Question?",
      DELETE_WARNING:
        "This question will be permanently deleted. Do you want to proceed?",
      DISCOVERY: "Discovery",
      TITLE: "Discovery Call Questions"
    },
    DNB_BUSINESS_CREDIT: {
      ASSESSMENT_DATE: "Assessment Date",
      AVERAGE_RECOMMENDED_LIMIT: "Average Recommended Limit",
      AVERAGE_RECOMMENDED_LIMIT_ASSESSMENT_DATE:
        "@:DEALS.DNB_BUSINESS_CREDIT.AVERAGE_RECOMMENDED_LIMIT @:DEALS.DNB_BUSINESS_CREDIT.ASSESSMENT_DATE",
      AVERAGE_RECOMMENDED_LIMIT_DESC:
        "The details of the average amount of credit that should be extended to this entity",
      BLOCK_ID: "Block ID",
      COUNTRY_ISO_ALPHA_2_CODE: "Country ISO Alpha 2 Code",
      CREDIT_LIMIT_SUBTITLE:
        "The details of the credit recommendation Dun & Bradstreet is providing for an entity (either an amount or a comment)",
      CREDIT_LIMIT_TITLE: "Credit Limit Recommendation",
      CURRENT_PAYDEX: "Current Paydex",
      DERIVED_TRADE_INSIGHTS: "Derived Trade Insights",
      DUNS_NUMBER: "DUNS Number",
      HAS_SEVERE_NEGATIVE_EVENTS: "Has Severe Negative Events",
      IN_LANGUAGE: "In Language",
      L_1: "L1",
      MAXIMUM_RECOMMENDED_LIMIT: "Maximum Recommended Limit",
      MAXIMUM_RECOMMENDED_LIMIT_DESC:
        "The details of the greatest amount of credit that should be extended to this entity",
      MONTHS_DATA_COVERAGE: "{months} Months Data Coverage",
      PAYDEX_ACCOUNTS_USED_COUNT: "Paydex Accounts Used Count",
      PAYDEX_SUPPLIERS_USED_COUNT: "Paydex Suppliers Used Count",
      PRIMARY_NAME: "Primary Name",
      REASON: "Reason",
      STATUS: "Status",
      TRANSACTION_ID: "Transaction ID",
      TRANSACTION_TIMESTAMP: "Transaction Timestamp"
    },
    DNB_MATCH: {
      CONFIDENCE_SCORE: "Confidence Score: {score}",
      DATA: "Data",
      DENSITY: "Density",
      DENSITY_DESC: "Businesses per square mile within a specific ZIP Code.",
      DIFFERENT: "Different",
      DUNS: "Duns",
      IS_MAIL_UNDELIVERABLE: "Is Mail Undeliverable",
      IS_STANDALONE: "Is Standalone",
      MATCH: "Business Match",
      MATCH_GRADE: "Match Grade",
      MSP: "Most Senior Principals",
      NOT_AVAILABLE: "Missing",
      PRIMARY_COMPANY: "Primary Company",
      PULL_DATA: "Update Record With D&B Data?",
      RESULT: "Result",
      SIMILAR: "Similar",
      STATUS: "Status",
      SUBMITTED_DATA: "Submitted Data to Run API",
      UNIQUENESS: "Uniqueness",
      UNIQUENESS_DESC:
        "Records in the match reference database (A: 1, B: 2-3, F: 4+)"
    },
    DNB_COMPANY_INFORMATION: {
      ACTIVITY_SUBTITLE:
        "The details of the descriptions of operations in different languages.",
      ADDRESSES_SUBTITLE:
        "The details of company’s past and present primary, registered and mailing addresses.",
      AREA_OF_INFLUENCE: "Area of Influence",
      BUSINESS_ENTITY_TYPE: "Business Entity Type",
      BUSINESS_TRUST_INDEX: "Business Trust Index",
      BUSINESS_TRUST_INDEX_SUBTITLE:
        "Records the likelihood of a business attempting evasion.",
      CHARTER_TYPE: "Charter Type",
      CBSA_CODE: "CBSA Code",
      COMPANY_DETAILS: "@:COMMON.COMPANY Details",
      COMPANY_INFORMATION: "@:COMMON.COMPANY Information",
      COMPANY_NAMES: "@:COMMON.COMPANY Names",
      COMPANY_NAMES_SUBTITLE:
        "The details of company’s past and present primary, registered, trade style names in multiple languages.",
      COMPANY_TAGS: "@:COMMON.COMPANY Tags",
      CONGRESSIONAL_DISTRICTS: "Congressional Districts",
      CONNECTED: "Connected",
      CONNECTED_ERROR: "Disconnected",
      CONTACT_INFORMATION: "Contact Information",
      CONTACT_INFORMATION_SUBTITLE: "The details of company’s contacts.",
      CONTINENTAL_REGION: "Continental Region",
      CONTROL_OWNERSHIP_DATE: "Control Ownership @:COMMON.DATE",
      CONTROL_OWNERSHIP_TYPE: "Control Ownership @:COMMON.TYPE",
      CONTROL_STATUS: "Control Status",
      CONTROL_STATUS_SUBTITLE:
        "The details of the organization's functional state and controls that impact its suitability for various usage purposes.",
      COORDINATES: "Coordinates",
      CURRENT_NAMES: "Current Names",
      DEFAULT_CURRENCY: "Default Currency",
      DELIVERABLE: "Deliverable",
      DELIVERABLE_ERROR: "Undeliverable",
      DESIGNATION: "Designation",
      DETAILED_OPERATING_STATUS: "Detailed Operating Status",
      DOMESTIC_ULTIMATE_SUBTITLE:
        "Records the firmograhics information of the Domestic Ultimate company.",
      DOMESTIC_ULTIMATE_TITLE: "Domestic Ultimate",
      EMAIL_ADDRESSES: "Email Addresses",
      EMPLOYER_DESIGNATION: "Employer Designation",
      EXCHANGE: "Exchange | Exchanges",
      EXCHANGE_NAME: "@:DEALS.DNB_COMPANY_INFORMATION.EXCHANGE @:COMMON.NAME",
      EXCHANGE_COUNTRY:
        "@:DEALS.DNB_COMPANY_INFORMATION.EXCHANGE @:COMMON.COUNTRY",
      EXCLUDED_ORGANIZATION: "Excluded Organization",
      FINANCIALS: "Financials",
      FINANCIALS_SUBTITLE:
        "The details of the standardized summary view of the financial results (actual or estimated) for the organization.",
      FORMER_PRIMARY_ADDRESSES: "Former Primary Addresses",
      FORMER_PRIMARY_NAMES: "Former Primary Names",
      FORMER_REGISTERED_ADDRESSES: "Former Registered Addresses",
      FORMER_REGISTERED_NAMES: "Former Registered Names",
      FRANCHISE_OPERATION_TYPE: "Franchise Operation Type",
      FULL_ADDRESS: "Full Address",
      GENERAL_COMPANY_INFO:
        "General information about the @.lower:COMMON.COMPANY",
      GLOBAL_ULTIMATE_SUBTITLE:
        "Records the firmograhics information of the Global Ultimate company.",
      GLOBAL_ULTIMATE_TITLE: "Global Ultimate",
      INCORPORATED_DATE: "Incorporated @:COMMON.DATE",
      INDEX_SCORE: "Index Score",
      INDUSTRY_CODES: "Industry Codes",
      INDUSTRY_CODES_SUBTITLE:
        "The details of the business activities in which the organization is actively engaged using standardized coding schemes.",
      IMPERIAL_CALENDAR_START_YEAR: "Imperial Calendar Start Year",
      LANGUAGE_DESCRIPTION: "@:COMMON.LANGUAGE @:COMMON.DESCRIPTION",
      LANGUAGE_CODE: "@:COMMON.LANGUAGE @:COMMON.CODE",
      LEGAL_EVENT: "Legal Event",
      LEGAL_FORM: "Legal Form",
      LISTED: "Listed",
      LISTED_ERROR: "Delisted",
      LOCALITY: "Locality",
      LOCATION_AND_PREMISES: "Location and Premises",
      MAIL_STATUS: "Mail Status",
      MAILING_ADDRESS: "Mailing Address",
      MARKETABILITY_STATUS: "Marketability Status",
      MARKETABLE: "Marketable",
      MARKETABLE_ERRROR: "Unmarketable",
      MEASUREMENTS: "Measurements",
      MINOR_TOWN: "Minor Town",
      MULTILINGUAL: "Multilingual",
      MULTILINGUAL_NAMES:
        "@:DEALS.DNB_COMPANY_INFORMATION.MULTILINGUAL @:COMMON.NAME",
      MULTILINGUAL_PRIMARY_ADDRESSES:
        "@:DEALS.DNB_COMPANY_INFORMATION.MULTILINGUAL Primary Addresses",
      MULTILINGUAL_TRADE_STYLE_NAMES:
        "@:DEALS.DNB_COMPANY_INFORMATION.MULTILINGUAL @:DEALS.DNB_COMPANY_INFORMATION.TRADE_STYLE_NAME",
      MULTILINGUAL_TRADE_STYLE_NAMES_SUBTITLE:
        "The details of the names, in multiple languages, that the entity trades under for commercial purposes although its registered legal name used for contracts and other formal situations may be another name.",
      NATIONAL_PERCENTILE: "National Percentile",
      NUMBER_OF_EMPLOYEES_SUBTITLE:
        "The details of the number of individuals engaged by a business for various purposes, e.g., to perform its business operations.",
      NUMBER_OF_EMPLOYEES_TITLE: "Number of Employees",
      OPERATING_STATUS: "Operating Status",
      OPERATING_SUBSTATUS: "Operating Substatus",
      ORGANIZATION_SIZE_CATEGORY: "Organization Size Category",
      OWNERSHIP: "Ownership",
      POPULATION_RANK: "Population Rank",
      POST_OFFICE_BOX: "Post Office Box",
      PREFERRED: "Preferred",
      PREFERRED_LANGUAGE:
        "@:DEALS.DNB_COMPANY_INFORMATION.PREFERRED @:COMMON.LANGUAGE",
      PRIMARY_ADDRESS: "Primary Address",
      PRIMARY_NAME: "Primary Name",
      REGISTERED_ADDRESS: "Registered Address",
      REGISTERED_DETAILS: "Registered Details",
      REGISTRATION_NUMBER: "Registration Number | Registration Numbers",
      REGISTRATION_NUMBERS_SUBTITLE:
        "The details of the organization's identifiers issued by a recognized external authority to uniquely identify subjects.",
      REGISTERED_NAME: "Registered Name",
      REGISTRATION_STATUS: "Registration Status",
      REGISTRATION_LOCATION: "Registration Location",
      RELIABILITY: "Reliability",
      SCORE_DATE: "Score @:COMMON.DATE",
      SCORE_REASONS: "Score Reasons",
      SECURITIES_REPORT_ID: "Securities Report ID",
      SELF_REQUESTED_DUNS: "Self Requested DUNS",
      STANDARD_ADDRESS_CODES: "Standard Address Codes",
      STATISTICAL_AREA: "Statistical Area",
      STOCK_EXCHANGE: "Stock @:DEALS.DNB_COMPANY_INFORMATION.EXCHANGE",
      STOCK_EXCHANGE_SUBTITLE:
        "The details of the Stock Exchanges on which the entity's shares are traded.",
      STREET_ADDRESS: "Street @:COMMON.ADDRESS",
      SUMMARY_DETAILS: "The details of the editorial comments for the entity.",
      TELEPHONE_STATUS: "Telephone Status",
      TICKER_NAME: "Ticker @:COMMON.NAME",
      TITLE: "DnB",
      TRADE_STYLE_NAME: "Trade Style Name | Trade Style Names",
      TRADE_STYLE_NAME_SUBTITLE:
        "The details of the names the organization trades under for commercial purposes",
      TREND: "Trend",
      EMPLOYEE_CATEGORIES: "Employee Categories",
      TRUST_CLASS: "Trust Class",
      TRUST_INDEX: "Trust Index",
      UNSPSC_CODES: "UNSPSC Codes",
      UNSPSC_CODES_SUBTITLE:
        "The details of several products and services represented in the United Nations Standard Products and Services Code (UNSPSC) scheme.",
      WEBSITE_ADDRESSES: "Website Addresses"
    },
    DOCUMENTS: {
      ADD_APPLICATIONS: "Add Applications",
      ADD_DOCUMENTS: "Add Documents",
      ADD_NEW: "Upload New Document",
      ADD_NEW_DOCUMENT: "Add New Document",
      APPLICATION: "Application",
      ASSIGN_APPLICATION_TO_OWNER: "Assign Application to Owner",
      ATTACH_DOCUMENT: "Attached Documents",
      ATTACH_DOCUMENTS: "Attach Documents",
      CHOOSE_PRODUCT_TYPE: "Choose Product Type",
      DELETE_CONFIRMATION: "Are you sure you want to delete this document?",
      DELETE_DOC_PROMPT: "{name} will be permanently removed from the system.",
      DELETE_MODAL_TITLE: "Delete Document?",
      DOCUMENT_TYPE: "Document Type",
      DOCUMENT_RETRIEVAL: "Document Retrieval",
      DOWNLOAD: "Download",
      EDIT_DOCUMENT: "Edit Document",
      EMPTY_BODY:
        "Upload documents related to current deal here using the button below.",
      EMPTY_HEADING: "No documents were uploaded yet",
      FILE_BIG: "Selected File(s) Size Exceeds The Limit",
      FILE_BIG_SUBTITLE: "Maximum file size is 100MB.",
      NEW_TASK: "New Task",
      NO_APPLICATIONS_FOR_PRODUCT_TYPE:
        "No signed applications for {productType} added yet",
      PREVIEW_UNSUPPORTED_1: "File could not be previewed.",
      PREVIEW_UNSUPPORTED_2: "Try to download the file.",
      SEARCH_FOR_DOCUMENTS: "for documents",
      SELECT_FILE_TYPE: "Select File Type",
      SELECT_OWNER: "Select Owner",
      SELECT_PRODUCT_TYPE: "Select Product Type",
      SIGNED_DOC: "Signed Application",
      TABLE: {
        LAST_CHANGE: "Last Change",
        NAME: "Name",
        OWNER: "File Owner",
        SIZE: "File Size"
      },
      UPLOAD: "Upload",
      UPLOAD_FAILED: "Upload failed",
      UPLOAD_FILE_SIZE_KB: "{size} kb",
      UPLOAD_FILE_SIZE_MB: "{size} mb",
      UPLOAD_NEW_DOCUMENT: "Upload New Document",
      UPLOAD_TEXT_1: "Drag file (doc, pdf, xls, jpg, gif, png, heic) here or ",
      UPLOAD_TEXT_2: " to upload",
      UPLOAD_TEXT_3: "Drag file ({docTypes}) here or ",
      UPLOAD_TEXT_4: "Drag & Drop files here",
      UPLOAD_TEXT_BROWSE: "browse"
    },
    DOCUSIGN: {
      CONSENT_GRANTED: "Docusign consent has been granted.",
      GRANT_CONSENT: "Grant Docusign Consent",
      SERVICE_NAME: "DocuSign",
      SECURITY_KEY: "Security Key"
    },
    EKATA: {
      ACCOUNT_OPENING: "Account Opening",
      ACCOUNT_OPENING_DESCRIPTION:
        "Account Opening API assesses the overall risk of an applicant for a new account. Using the inputs of name, phone, address, email, and IP, the API returns 18 highly predictive identity verification features from Ekata's Identity Graph and Identity Network.",
      ADDRESS_RISK: "Address Risk",
      ADDRESS_RISK_DESCRIPTION:
        "Address Risk API validates global addresses and provides fraud risk signals in fractions of a second.",
      ERROR_MESSAGE: "Failed to fetch Ekata report:",
      MATCH: "Match status",
      PHONE_INTELLIGENCE: "Phone Intelligence",
      PHONE_INTELLIGENCE_DESCRIPTION:
        "From just a phone number input, Phone Intelligence API validates the metadata attributes associated with the phone such as Is Valid, Country Code, Is Prepaid, Line Type, Carrier, and more.",
      SERVICE_NAME: "Ekata",
      SCORE_TOOLTIP:
        "Risk score: higher score indicating higher risk. Score: {score} / {maxScore}",
      TRANSACTION_RISK: "Transaction Risk",
      TRANSACTION_RISK_DESCRIPTION:
        "Built specifically for models, Transaction Risk API provides the most predictive identity verification features. In under 100 ms, it scores the overall risk of an identity using email, IP, phone, name, and address and delivers a concise response for easy integration.",
      VALIDITY: "Item validity"
    },
    EMPTY_BODY:
      "Deals appear on this page when your clients fill in the first step of Lendflow widget.",
    EMPTY_HEADING: "You don't have any deals yet",
    EMPTY_WARNING: "There are no deals found.",
    EQUIFAX: {
      BUSINESS_CREDIT: {
        ACCOUNT_DESIGNATOR: "Accout Designator",
        ACCOUNT_TYPE_CODE: "Account Type Code",
        ADDRESSES: "Addresses",
        ADDRESS_DESCREPANCY_INDICATOR: "Address Discrepancy Indicator",
        ADDRESS_LINE_1: "@:GEOGRAPHY.ADDRESS_LINE_1",
        AUTOMATED_UPDATED_INDICATOR: "Automated Update Indicator",
        BALANCE: "Balance",
        BUREAU_NAME: "Bureau Name",
        CODE: "Code",
        CODE_DESCRIPTION_REQUIRED: "Code Description Required",
        CONSUMER_REFERRAL_CODE: "Consumer Referral Code",
        CONSUMER_REFERRAL_LOCATION: "Consumer Referral Location",
        CREDIT_RISK_SCORE: "Credit Risk Score",
        CUSTOMER_CODE: "Customer Code",
        CUSTOMER_NAME: "Customer Name",
        CUSTOMER_NUMBER: "Customer Number",
        CUSTOMER_REFERENCE_NUMBER: "Customer Reference Number",
        DATE: "Date",
        DATE_FIRST_REPORTED: "Date First Reported",
        DATE_LAST_REPORTED: "Date Last Reported",
        DATE_MAJOR_DELINQUENCY_FIRST_REPORTED:
          "Date Major Delinquency First Reported",
        DATE_OPENED: "Date Opened",
        DATE_REPORTED: "Date Reported",
        DESCRIPTION: "Description",
        ECOA_INQUIRY_TYPE: "ECOA Inquiry Type",
        FILE_SELECTION_LEVEL: "File Selection Level",
        FILE_SINCE_DATE: "File Since Date",
        FIRST_NAME: "First Name",
        FIXED_INQUIRY_FORMAT: "Fixed Inquiry Format",
        FRAUD_ID_SCAN_ALERT_CODES: "Fraud ID Scan Alert Codes",
        HIT_CODE: "Hit Code",
        INQUIRIES: "Inquiries",
        ITEMS_PER_PAGE: "Items Per Page",
        LAST_ACTIVITY_DATE: "Last Activity Date",
        LAST_NAME: "Last Name",
        MEMBER_NUMBER: "Member Number",
        MONTHS_FOR_INQUIRY: "Months For Inquiry",
        MONTHS_REVIEWED: "Months Reviewed",
        MULTIPLE_REPORT_INDICATOR: "Multiple Report Indicator",
        NO_RECORDS_FOUND_MESSAGE: "No records found for the business owner.",
        NARRATIVE_CODES: "Narrative Codes",
        OPTIONAL_FEATURE_CODE: "Optional Feature Code",
        OUTPUT_FORMAT: "Output Format",
        PAST_DUE_AMOUNT: "Past Due Amount",
        PAYMENT_HISTORY_1_TO_24: "Payment History 1 to 24",
        PDF_COMBO_INDICATOR: "PDF Combo Indicator",
        PLAIN_LANGUAGE: "Plain Language",
        PORTFOLIO_TYPE_CODE: "Portfolio Type Code",
        PRODUCT_CODES: "Product Codes",
        PROTOCOL_INDICATOR: "Protocol Indicator",
        PURCHASED_FROM_OR_SOLD_CREDITOR_INDICATOR:
          "Purchased from or Sold Creditor Indicator",
        PURCHASED_FROM_OR_SOLD_CREDITOR_NAME:
          "Purchased from or Sold Creditor Name",
        RATE: "Rate",
        RAW_NARRATIVE_CODES: "Raw Narrative Codes",
        RAW_REPORT_REQUIRED: "Raw Report Required",
        REJECT_CODE_DESCRIPTIONS: {
          AO: "Not available, file cannot be scored",
          A1: "Model Delivery is Not Available: File Under Review",
          BO: "Insufficient Information to Score",
          B1: "Subject Deceased",
          C1: "No Qualifying Accounts Present",
          C2: "Bankruptcy Occurrence Present",
          D0: "Bankruptcy On File",
          F1: "Model Delivery is Not Available: No Qualifying Accounts Present",
          F2: "No trades on file with date open older than 5 months",
          F3: "No recently reported account information",
          F4: "No trades on file with a valid current status and without data suppression or disputed info",
          L0: "Subject Deceased",
          N1: "Inquiry Only File",
          P1: "Subject Deceased",
          P2: "No qualifying trades",
          P3: "Insufficient or unknown tradeline history",
          P4: "No recently reported tradelines",
          P5: "No recent trades w/o data suppression or disputed info",
          P6: "Unable to score",
          R0: "Insufficient Information to Score",
          X1: "Model Delivery Temporarily Unavailable",
          X3: "Too Many Inquiries on File",
          X5: "No Input Fields Provided",
          X7: "Model Delivery Temporarily Unavailable, File Cannot Be Scored"
        },
        REASONS: "Reasons",
        REPORT_DATE: "Report Date",
        RISK_MODEL_CODE_ONLY: "Risk Model Code Only",
        SCORE: "Score",
        SECURITY_CODE: "Security Code",
        SOURCE_OF_ADDRESS: "Source of Address",
        SSN: "SSN",
        SUBJECT_NAME: "Subject Name",
        TERMS_FREQUENCY_CODE: "Terms Frequency Code",
        TIN: "TIN",
        TOTAL: "Total",
        TRADES: "Trades",
        TYPE: "Type"
      }
    },
    EQUIPMENT_DETAILS: {
      ADD_NEW: "Add New @:DEALS.EQUIPMENT_DETAILS.EQUIPMENT",
      ADDRESS: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT @:GEOGRAPHY.ADDRESS",
      COST: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Cost",
      DELETE: "Delete @:DEALS.EQUIPMENT_DETAILS.EQUIPMENT",
      DELETE_BODY: "Are you sure you want to delete this equipment?",
      DESCRIPTION: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Description",
      EDIT: "Edit @:DEALS.EQUIPMENT_DETAILS.TITLE",
      EQUIPMENT: "Equipment",
      LINK: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Link",
      MAKE: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Make",
      MILES_HOURS: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Miles/Hours",
      MODEL: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Model",
      NEW_OR_USED: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT New or Used",
      REQUESTED_TERM: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Requested Term",
      SELLER: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Seller",
      SERIAL_NUMBER: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Serial Number",
      TITLE: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Details",
      TOTAL_FINANCING_REQUEST:
        "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Total Financing Request",
      VENDOR: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Vendor",
      YEAR: "@:DEALS.EQUIPMENT_DETAILS.EQUIPMENT Year"
    },
    EQUIPMENT_RENTAL: {
      ADDITIONAL_DETAILS: {
        BONDING_AGENT: "Bonding Agent",
        CITIZENSHIP: "Citizenship",
        DATE_OF_BIRTH: "Date of Birth",
        EDIT_TITLE: "Edit Additional Contact & Business Details",
        ESTIMATED_SPEND_AMOUNT: "Estimated Rental Spend Amount",
        TAX_EXEMPT: "Tax Exempt:",
        TAX_ID_NUMBER: "Tax ID Number:",
        TITLE: "Additional Contact & Business Details",
        YEARS_IN_BUSINESS: "Years in Business:"
      },
      ADD_NEW_INSURANCE: "Add New Insurance",
      ALL: "All",
      APPROVAL_AMOUNT: "Approval Amount",
      APPROVAL_DATE: "Approval Date",
      APPROVAL_EXPIRATION_DATE: "Approval Expiration Date",
      APPROVED_AMOUNT: "Approved Amount",
      AUTHORIZED_SIGNATURE_GUARANTY: {
        AUTHORIZED_SIGNATURE: "Authorized Signature",
        EDIT_AUTHORIZED_SIGNATURE: "Edit Authorized Signature",
        EDIT_GUARANTOR_INFORMATION: "Edit Guarantor Information",
        GUARANTOR: "Guarantor",
        GUARANTOR_INFORMATION: "Guarantor Information",
        GUARANTY: "Guaranty",
        PHYSICAL_ADDRESS: "Physical Address:",
        SSN: "Social Security Number:"
      },
      BILLING_INFO: {
        CONTACT: "Accounts Payable Contact",
        EDIT_TITLE: "Edit Billing Information",
        JOB_NAME_NUMBER_REQ: "Job Name / Number Required:",
        MONTHLY_STATEMENTS_REQ: "Monthly Statements Required:",
        PO_REQUIRED: "PO Required:",
        PREFERRED_METHOD: "Preferred Method for Invoicing:",
        TITLE: "Billing Information"
      },
      CERTIFICATE_OF_INSURANCE: "Certificate of Insurance",
      CONTACT_FIRST_NAME: "Contact First Name",
      CONTACT_LAST_NAME: "Contact Last Name",
      CONTACT_PHONE: "Contact Phone",
      CREATE_OFFER: "Create New Offer",
      DELETE_OFFERS: "Delete Offers",
      DELETE_OFFERS_WARNING: "Selected offers will be permanently deleted",
      DISCOVERY: "Discovery",
      EDIT_INSURANCE_INFO: "Edit Insurance Information",
      EQUIPMENT_LINK: "Equipment Link",
      EQUIPMENT_NAME: "Equipment Name",
      EQUIPMENT_SHARE_USE: {
        ADDITIONAL_NOTES: "Additional notes by EquipmentShare should go here.",
        CUSTOMER_APPROVED_FOR: "Customer Approved For:",
        EDIT_TITLE: "Edit Approval Details",
        NET_30_TERMS: "Net 30 Terms:",
        OTHER_TERMS: "Other Terms:",
        TITLE: "Approval Details"
      },
      FILES_UPLOADED: "File(s) uploaded",
      INSURANCE_INFO: "Insurance Information",
      INSURANCE_INFO_COMPANY: "Insurance Company",
      INSURANCE_INFO_PROTECTION_PLAN: "Rental Protection Plan Purchased?",
      INSURANCE_INFO_PROTECTION_PLAN_DESC:
        "Do you have rental protection plan insurance?",
      INSURANCE_TYPE: "Insurance Type",
      INVALID_FILE_TYPE: "Invalid file type",
      NO_DATA_TEXT: "No Offers To Display",
      PHASES: {
        APPLICATION: "Application",
        UNDERWRITING: "Underwriting"
      },
      SELECT_OFFER_TITLE: "Select offer and send to applicant:",
      SEND_EMAIL_NOTIFICATION: "Send Email Notification",
      SIGNED_APP: "Signed Application",
      TERM: "Term Length (months)",
      TERM_LENGTH: "Term Length",
      TITLE: "Equipment Rental",
      TRADE_REFERENCES: {
        ADD: "Add Trade Reference",
        COMPANY: "Company",
        CONTACT: "Contact",
        EDIT_TITLE: "Edit Trade References",
        EMAIL_OR_FAX: "Email or Fax",
        REFERENCE: "Reference",
        TITLE: "Trade References"
      }
    },
    ERROR_CREATE_NEW_DEAL: "Please select Workflow Template",
    ERROR_LOADING_SMARTVIEW:
      "The selected smartview is not loading at the moment. Please try again.",
    EXPERIAN: {
      CONSUMER_SCORE_MODEL: "Consumer Score Model",
      DOCUMENT_NUMBER: "Document Number",
      HARD: "Hard",
      HARD_INQUIRY_WARNING_TEXT:
        "The selected Experian model is a hard inquiry type. Hard inquiries may have an effect on users personal credit score.",
      HARD_INQUIRY_WARNING_TITLE: "Experian Warning",
      INQUIRY_TYPE: "Inquiry Type",
      JUDGMENTS: "Judgments",
      LEGAL_ACTION: "Legal Action",
      LEGAL_TYPE: "Legal Type",
      LIABILITY_AMOUNT: "Liability Amount",
      OWNER: "Owner",
      SERVICE_CATEGORY: "BUSINESS CREDIT",
      SERVICE_NAME: "EXPERIAN",
      SUBCODES: "Subcodes",
      TITLE: "Experian"
    },
    EXPERIAN_COMMERCIAL_COLLECTIONS: {
      COLLECTIONS_DETAIL: "Collections Detail",
      COLLECTIONS_SUMMARY: "Collections Summary",
      SUBTITLE: "Commercial Collections"
    },
    EXPERIAN_CREDIT_STATUS: {
      SUBTITLE: "Credit Status"
    },
    EXPERIAN_FRAUD: {
      SUBTITLE: "Fraud"
    },
    EXPERIAN_GDN: {
      ACCOUNT_OPENED_DATE: "Account Opened Date",
      ACCOUNT_SECURED: "Account Secured",
      ALERT_DEROGATORY_SUMMARY_TYPE: "Alert - Derogatory Summary Type",
      AMOUNT: "Amount",
      APPLICATION_REFERENCE: "Application Reference",
      AREA: "Area",
      BALANCE_PERIOD_AMOUNT: "Balance Period Amount",
      BUSINESS_ACTIVITY_DESCRIPTION: "Business Activity Description",
      BUSINESS_PROFILE_RECORD_UPDATE_DATE:
        "Business Profile Record Update Date",
      CANADIAN_PROFILE: "Canadian Profile",
      CHARGE_OFF: "Charge Off",
      CITY: "City",
      CI_90_DAY: "CI 90 Day",
      CI_SCORE: "CI Score",
      CI_SCORE_PAYMENT_INDEX: "CI Score Payment Index",
      CN_CREDIT_REFERENCE_DETAIL: "CN Credit Reference Detail",
      CN_SCORE_DETAIL: "CN Score Detail",
      CODE: "Code",
      CODE_DESCRIPTION: "Code Description",
      COMPANY_EMPLOYEES: "Company Employees",
      COMPANY_INFO: "Company Info",
      COMPANY_NAME: "Company name",
      COMPANY_TURNOVERS: "Company Turnovers",
      COMPOSITE_INDEX: "Composite Index",
      COUNTRY_CODE: "Country Code",
      CREDIT: "Credit",
      CREDIT_LIMIT: "Credit Limit",
      CREDIT_RISK: "Credit Risk",
      CURRENT_BALANCE_AMOUNT: "Current Balance Amount",
      DATE_OF_FOUNDATION: "Date of Foundation",
      DATE_OF_LAST_ACTIVITY: "Date of last activity",
      DATE_REPORTED: "Date Reported",
      DEBTOR_COMMENT: "Debtor Comment",
      DELINQUENCIES: "Delinquencies",
      DEROGATORY_SUMMARY: "Derogatory Summary",
      DESCRIPTION: "Description",
      END_DATE: "End Date",
      EXPERIAN_TECHNICAL_ERROR: "Experian technical error",
      EXTENDED_REPORT: "Extended Report",
      FAX: "Fax",
      FAX_AREA_CODE: "Fax Area Code",
      FINANCIAL_REPORTS: "Financial Reports",
      FIRST_REGISTRATION_DATE: "First Registration Date",
      GBIN: "GBIN",
      HIGH_CREDIT_VAL: "High Credit",
      IDENTIFICATION_NUMBER_TYPE: "Identification Number Type",
      IDENTIFICATION_RECORDS: "Identification Records",
      LARGEST_AMOUNT: "Largest Amount",
      LAST_NEGATIVE_OCCCURRENCE: "Last Negative Occurrence",
      LAST_REPORT_UPDATE: "Last report Update",
      LEGAL_NAME: "Legal Name",
      LEGITIMATE_INTEREST_CODE: "Legitimate Interest Code",
      LIQUIDATOR_CODE: "Liquidator Code",
      LIQUIDATOR_LEGAL_NAME: "Liquidator Legal Name",
      MOST_SEVERE_STATUS: "Most Severe Status",
      MUNICIPALITY: "Municipality",
      NANCE_CODES: "Nance Codes",
      NANCE_VERSION: "Nance Version",
      NEGATIVE_FACTS: "Negative Facts",
      NEGATIVE_FACT_CODE: "Negative Fact Code",
      NEGATIVE_OCCURRENCE_PERCENTAGE: "Negative Occurrence Percentage",
      NON_FINANCIAL: "Non financial",
      NOT_UP_TO_DATE: "Not up to date",
      NUMBER: "Number",
      NUMBER_OF_ACCOUNTS: "Number of accounts",
      NUMBER_OF_NEGATIVE_OCCURRENCES: "Number of Negative Occurrences",
      OCCURENCE_DATE: "Occurence Date",
      ORGANIZATION: "Organization",
      PAYMENT_BEHAVIOUR: "Payment Behaviour",
      PAYMENT_HABITS: "Payment Habits",
      PAYMENT_INDEX: "Payment Index",
      PAYMENT_TERM: "Payment Term",
      PERIOD_NUMBER: "Period number",
      PHONE_AREA_CODE: "Phone Area Code",
      PHONE_NUMBER: "Phone Number",
      POSTAL_CODE: "Postal Code",
      POST_CODE: "Post Code",
      PROVINCE: "Province",
      QUARTERS: "Quarters",
      RATING: "Rating",
      RATINGS_CLASSIFICATIONS: "Ratings & Classifications",
      RATING_CLASS: "Rating Class",
      RBBID: "RBBID",
      REASON_CODES: "Reason Codes",
      RECORD: "Record",
      RECORDS: "Records",
      RELATED_ADDRESS: "Related Address",
      REPORT_HIGHLIGHT_AND_ALERT: "Report highlight and alert",
      RISK_CHECK: "Risk Check",
      RISK_CLASS: "Risk Class",
      SCORE: "Score",
      SCORE_SUMMARY: "Score Summary",
      SCORE_TRENDS: "Score Trends",
      SIC: "SIC",
      SINCE: "Since",
      SINGLE_13_MONTH: "Single 13 Month",
      SINGLE_90_DAYS: "Single 90 Day",
      SINGLE_ALL_LINES: "Single All Lines",
      SINGLE_HIGHEST_CREDIT: "Single Highest Credit",
      SMALL_REPORT: "Small Report",
      STREET: "Street",
      SUBCODE: "Subcode",
      TITLE: "Experian GDN",
      TOTAL_AMOUNT: "Total Amount",
      TOTAL_BALANCE_AMOUNT: "Total Balance Amount",
      TOTAL_CURRENT_BALANCE: "Total Current Balance",
      TOTAL_CURRENT_CREDIT_EXPOSURE: "Total Current Credit Exposure",
      TOTAL_OUTSTANDING: "Total Outstanding",
      TOTAL_PAST_DUE: "Total Past Due",
      TOTAL_PAST_DUE_AMOUNT: "Total Past Due Amount",
      TRADE_LINES: "Trade Lines",
      TREND_YEAR: "Trend Year",
      UP_TO_DATE: "Up to date",
      VALUE: "Value",
      YEARS_ON_FILE: "Years On File",
      YEAR_DIFFERENCE_PI: "Year Difference PI"
    },
    EXPERIAN_KYB: {
      BUSINESS_CONTACTS_SUBTITLE: "Business Contacts",
      CORPORATE_REGISTRATIONS_SUBTITLE: "Corporate Registrations",
      LEGAL_COLLECTIONS_SUBTITLE: "Legal Collections"
    },
    EXPERIAN_LIENS: {
      DOCUMENT_NUMBER: "Document Number",
      LEGAL_ACTION: "Legal Action",
      LEGAL_TYPE: "Legal Type",
      LIABILITY_AMOUNT: "Liability Amount",
      LIENS: "Liens",
      OWNER: "Owner"
    },
    EXPERIAN_MATCH: {
      ADDRESS: "Address",
      BANK_DATA_INDICATOR: "Bank Data Indicator",
      BIN: "BIN",
      BIN_FULL: "Business Identification Number",
      BN: "BN",
      BUSINESS_GEOCODE: "Business Geocode",
      BUSINESS_NAME: "Business Name",
      CONTACT_NAME: "Contact Name",
      CONTACT_TITLE: "Contact Title",
      COUNTRY_CODE: "Country Code",
      CREDIT_INDICATOR: "Credit Indicator",
      DATA: "Data",
      EIN: "EIN",
      EXECUTIVE_SUMMARY_INDICATOR: "Executive Summary Indicator",
      FINANCIAL_STATEMENT_INDICATOR: "Financial Statement Indicator",
      GBIN: "GBIN",
      GEO: "Geo",
      GOVERNMENT_DATA_INDICATOR: "Government Data Indicator",
      INQUIRY_INDICATOR: "Inquiry Indicator",
      KEY_FACTS_INDICATOR: "Key Facts Indicator",
      MATCH: "Business Match",
      MATCHING_NAME_AND_ADDRESS: "Matching Name And Address",
      NUMBER_OF_TRADELINES: "Number Of Tradelines",
      PHONE: "Phone",
      PULL_DATA: "Update Record With Experian Data?",
      QUICK_SEARCH: "Quick Search",
      RBBID: "RBBID",
      RECEIVED_DATA: "Received Data",
      RELIABILITY_SCORE: "Reliability Score: {score}",
      RELIABILITY_SCORE_LABEL: "Reliability Score",
      RESULT: "Result",
      SEARCH_STRING: "Search String",
      STATE_CODE: "State Code",
      SUBCODE: "Subcode",
      UCC_INDICATOR: "UCC Indicator"
    },
    EXPERIAN_UCC: {
      ASSIGNEE: "Assignee",
      DEFINITION_COLLATERAL_CODE: "Definition (Collateral Code)",
      DOCUMENT_NUMBER: "Document Number",
      LEGAL_ACTION: "Legal Action",
      LEGAL_TYPE: "Legal Type",
      SECURED_PARTY: "Secured Party"
    },
    FILTERS: {
      ACTIVE: "active",
      ALL: "all",
      DECLINED: "declined",
      FUNDED: "funded",
      INCOMPLETE: "incomplete"
    },
    FRAUD: {
      SOCURE_FRAUD_SCORES_DESCRIPTIONS: {
        addressRisk: "Returns a score indicating the riskiness of an address.",
        emailRisk: "Returns a score indicating riskiness of an email address.",
        fraud:
          "Returns a score indicating the likelihood an identity is fraudulent.",
        nameAddressCorrelation:
          "Returns a score indicating the correlation of a name and an address.",
        nameEmailCorrelation:
          "Returns a score indicating the correlation of a name and an email address.",
        namePhoneCorrelation:
          "Returns a score indicating the correlation of a name and a phone number.",
        phoneRisk:
          "Returns a score indicating the riskiness of a phone number.",
        synthetic:
          "Returns a score indicating the likelihood an identity is synthetic."
      },
      SOCURE_FRAUD_SCORES_FIELDS: {
        addressRisk: "Address Risk",
        alertList: "Alert List",
        emailRisk: "Email Risk",
        fraud: "Fraud - {subFieldName}",
        nameAddressCorrelation: "Name-Address Correlation",
        nameEmailCorrelation: "Name-Email Correlation",
        namePhoneCorrelation: "Name-Phone Correlation",
        phoneRisk: "Phone Risk",
        synthetic: "Synthetic - {subFieldName}"
      },
      TITLE: "Personal Fraud"
    },
    HERON: {
      ALL_CATEGORIES: "All Categories",
      ALL_TIME: "All Time",
      CATEGORIES: "Categories",
      CATEGORY: "Category",
      CHANGE_IN_NET_DEBT: "Change in Net Debt",
      COMPANY_INVESTMENTS: "Company Investments",
      DEBT: "Debt",
      DEBT_INVESTMENT: "Debt Investment",
      DEBT_REPAYMENT: "Debt Repayment",
      END_USER: "End User",
      END_USERS: "End Users",
      END_USER_STATS: "End User Stats",
      END_USER_STATS_TABLE: {
        CATEGORY: "Category",
        CATEGORY_HERON_IDS: "Category Heron IDs",
        END_USER_ID: "End User ID",
        GROUP_BY: "Group By",
        MERCHANT_HERON_IDS: "Merchant Heron IDs"
      },
      END_USER_TABLE: {
        COMPANY_NAME: "Company Name",
        CREATED: "Created",
        END_USER_ID: "End User ID",
        HERON_ID: "Heron ID",
        STATUS: "Status"
      },
      EQUITY: "Equity",
      EQUITY_INVESTMENT: "Equity investment",
      HERON: "Heron",
      INTRA_COMPANY: "Intra-Company",
      INTRA_COMPANY_TRANSFERS: "Intra-Company Transfers",
      MERCHANT: "Merchant",
      MERCHANTS: "Merchants",
      MERCHANT_TABLE: {
        HERON_ID: "Heron ID",
        ICON: "Icon",
        LOGO: "Logo",
        NAME: "Name",
        NO_MERCHANT_DATA: "No merchant data.",
        URL: "Url"
      },
      NO_DATA: "No Heron data for business",
      PNL: "P&L",
      RECONCILED_INTRA_COMPANY_TRANSFERS: "Reconciled Intra-Company Transfers",
      RECORDS: "{count} Records",
      RESULTS: "Results",
      RESULTS_TABLE: {
        ANNOTATOR: "Annotator",
        CONFIDENCE: "Confidence",
        HERON_ID: "Heron ID",
        NAME_CATEGORY: "Name Category"
      },
      SPECIAL_INFLOWS: "Special Inflows",
      SPECIAL_ITEMS: "Special Items",
      SPECIAL_OUTFLOWS: "Special Outflows",
      TOTAL_SPECIAL_ITEMS: "Total special items",
      TRANSACTIONS: "Transactions",
      TRANSACTIONS_TABLE: {
        AMOUNT: "Amount",
        DESCRIPTION: "Description",
        END_USER_ID: "End User ID",
        MCC_CODE: "MCC Code",
        MERCHANT_NAME: "Merchant Name",
        PAYMENT_PROCESSOR: "Payment Processor",
        REFERENCE_ID: "Reference ID",
        TIMESTAMP: "Timestamp",
        TRANSACTION_CODE: "Transaction Code"
      },
      UNCATEGORIZED: "Uncategorized",
      UNRECONCILED_INTRA_COMPANY_TRANSFERS:
        "Unreconciled Intra-Company Transfers"
    },
    JSON_PREVIEW: {
      EDIT_BUTTON: "Edit JSON",
      TITLE: "JSON Preview"
    },
    JUDGMENTS: {
      BUSINESS_TITLE: "Business Judgments"
    },
    KYC: {
      KYC_PROGRESS: "In Progress",
      RERUN: "Refresh KYC",
      SENTILINK: {
        APP_ID: "Application ID: {id}",
        CREATED: "Created: {date}",
        NO_KYC: "No KYC Data."
      },
      SOCURE: {
        AKA: "Aka",
        DATE_OF_BIRTH: "Date Of Birth",
        DESIGNATION_DATE: "Designation Date",
        ENTITY_ID: "Entity Id",
        GLOBAL_WATCH_LIST: "Global Watch List",
        GLOBAL_WATCH_LIST_DESCRIPTIONS: {
          I196: "Global Watchlist sources selected are not correlated with the input identifiers",
          R180: "Watchlist search returned at least one hit on OFAC SDN list",
          R181: "Watchlist search returned at least one hit on OFAC Non-SDN lists",
          R182: "Watchlist search returned at least one hit on a sanction list excluding any OFAC hits",
          R183: "Watchlist search returned at least one hit on an enforcement list excluding any OFAC hits",
          R184: "Watchlist search returned at least one hit on a politically exposed person",
          R185: "Watchlist search returned at least one entity that had adverse media articles",
          R186: "Global Watchlist sources selected are correlated with the input identifiers",
          R187: "Address resolves to a High-Intensity Drug Trafficking Area",
          R188: "Address resolves to a High-Intensity financial Crime Area"
        },
        LISTING_ID: "Listing Id",
        LIST_NAME: "List Name",
        MATCH_FIELDS: "Match Fields",
        NAME: "Name",
        OFFENSE: "Offense",
        ORIGINAL_PLACE_OF_BIRTH_TEXT: "Original Place Of Birth Text",
        PLACE_OF_BIRTH: "Place Of Birth",
        PROGRAM: "Program",
        REASON_CODES: "Reason Codes",
        REASON_CODE_DESCRIPTIONS: "Reason Code Descriptions",
        REASON_CODE_DESCRIPTIONS_LIST: { ...SOCURE_CODE_DESCRIPTIONS_LIST },
        SANCTION_TYPE: "Sanction Type",
        SOURCE_URLS: "Source Urls",
        TITLE: "Socure"
      },
      SOCURE_KYC_FIELDS: {
        city: "City",
        dob: "DOB",
        firstName: "First Name",
        mobileNumber: "Mobile Number",
        ssn: "SSN",
        state: "State",
        streetAddress: "Physical Address",
        surName: "Last Name",
        zip: "Zip Code"
      },
      TITLE: "KYC"
    },
    LEXIS_NEXIS: {
      BANKRUPTCIES: {
        ACTIVITY_RECEIPT: "Activity Receipt",
        ADDRESS: "Address",
        APPENDED_SSN: "Appended SSN",
        ASSETS_FOR_UNSECURED: "Assets for Unsecured",
        ATTORNEYS: "Attorneys",
        BANKRUPTCY_REPORT_RECORD: "Bankruptcy Report Record",
        BANKRUPTCY_SEARCH_RECORD: "Bankruptcy Search Record",
        BAR_DATE: "Bar Date",
        CASE_ID: "Case ID",
        CASE_NUMBER: "Case Number",
        CASE_STATUS: "Case Status",
        CASE_TYPE: "Case Type",
        CHAPTER: "Chapter",
        CITY: "City",
        CLOSED_DATE: "Closed Date",
        COMMENT: "Comment",
        COMMENTS: "Comments",
        COMPANY_NAME: "Company Name",
        CORP_FLAG: "Corp Flag",
        COUNTY: "County",
        COURT_CODE: "Court Code",
        COURT_LOCATION: "Court Location",
        COURT_NAME: "Court Name",
        DATE: "Date",
        DEBTOR: "Debtor",
        DEBTORS: "Debtors",
        DEBTORS_SUPRESSED: "Debtors Supressed",
        DEFENDANT_ID: "Defendant ID",
        DEFENDANT_TYPE: "Defendant Type",
        DISCHARGE_DATE: "Discharge Date",
        DISPOSITION: "Disposition",
        DISPOSITION_CODE: "Disposition Code",
        DISPOSITION_CODE_DESC: "Disposition Code Desc",
        DISPOSITION_REASON: "Disposition Reason",
        DISPOSITION_TYPE: "Disposition Type",
        DISPOSITION_TYPE_DESC: "Disposition Type Desc",
        EMAIL: "Email",
        EXTERNAL_KEY: "External Key",
        FILED_IN_ERROR: "Filed In Error",
        FILER_TYPE: "Filer Type",
        FILING_DATE: "Filing Date",
        FILING_JURISDICTION: "Filing Jurisdiction",
        FILING_STATUS: "Filing Status",
        FILING_TYPE: "Filing Type",
        JUDGE_IDENTIFICATION: "Judge Identification",
        JUDGE_NAME: "Judge Name",
        MEETING: "Meeting",
        METHOD_DISMISS: "Method Dismiss",
        NAME: "Name",
        ORIGINAL_CHAPTER: "Original Chapter",
        ORIGINAL_COUNTY: "Original County",
        ORIGINAL_FILING_DATE: "Original Filing Date",
        PERSON_NAME: "Person Name",
        PHONES: "Phones",
        RECORD_ID: "Record ID",
        SCREEN: "Screen",
        SCREEN_DESC: "Screen Desc",
        SELF_REPRESENTED: "Self Represented",
        SPLIT_CASE: "Split Case",
        SSN: "SSN",
        STATE: "State",
        STATUS: "Status",
        STATUS_DATE: "Status Date",
        STATUS_HISTORY: "Status History",
        STREET: "Street",
        STREET_ADDRESS_1: "Orig. Street Address 1",
        STREET_ADDRESS_2: "Orig. Street Address 2",
        STREET_NUMBER: "Street Number",
        STREET_SUFFIX: "Street Suffix",
        SUBTITLE: "Bankruptcies",
        TAX_ID: "Tax ID",
        TIME: "Time",
        TIMEZONE: "Timezone",
        TMS_ID: "TMS ID",
        TRANSFER_IN: "Transfer In",
        TRUSTEES: "Trustees",
        TRUSTEE_ID: "Trustee ID",
        TYPE: "Type",
        UNIQUE_ID: "Unique ID",
        ZIP_4: "Zip 4",
        ZIP_5: "Zip 5"
      },
      BUSINESS_CREDIT: {
        ACCOUNTS_WITH_GUARANTOR: "Accounts with Guarantor",
        ADDRESS_LEGAL_ENTITY_COUNT: "Address Legal Entity Count",
        AIRCRAFT_COUNT: "Aircraft Count",
        ASSET_DATA: "Asset Data",
        ASSOCIATE_BANKRUPT_12M_COUNT: "Associate Bankrupt 12M Count",
        ASSOCIATE_BANKRUPT_COUNT: "Associate Bankrupt Count",
        ASSOCIATE_BUSINESS_COUNT: "Associate Business Count",
        ASSOCIATE_COUNT_WITH_BANKRUPTCY: "Associate Count With Bankruptcy",
        ASSOCIATE_COUNT_WITH_FELONY: "Associate Count With Felony",
        ASSOCIATE_COUNT: "Associate Count",
        ASSOCIATE_FELONY_COUNT: "Associate Felony Count",
        ASSOCIATE_JUDGMENT_COUNT: "Associate Judgment Count",
        ASSOCIATE_LIEN_COUNT: "Associate Lien Count",
        ASSOCIATE_WATCHLIST_COUNT: "Associate Watchlist Count",
        ASSOCIATIONS: "Associations",
        BANKRUPTCY_CHAPTER: "Bankruptcy Chapter",
        BANKRUPTCY_COUNT: "Bankruptcy Count",
        BANKRUPTCY_TIME_NEWEST: "Bankruptcy Time Newest",
        BLENDED_SCORE: "Blended Score",
        BUSINESS_ACTIVITY: "Business Activity",
        BUSINESS_ADDRESS_COUNT: "Business Address Count",
        BUSINESS_ALTERNATE_NAME: "Business Alternate Name",
        BUSINESS_ATTRIBUTES: "Business Attributes",
        BUSINESS_IDENTIFICATION: "Business Identification",
        BUSINESS_INFO: "Business Info",
        BUSINESS_NAME_REP_FIRST_NAME: "Business Name Representative First Name",
        BUSINESS_NAME_REP_FULL_NAME: "Business Name Representative Full Name",
        BUSINESS_RECORD_TIME_NEWEST: "Business Record Time Newest",
        BUSINESS_RECORD_TIME_OLDEST: "Business Record Time Oldest",
        BUSINESS_RECORD_UPDATED_12M: "Business Record Updated 12M",
        BUSINESS_REP: "Business Representative",
        BUSINESS_REP_ADDRESS:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP Address",
        BUSINESS_REP_AGE:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP Age",
        BUSINESS_REP_CITY:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP City",
        BUSINESS_REP_DL_STATE:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP DL State",
        BUSINESS_REP_DL:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP DL",
        BUSINESS_REP_DOB:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP DOB",
        BUSINESS_REP_FIRST_NAME:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP First Name",
        BUSINESS_REP_LAST_NAME:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP Last Name",
        BUSINESS_REP_MIDDLE_NAME:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP Middle Name",
        BUSINESS_REP_PHONE:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP Phone",
        BUSINESS_REP_SSN:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP SSN",
        BUSINESS_REP_STATE:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP State",
        BUSINESS_REP_ZIP:
          "{count} @:DEALS.LEXIS_NEXIS.BUSINESS_CREDIT.BUSINESS_REP Zip",
        CHARGE_OFF_RECORDED: "Charge Off Recorded",
        CREDIT_SCORE: "Credit Score",
        DATE_SCORED: "Date Scored",
        EMPLOYEE_COUNT: "Employee Count",
        EMPLOYEE_RANGE_COUNT: "Employee Range Count",
        EXECUTIVE_LINKS: "Executive Links",
        FIRM_OWNERSHIP_TYPE: "Firm Ownership Type",
        INPUT_DATA_CHECK: "Input Data Check",
        INQUIRIES: "Inquiries",
        INQUIRY_CONSUMER_ADDRESS_SSN: "Inquiry Consumer Address SSN",
        INQUIRY_CONSUMER_ADDRESS: "Inquiry Consumer Address",
        INQUIRY_CONSUMER_PHONE: "Inquiry Consumer Phone",
        INQUIRY_COUNT: "Inquiry Count",
        INQUIRY_CREDIT_COUNT: "Inquiry Credit Count",
        INQUIRY_HIGH_RISK_COUNT: "Inquiry High Risk Count",
        INQUIRY_OTHER_COUNT: "Inquiry Other Count",
        INQUIRY_OVERLAP_COUNT: "Inquiry Overlap Count",
        IRS_RETIREMENT_FIRM: "IRS Retirement Firm",
        IRS_RETIRMENT_PLAN_FIRM: "IRS Retirement Plan Firm",
        JUDGMENT_COUNT: "Judgment Count",
        JUDGMENT_NEWEST_TYPE: "Judgment Newest Type",
        JUDGMENT_TIME_OLDEST: "Judgment Time Oldest",
        JUDGMENT_TOTAL_AMOUNT: "Judgment Total Amount",
        LEGAL_DATA: "Legal Data",
        LEGAL_ENTITY_COUNT: "Legal Entity Count",
        LIEN_COUNT: "Lien Count",
        LIEN_FED_TAX_COUNT: "Lien Fed Tax Count",
        LIEN_FED_TAX_TOTAL_AMOUNT: "Lien Fed Tax Total Amount",
        LIEN_FORECLOSURE_COUNT: "Lien Foreclosure Count",
        LIEN_FORECLOSURE_TOTAL_AMOUNT: "Lien Foreclosure Total Amount",
        LIEN_NEWEST_TYPE: "Lien Newest Type",
        LIEN_TIME_NEWEST: "Lien Time Newest",
        LIEN_TIME_OLDEST: "Lien Time Oldest",
        LIEN_TOTAL_AMOUNT: "Lien Total Amount",
        LOCATION_COUNT: "Location Count",
        NON_PROFIT_FIRM: "Non Profit Firm",
        OBSERVED_FIRM_AGE: "Observed Firm Age",
        OPEN_ACCOUNTS: "Open Accounts",
        PARENT_COMPANY_INDICATOR: "Parent Company Indicator",
        PROPERTY_ASSESSED_TOTAL_CURRENT: "Property Assessed Total Current",
        PROPERTY_ASSESSED_TOTAL_EVER: "Property Assessed Total Ever",
        PROPERTY_COUNT_CURRENT: "Property Count Current",
        PROPERTY_COUNT_EVER: "Property Count Ever",
        PROPERTY_LOT_SIZE_TOTAL_CURRENT: "Property Lot Size Total Current",
        PROPERTY_LOT_SIZE_TOTAL_EVER: "Property Lot Size Total Ever",
        PROPERTY_OVERLAP_COUNT: "Property Overlap Count",
        PROPERTY_SQUARE_FOOTAGE_TOTAL_CURRENT:
          "Property Square Footage Total Current",
        PROPERTY_SQUARE_FOOTAGE_TOTAL_EVER:
          "Property Square Footage Total Ever",
        PROPERTY_STATE_COUNT_CURRENT: "Property State Count Current",
        RANGE_COUNT: "Range Count",
        REASON_CODE: "Reason Code",
        RELATED_ENTITY_COUNT: "Related Entity Count",
        REP_ADDRESS_BUSINESS_ADDRESS: "Representative Address Business Address",
        REP_ADDRESS_ON_FILE: "Representative Address On File",
        REP_NAME_ON_FILE: "Representative Name On File",
        REP_PHONE_BUSINESS_PHONE: "Representative Phone Business Phone",
        REP_PHONE_ON_FILE: "Representative Phone On File",
        REP_SSN_BUSINESS_TIN: "Representative SSN Business TIN",
        REP_SSN_ON_FILE: "Representative SSN On File",
        REPORTED_EARNINGS: "Reported Earnings",
        REPORTED_SALES_RANGE: "Reported Sales Range",
        REPORTED_SALES: "Reported Sales",
        SCORE_REASONS: "Score Reasons",
        SCORE_RISK_LEVEL: "Score Risk Level",
        SCORE_TYPE: "Score Type",
        SECRETARY_OF_STATE: "Secretary of State",
        SINGLE_LOCATION: "Single Location",
        SMALL_BUSINESS_ATTRIBUTES: "Small Business Attributes",
        SMALL_BUSINESS_TITLE: "Small Business Credit Score Report",
        SOS_AGENT_CHANGE_TIME: "SOS Agent Change Time",
        SOS_FOREIGN_STATE_FLAG: "SOS Foreign State Flag",
        SOS_INCORPORATION_FILINGS_COUNT: "SOS Incorporation Filings Count",
        SOS_INCORPORATION_TIME_OLDEST: "SOS Incorporation Time Oldest",
        SOS_STANDING_BEST: "SOS Standing Best",
        SOS_STANDING_DEFUNCT: "SOS Standing Defunct",
        SOS_STATE_COUNT: "SOS State Count",
        TOP_BUSINESS_RECORD: "Top Business Record",
        UCC_ACTIVE_COUNT: "UCC Active Count",
        UCC_COUNT: "UCC Count",
        UCC_ROLES_ACTIVE: "UCC Roles Active",
        UCC_ROLES: "UCC Roles",
        UCC_TIME_NEWEST: "UCC Time Newest",
        UCC_TIME_OLDEST: "UCC Time Oldest",
        UTILITY_OVERLAP_COUNT: "Utility Overlap Count",
        VERIFICATION_OF_BUSINESS_INPUT_ADDRESS:
          "Verification of Business Input Address",
        VERIFICATION_OF_BUSINESS_INPUT_INDUSTRY:
          "Verification of Business Input Industry",
        VERIFICATION_OF_BUSINESS_INPUT_NAME:
          "Verification of Business Input Name",
        VERIFICATION_OF_BUSINESS_INPUT_PHONE:
          "Verification of Business Input Phone",
        VERIFICATION_OF_BUSINESS_INPUT_TIN:
          "Verification of Business Input TIN",
        WATERCRAFT_COUNT: "Watercraft Count"
      },
      CONTACT_CARD: {
        AKAS: "AKAs",
        HAS_BANKRUPTCY: "Has Bankruptcy",
        HAS_CORPORATE_AFFILIATION: "Has Corporate Affiliation",
        HAS_PROPERTY: "Has Property",
        HIGH_RISK_INDICATORS: "High Risk Indicators",
        IDENTITY: "Identity",
        INCLUDE_EMAILS: "Include Emails",
        INCLUDE_PHONES: "Include Phones",
        PUBLIC_NONPUBLIC: "Public/Nonpublic",
        RELATIONSHIP: "Relationship",
        REPORT_ADDRESS: "Report Address | Report Addresses",
        RISK_CODE: "Risk Code",
        SUBJECT_INFORMATION: "Subject Information",
        TITLE: "LexisNexis Contact Card"
      },
      EMAIL_SEARCH: {
        ADDITIONAL_STATUS_INFO: "Additional Status Info",
        BEST_INFO: "Best Info",
        DATE_VENDOR_FIRST_REPORTED: "Date Vendor First Reported",
        DATE_VENDOR_LAST_REPORTED: "Date Vendor Last Reported",
        EMAIL_STATUS: "Email Status",
        MARKETING_USE: {
          TITLE: "Is Marketing Use",
          TRUE: "Is For Marketing Use",
          FALSE: "Is Not For Marketing Use"
        },
        NUM_EMAIL_PER_LEXID: "Number of Emails per LexID",
        NUM_LEXID_PER_EMAIL: "Number of LexIDs per Email",
        SEARCH_TIER: {
          BASIC: "Basic",
          PREMIUM: "Premium",
          TITLE: "Search Tier"
        },
        SEARCH_TYPE: {
          EMAIL_ADDRESS_APPEND: "Email Address Append",
          EMAIL_IDENTITY_APPEND: "Email Identity Append",
          EMAIL_IDENTITY_CHECK: "Email Identity Check",
          TITLE: "Search Type"
        },
        TITLE: "LexisNexis Email Search"
      },
      JUDGMENTS: {
        AGENCY: "Agency",
        AGENCY_COUNTY: "Agency County",
        AGENCY_STATE: "Agency State",
        AMOUNT: "Amount",
        COMPANY: "Company",
        COMPANY_NAME: "Company Name",
        CREDITOR: "Creditor",
        DEBTOR: "Debtor",
        EVICTION: "Eviction",
        FILING: "Filing",
        FILING_DATE: "Filing Date",
        FILING_JURISDICTION: "Filing Jurisdiction",
        FILING_NUMBER: "Filing Number",
        FILING_TYPE: "Filing Type",
        MULTIPLE_DEFENDANT: "Multiple Defendant",
        NAME: "Name",
        ORIGINAL_FILING_DATE: "Original Filing Date",
        ORIGINAL_NAME: "Original Name",
        PERSON_FILTER_ID: "Person Filter ID",
        SUBTITLE: "Judgments"
      },
      KYB: {
        ACTIVE_EDA: "Phone Active in EDA",
        ADDRESS_FROM_DATE: "Address From Date",
        ADDRESS_TO_DATE: "Address To Date",
        AIR_CONDITIONING: "Air Conditioning",
        ALSO_FOUND: "Also Found",
        ASSESSED_TOTAL_VALUE: "Assessed Total Value",
        ASSESSOR: "Assessor",
        BANKRUPTCY: "Bankruptcy",
        BANKRUPTCY_TOTAL: "Bankruptcy Total",
        BUSINESS_ACTIVE: "Business Active",
        BUSINESS_ASSOCIATES: "Business Associates",
        BUSINESS_CREDIT_INDICATOR: "Business Credit Indicator",
        BUSINESS_IDENTITY: "Business Identity",
        BUSINESS_IDS: "Business IDs",
        BUSINESS_INACTIVE: "Business Inactive",
        COMPANY_NAME_INFO: "Company Name Info",
        COMPANY_VERIFICATION: "Company Verification",
        CONTACT_INFO: "Contact Info",
        CONTRACT_DATE: "Contract Date",
        DEROGATORY: "Derogatory",
        DEROG_SUM_CNT_BNKRPTCY: "Derog Summary Cnt Bankruptcy",
        ELECTRONIC_DIRECTORY_ASSISTANCE: "Electronic Directory Assistance",
        EMAIL_INFO: "Email Info",
        EXPERIAN_BUSINESS_REPORT: "Experian Business Report",
        GARAGE: "Garage",
        HEATING: "Heating",
        IS_ACTIVE: "Is Active",
        IS_DEFUNCT: "Is Defunct",
        IS_PARENT_COMPANY_INDICATOR: "Is Parent Company Indicator",
        IS_PHONE_DISCONNECTED: "Is Phone Disconnected",
        JUDGMENT_TOTAL: "Judgment Total",
        LIEN_TOTAL: "Lien Total",
        LOAN_DATE: "Loan Date",
        MARKET_IMPROVEMENT_VALUE: "Market Improvement Value",
        MARKET_LAND_VALUE: "Market Land Value",
        MORTGAGE: "Mortgage",
        MORTGAGES: "Mortgages",
        NUM_BATHS: "Number Of Baths",
        NUM_BEDS: "Number Of Bedrooms",
        OWNERS: "Owners",
        PARTY_TYPE: "Party Type",
        PERSON_ASSOCIATES: "Person Associates",
        PHONE_INFO: "Phone Info",
        PROPERTIES: "Properties",
        PROXY_NAME_SEQ: "Proxy Name Seq",
        RECORDING_DATE: "Recording Date",
        RECORDS: "Records",
        REPORT: "Report",
        SELLER: "Seller",
        SELLERS: "Sellers",
        SSN: "SSN",
        SUBTITLE: "KYB",
        TAX_AMOUNT: "Tax Amount",
        TAX_YEAR: "Tax Year",
        TIN_INFO: "TIN Info",
        TOTAL_DEROG_SUM_CNT_BNKRPTCY: "Total Derog Summary Cnt Bankruptcy",
        TOTAL_MARKET_VALUE: "Total Market Value",
        TO_DATE: "To Date",
        UCC_TOTAL: "UCC Total",
        URL_INFO: "URL Info",
        VERIFIED_INDICATORS: "Verified Indicators",
        WARRANTY_DEED: "Warranty Deed",
        YEARS_IN_BUSINESS: "Years in Business",
        YEAR_IN_BUSINESS: "Year in Business",
        MODELS: {
          INCLUDE_REGISTERED_AGENTS: "Include Registered Agents",
          INCLUDE_DUN_BRAD_STREET: "Include Dun Brad Street",
          INCLUDE_UCCFILINGS_SECUREDS: "Include UCC Filings Secureds",
          INCLUDE_COMPANY_VERIFICATION: "Include Company Verification",
          INCLUDE_ASSOCIATED_BUSINESSES: "Include Associated Businesses",
          INCLUDE_INDUSTRIES: "Include Industries",
          INCLUDE_PARENTS: "Include Parents",
          INCLUDE_PROPERTIES: "Include Properties",
          INCLUDE_AIRCRAFTS: "Include Aircrafts",
          INCLUDE_WATERCRAFTS: "Include Watercrafts",
          INCLUDE_NAME_VARIATIONS: "Include Name Variations",
          INCLUDE_MOTOR_VEHICLES: "Include Motor Vehicles",
          INCLUDE_SOURCE_COUNTS: "Include Source Counts",
          INCLUDE_INCORPORATION: "Include Incorporation",
          INCLUDE_INTERNET_DOMAINS: "Include Internet Domains",
          INCLUDE_SANCTIONS: "Include Sanctions",
          INCLUDE_EXPERIAN_BUSINESS_REPORTS:
            "Include Experian Business Reports",
          INCLUDE_PROFESSIONAL_LICENSES: "Include Professional Licenses",
          INCLUDE_BUSINESS_REGISTRATIONS: "Include Business Registrations",
          INCLUDE_BANKRUPTCIES: "Include Bankruptcies",
          INCLUDE_OPS_SITES: "Include Ops Sites",
          INCLUDE_FINANCES: "Include Finances",
          INCLUDE_UCCFILINGS: "Include UCC Filings",
          INCLUDE_CONTACTS: "Include Contacts",
          INCLUDE_BUSINESS_INSIGHT: "Include Business Insight",
          INCLUDE_LIENS_JUDGMENTS: "Include Liens Judgments",
          INCLUDE_IRS5500: "Include IRS 5500",
          INCLUDE_CONNECTED_BUSINESSES: "Include Connected Businesses"
        },
        NO_MODELS_SELECTED:
          "Please select one or more options to run the service"
      },
      KYC: {
        ADDRESS: "Address",
        AGE: "Age",
        CITY: "City",
        COUNTY: "County",
        DATE_FIRST_SEEN: "Date First Seen",
        DATE_LAST_SEEN: "Date Last Seen",
        DATE_OF_BIRTH: "Date of Birth",
        DECEASED: "Deceased",
        FDN_SSN_IND: "FDN SSN Ind",
        GENDER: "Gender",
        ISSUED_END_DATE: "Issued End Date",
        ISSUED_LOCATION: "Issued Location",
        ISSUED_START_DATE: "Issued Start Date",
        IS_CURRENT: "Is Current",
        NAME: "Name",
        PULL_DATA: "Update Record With LexisNexis Data?",
        RECORDS: "Records",
        RESULT: "Result",
        RUN: "Run",
        SECTION_NAME: "Section Name",
        SSN: "SSN",
        SSN_INFO: "SSN Info",
        STATE: "State",
        STREET: "Street",
        STREET_NUMBER: "Street Number",
        STREET_SUFFIX: "Street Suffix",
        SUBTITLE: "KYC",
        SYSTEM_RESULT: "System Result",
        TITLE: "LexisNexis",
        TYPE: "Type",
        UNIQUE_ID: "Unique ID",
        VALID: "Valid",
        VERIFIED: "Verified",
        ZIP_4: "Zip 4",
        ZIP_5: "Zip 5"
      },
      PEOPLE_AND_BUSINESS_FUNCTIONS: "People and Business Functions",
      RISK_VIEW: {
        ATTRIBUTE: "Attribute",
        ATTRIBUTE_NAME: "Attribute Name",
        ATTR_V6_TITLE: "Risk View Attr V6",
        REPORT: "@:DEALS.LEXIS_NEXIS.RISK_VIEW.SHORT_TITLE Report",
        SHORT_TITLE: "Risk View",
        TITLE: "Lexis Nexis @:DEALS.LEXIS_NEXIS.RISK_VIEW.SHORT_TITLE"
      },
      SERVICE_NAME: "LexisNexis",
      SMALL_BUSINESS_ANALYTICS_FUNCTION: "Small Business Analytics Function",
      UCC: {
        ADDRESS: "Address",
        ADDRESSES: "Addresses",
        ADDRESS_TYPE: "Address Type",
        AGENCY: "Agency",
        AGE_AT_DEATH: "Age At Death",
        AKAS: "AKAS",
        AMOUNT: "Amount",
        ASSESSED_IMPROVEMENT_VALUE: "Assessed Improvement Value",
        ASSESSED_TOTAL_VALUE: "Assessed Total Value",
        ASSESSED_VALUE_YEAR: "Assessed Value Year",
        ASSESSEE_OWNERSHIP_RIGHTS: "Assessee Ownership Rights",
        ASSOCIATES: "Associates",
        BEST_INFO: "Best Info",
        BPS_REPORT_ADDRESSES: "BPS Report Addresses",
        CARRIER: "Carrier",
        CARRIER_CITY: "Carrier City",
        CARRIER_STATE: "Carrier State",
        CASE_NUMBER: "Case Number",
        CERTIFICATE_NUMBER: "Certificate Number",
        COLLATERAL: "Collateral",
        COMPANY: "Company",
        COMPANY_TIMEZONE: "Company Timezone",
        CONFIDENCE: "Confidence",
        CORPORATE_AFFILIATIONS: "Corporate Affiliations",
        CORPORATION_NUMBER: "Corporation Number",
        COUNT: "Count",
        COUNTY_OF_ORIGIN: "County Of Origin",
        CRIMINAL_RECORDS: "Criminal Records",
        DATA_SOURCE: "Data Source",
        DATE_LAST_COHABIT: "Date Last Cohabit",
        DATE_OF_DEATH: "Date Of Death",
        DEATH_COUNTY: "Death County",
        DEATH_STATE: "Death State",
        DEATH_VERIFICATION_CODE: "Death Verification Code",
        DEBTOR: "Debtor",
        DESCRIPTION: "Description",
        DOT_ID: "DotID",
        EMAIL: "Email",
        EMAILS: "Emails",
        EMP_ID: "EmpID",
        ENTITIES: "Entities",
        EXPIRATION: "Expiration",
        EXPIRATION_DATE: "Expiration Date",
        FDN_ADDRIND: "FDN AddrInd",
        FDN_DIDIND: "FDN DidInd",
        FDN_PHONE_IND: "FDN PhoneInd",
        FILE_DATE: "File Date",
        FILING: "Filing",
        FILINGS: "Filings",
        FILING_DATE: "Filing Date",
        FILING_JURISDICTION: "Filing Jurisdiction",
        FILING_JURISDICTION_NAME: "Filing Jurisdiction Name",
        FILING_NUMBER: "Filing Number",
        FILING_OFFICE_ADDRESS: "Filing Office Address",
        FILING_STATUS: "Filing Status",
        FILING_TYPE: "Filing Type",
        FIPS_CODE: "Fips Code",
        FLAG: "Flag",
        FYLE_TYPE: "File Type",
        GARAGE_TYPE: "Garage Type",
        GEO_LOCATION: "Geo Location",
        HISTORICAL_NEIGHBORS: "Historical Neighbors",
        IDENTITY: "Identity",
        IMPOSTERS: "Imposters",
        INDIVIDUAL: "Individual",
        IRS_SERIAL_NUMBER: "IRS Serial Number",
        JUDGEMENTS_LIENS: "Liens / Judgments",
        JURISDICTION: "Jurisdiction",
        LAND_SQUARE_FOOTAGE: "Land Square Footage",
        LAND_USE: "Land Use",
        LAND_USE_CODE: "Land Use Code",
        LEGAL_INFO: "Legal Info",
        LEVEL: "Level",
        LISTED_NAME: "Listed Name",
        LISTING_NAME: "Listing Name",
        LOCATION_ID: "Location ID",
        NAME: "Name",
        NEIGHBORHOOD: "Neighborhood",
        NEIGHBORS: "Neighbors",
        NEIGHBOR_ADDRESS: "Neighbor Address",
        NO_DATA: "No report Data.",
        NO_OF_BATHS: "No Of Baths",
        NO_OF_BEDROOMS: "No Of Bedrooms",
        NO_OF_STORIES: "No Of Stories",
        NUMBER: "Number",
        OFFENDER_ID: "Offender ID",
        OFFENSE: "Offfense",
        OFFENSES: "Offenses",
        OLD_PHONES: "Old Phones",
        ORG_ID: "OrgID",
        ORIGINAL_ADDRESS: "Original Address",
        ORIGINAL_FILING_DATE: "Original Filing Date",
        ORIGINAL_FILING_TYPE: "Original Filing Type",
        ORIGINAL_NAME: "Original Name",
        ORIGIN_FILING_NUMBER: "Origin Filing Number",
        ORIGIN_NAME: "Origin Name",
        ORIG_FILE_NUMBER: "Orig. File Number",
        PAGES: "Pages",
        PARCEL_ID: "Parcel ID",
        PARSED_PARTIES: "Parsed Parties",
        PEOPLE_AT_WORKS: "People At Works",
        PHONE: "Phone",
        PHONES: "Phones",
        PHONE_10: "Phone 10",
        PHONE_NUMBER: "Phone Number",
        PHONE_PLUSES: "Phone Pluses",
        PHONE_TYPE: "Phone Type",
        POOL: "Pool",
        POW_ID: "POWID",
        PRIOR_RECORDING_DATE: "Prior Recording Date",
        PROPERTIES: "Properties",
        PROX_ID: "ProxID",
        RECORDING_DATE: "Recording Date",
        RECORD_TYPE: "Record Type",
        RECORD_TYPE_DESC: "Record Type Desc",
        RELATIVES: "Relatives",
        REPORT: "UCC Filing Report",
        RESIDENTS: "Residents",
        ROLE: "Role",
        RUN: "Run",
        RUN_REPORT: "Run Report",
        SALES_PRICE: "Sales Price",
        SEARCH: "UCC Filing Search",
        SEARCH_FILINGS: "Search Filings",
        SECURED: "Secured",
        SECURED_PARTY_INFO: "Secured Party Info",
        SELE_ID: "SeleID",
        SOURCES: "Sources",
        SOURCE_DOC_ID: "Source Doc ID",
        SOURCE_PROPERTY_RECORD: "Source Property Record",
        SOURCE_PROPERTY_RECORD_ID: "Source Property Record ID",
        STATE_CODE: "State Code",
        STATE_OF_ORIGIN: "State Of Origin",
        STATUS: "Status",
        STATUS_DESCRIPTION: "Status Description",
        SUBJECT: "Subject",
        SUBJECT_NAME: "Subject Name",
        SUBJECT_SSN_INDICATOR: "SubjectSSNIndicator",
        SUBTITLE: "UCC filings",
        TIMEZONE: "Timezone",
        TIME_ZONE: "Time Zone",
        TITLE: "LexisNexis",
        TMS_ID: "TMS ID",
        UCC_FILINGS: "UCC Filings",
        ULT_ID: "UltID",
        YEAR_BUILT: "Year Built"
      }
    },
    LIENS: {
      ADDRESS: "Address",
      AGENCY: "Agency",
      AGENCY_COUNTRY: "Agency Country",
      AGENCY_STATE: "Agency State",
      AMOUNT: "Amount",
      COLLATERAL: "Collateral",
      COMPANY: "Company",
      CREDITOR: "Creditor",
      DEBTOR: "Debtor",
      DESCRIPTION:
        "LexisNexis offers content-enabled workflow solutions in the legal, risk management, corporate, government, accounting, and academic markets.",
      EVICTION: "Eviction",
      EXPIRATION: "Expiration",
      FILE_DATE: "File Date",
      FILE_NUMBER: "File Number",
      FILING: "Filing|Filings",
      FILING_DATE: "Filing Date",
      FILING_JURISDICTION: "Filing Jurisdiction",
      FILING_NUMBER: "Filing Number",
      FILING_TYPE: "Filing Type",
      FYLE_TYPE: "File Type",
      JURISDICTION: "Jurisdiction",
      MULTIPLE_DEFENDANT: "Multiple Defendant",
      NAME: "Name",
      NO_DATA: "No data Available",
      RELEASE_DATE: "Release Date",
      REPORT: "Liens Report",
      ROLE: "Role",
      RUN_REPORT: "Run Report",
      SEARCH: "Liens Search",
      SECURED_PARTY_INFO: "Secured Party Info",
      STATUS: "Status",
      SUBTITLE: "Liens",
      TAX_ID: "Tax Id",
      TITLE: "Lexis Nexis"
    },
    LITIGATION: {
      ARRESTS: "Arrests",
      ARREST_WARRANTS: "Arrest Warrants",
      DOCUMENT_IDENTIFIER_INFO: "Document Identifier Info",
      FINANCIAL_CIVIL_JUDGEMENTS: "Financial Civil Judgments",
      LAWSUITS: "Lawsuits",
      LITIGATION_BUSINESS: "Business Litigation",
      LITIGATION_INDIVIDUAL: "Individual Litigation",
      LITIGIOUS_INDIVIDUAL: "Litigious Individual",
      NOT_FOUND: "Litigation information on {businessName} was not found",
      NO_RISK_FLAGS_RETURNED:
        "No Risk Inform flags were returned for your search subject",
      RECEIVED_DATA: "Received Data",
      SANCTIONS: "Sanctions",
      SECTION_NAME: "Section Name",
      SECTION_SCORE: "Section Score",
      TITLE: "Litigation"
    },
    MIDDESK: {
      ADDITIONAL_ADDRESS: "Additional Address",
      AGENCY_INFORMATION_URL: "Agency Information URL",
      AGENCY_LIST_URL: "Agency List URL",
      CASE_DESCRIPTION: "Case Description",
      CASE_GUID: "Case Guid",
      CASE_NO: "Case #",
      CASE_NUMBER: "Case Number",
      CHAPTER: "Chapter",
      CHAPTER_NO: "Chapter #",
      COURT_NAME: "Court Name",
      COURT_NUMBER: "Court Number",
      COURT_TYPE: "Court Type",
      CREATED_AT: "Created At",
      DEBTOR: "Debtor",
      DEBTORS_NAME: "Debtors Name",
      ENTITY_TYPE: "Entity Type",
      FILING_DATE: "Filing Date",
      FORMATION_DATE: "Formation Date",
      FORMATION_MESSAGE:
        "Every business has a formation record that dictates when and where a business was established.",
      FORMATION_STATE: "Formation State",
      FORMATION_TITLE: "Formation",
      INDUSTRY_CLASSIFICATION: {
        TITLE: "Industry Classification",
        HIGH_RISK: "High Risk"
      },
      LIENS: {
        ALL_LIENS: "All Liens",
        ALTERNATIVE_DESIGNATION: "Alternative Designation",
        COLLATERAL: "Collateral",
        COLLATERAL_TYPE: "Collateral Type",
        CONFIRMATION_NUMBER: "Confirmation Number",
        DEBTOR: "Debtor",
        FILE_NUMBER: "File Number",
        FILING_DATE: "Filing Date",
        LAPSE_DATE: "Lapse Date",
        LIABILITY: "Liability",
        LIEN_TERMINATION: "Lien Termination",
        LOAN_PRINCIPAL_AMOUNT: "Loan Principal Amount",
        NEGATIVE_PLEDGE: "Negative Pledge",
        PACKET_NUMBER: "Packet Number",
        SECURED_PARTY: "Secured Party",
        UPDATED_DATE: "Updated Date"
      },
      NO_TIN_RESULT: "No result returned from Middesk TIN verification service",
      OFFICE_NUMBER: "Office Number",
      OFFICERS_NAMES: "Officers Names",
      PHONE_ERROR_MESSAGE:
        "Middesk: cannot create business - The phone number field contains an invalid number.",
      PUBLISH_DATE: "Publish Date",
      PULL_DATA: "Update Record With Middesk Data?",
      REGISTRATION_DATE: "Registration Date",
      SOS_TABLE: {
        DOCUMENTS: "Documents",
        ENTITY_TYPE: "Entity Type",
        FILED_DATE: "Filed Date",
        OFFICERS: "Officers",
        REGISTERED_AGENT: "Registered Agent"
      },
      TITLE: "Middesk",
      TRUSTEE: "Trustee",
      UPDATED_ADDRESS: "Updated Address",
      UPDATED_AT: "Updated At",
      UPDATED_BUSINESS_NAME: "Updated Business Name",
      WATCHLIST_RESULTS: "Watchlist Results"
    },
    MODE: {
      LIVE: "Live",
      MOVE_TO_LIVE: "Move to Live",
      TEST: "Test",
      TEST_DEAL: "Test Deal"
    },
    MONEYTHUMB: {
      ACCOUNT: "Account",
      ACCOUNT_ID: "Account ID",
      ACCOUNT_IDX: "Account IDX",
      ACCOUNT_NUMBER: "Account Number",
      ACCOUNT_OWNER: "Account Owner",
      ACCOUNT_TITLE: "Account Title",
      ACCOUNT_TYPE: "Account Type",
      AMOUNT: "Amount",
      ANNUAL: "Annual",
      AVERAGE: "Average",
      AVG_BALANCE: "Avg Balance",
      AVG_TRUE_BALANCE: "Avg True Balance",
      BALANCE: "Balance",
      BALANCE_DAYS_NEGATIVE: "Balance/Days Negative",
      BALANCE_MATCH: "Balance Match",
      BANK_NAME: "Bank Name",
      BANK_STATEMENTS: "Bank Statements",
      CASH_FLOW: "Cash Flow",
      CHECKNUMBER: "Checknumber",
      COMPANY: "Company",
      CONTRIBUTING_FACTORS: "Contributing Factors",
      CREDIT_TRANSACTIONS: "Credit Transactions",
      DAILY_BALANCES: "Daily Balances",
      DAILY_CASH_FLOWS: "Daily Cash Flows",
      DATA: "Data",
      DAYS_NEG: "Days Negative",
      DAYS_NEG_COUNT: "Negative Days Count",
      DAYS_WITH_RETURNS: "Days with Returns",
      DAYS_W_RETURNS: "Days w/ Returns",
      DEPOSIT_COUNT: "Deposit Count",
      DEPOSIT_DATE: "Deposit Date",
      DEPOSIT_TOTAL: "Deposit Total",
      DESCRIPTION: "Description",
      DRAG_AND_DROP_LINK: "browse",
      DRAG_AND_DROP_TEXT_1: "Drag & Drop files here or",
      DRAG_AND_DROP_TEXT_2: "to upload",
      ENDING_BALANCE: "Ending Balance",
      ENDING_DATE: "Ending Date",
      EVENT_DATE: "Event Date",
      EXPENSES: "Expenses",
      FRAUDULENT: "Fraudulent",
      FRAUD_SCORE: "Fraud Score",
      ID: "ID",
      INCOMING_TRANSFERS: "Incoming Transfers",
      LARGE_TRANSACTIONS: "Large Transactions",
      LAST_WITHDRAWAL_DATE: "Last Withdrawal Date",
      LENDERS: "Lenders",
      LOW_DAYS: "Low Days",
      MANUALLY_UPLOADED_DOCUMENT_WARNING:
        "Manually uploaded document will be saved to your documents",
      MCA_BY_MONTH: "MCA By Month",
      MCA_COMPANIES: "MCA Companies",
      MCA_CREDITS: "MCA Credits",
      MCA_DEBITS: "MCA Debits",
      MCA_TRANSACTIONS: "MCA Transactions",
      MEMO: "Memo",
      MONTHLY: "Monthly",
      MONTHLY_CASH_FLOWS: "Monthly Cash Flows",
      MONTHLY_NEGATIVE_DAYS: "Monthly Negative Days",
      MONTH_AND_YEAR: "Month & Year",
      MONTH_GAP: "Month Gap",
      NON_TRUE_CREDITS: "Non-True Credits",
      NON_TRUE_CREDIT_TRANSACTIONS: "Non-True Credit Transactions",
      NON_TRUE_DEBITS: "Non-True Debits",
      NON_TRUE_REVENUE: "Non-True Revenue",
      NSF_TRANSACTIONS: "NSF Transactions",
      NUMBER: "Number",
      NUMBER_OF_CREDITS: "Number of Credits",
      NUMBER_OF_DEBITS: "Number of Debits",
      NUMBER_OF_INCOMING_TRANSFERS: "Number of Incoming Transfers",
      NUMBER_OF_MCAS: "# of MCA's",
      NUMBER_OF_NSF_ITEMS: "# of NSF Items",
      NUMBER_OF_ODS: "Number of OD's",
      NUMBER_OF_OUTGOING_TRANSFERS: "Number of Outgoing Transfers",
      NUMBER_OF_STOPS: "Number of Stops",
      NUMBER_OF_TRANSFERS: "Number of Transfers",
      NUMBER_OF_TRUE_CREDITS: "# of True Credits",
      NUMBER_OF_TRUE_DEBITS: "# of True Debits",
      OD_BALANCE: "Overdraft Balance",
      OD_NEG_DAYS: "Overdraft Negative Days",
      OD_NEG_DAYS_COUNT: "Overdraft Negative Days Count",
      OUTCOMING_TRANSFERS: "Outcoming Transfers",
      OUTGOING_TRANSFERS: "Outgoing Transfers",
      OVERDRAFT_TRANSACTIONS: "Overdraft Transactions",
      PLEASE_NOTE: "Please note",
      POSSIBLE: "Possible",
      PRODUCER: "Producer",
      PROFIT: "Profit",
      RECONCILES: "Reconciles",
      RECONCILED: "Reconciled",
      RECONCILED_ERROR: "Not Reconciled",
      RECOVERY_CREDITS: "Recovery Credits",
      RECOVERY_DEBITS: "Recovery Debits",
      REPEATING_TRANSACTIONS: "Repeating Transactions",
      REVENUE: "Revenue",
      REVENUE_STATISTICS: "Revenue Statistics",
      RUN: "Run",
      STARTING_BALANCE: "Starting Balance",
      START_DATE: "Start Date",
      STATEMENTS_SUMMARY: "Statements Summary",
      STATEMENT_MONTH: "Statement Month",
      STATEMENT_MONTH_NUMBER: "Statement Month #",
      STATEMENT_MONTH_NUMBER_AND_NAME: "Statement Month # and Name",
      STATEMENT_YEAR: "Statement Year",
      SUGGESTION: "Suggestion",
      TAX_ANALYSIS: "Tax Analysis",
      THUMBPRINTS: "Thumbprints",
      THUMBPRINT_SCORE: "Thumbprint Score",
      TITLE: "MoneyThumb",
      TOTAL_CREDITS: "Total Credits",
      TOTAL_DEBITS: "Total Debits",
      TRANSACTIONS: "Transactions",
      TRANSFERS: "Transfers",
      TRUEVAL: "Trueval",
      TRUE_BALANCE: "True balance",
      TRUE_CASH_FLOW: "True cash flow",
      TRUE_CREDITS: "True Credits",
      TRUE_CREDIT_TRANSACTIONS: "True Credit Transactions",
      TRUE_DEBITS: "True Debits",
      TRUE_NEG_DAYS: "True Negative Days",
      TRUE_NEG_DAYS_COUNT: "True Negative Days Count",
      TRUE_REVENUE: "True Revenue",
      TRUE_TRANSACTIONS: "True Transactions",
      TYPE: "Type",
      TYPE_ERROR: "Supported file type for bank statements is application/pdf",
      WAITING_FOR_RUN: "Waiting for run",
      WITHDRAWAL_COUNT: "Withdrawal Count",
      WITHDRAWAL_FREQUENCY: "Withdrawal Frequency",
      WITHDRAWAL_TOTAL: "Withdrawal Total",
      WORKDAYS: "Workdays"
    },
    NEW_DEAL: "New Deal",
    NEXT_RESULT: "Next Result",
    NOTES: {
      BUSINESS_NOTE: "Business Note | Business Notes",
      BUSINESS_UPDATES: "business updates",
      CALL_NOTE: "Call Note | Call Notes",
      CALL_SUMMARy: "Call Summary",
      DEAL_NOTE: "Deal Note | Deal Notes",
      STRATEGIC_INSIGHTS: "strategic insights"
    },
    NUMVERIFY: {
      CARRIER: "Carrier",
      DESCRIPTION:
        "Numverify is an API that validates international and domestic phone numbers for 232 countries.",
      LINE_TYPE: "Line Type",
      NO_PHONES: "There are no phone numbers assigned to the deal.",
      PHONE_NUMBER_VERIFICATION: "Phone Number Verification",
      TITLE: "Numverify"
    },
    ORCHESTRATION_UNDERWRITING_TOOLTIP: "Orchestration Step Outcome",
    ORGANIZATION: "Organization",
    OUT_OF: "Out of",
    PERSONA_STATUSES: {
      FAILED: "The check failed for the verification attempt.",
      NOT_APPLICABLE:
        "The check was not evaluated for the verification attempt.",
      PASSED: "The check passed for the verification attempt."
    },
    PLACEMENT: {
      DECLINE_DESCRIPTION_PLACEHOLDER:
        "Description (Optional - Limited to 250 Characters)",
      DECLINE_REASON_EDIT_TOOLTIP: "Edit Decline Reason",
      DECLINE_REASON_EMPTY: "Decline reason cannot be empty.",
      DECLINE_REASON_MODAL_TITLE: "Why Was the Deal Declined?",
      DECLINE_REASON_PLACEHOLDER: "Decline Reason",
      DECLINE_SUB_REASON_PLACEHOLDER: "Decline Sub-Reason",
      DROPDOWN_HEADING: "Select the reason below",
      NO_ACTIVE_STAGE_ERROR: "There is no active stage",
      NO_NAICS_API_ERROR: "NAICS code is required for API submission",
      REASON_BTN_LABEL: "+Reason",
      SEND_DECLINE_EMAIL: "Send decline email",
      SUBCATEGORY_REASONS_TITLE: "Select subcategory",
      SUBMISSION_FAILED: "Placement Submission Failed",
      SUBMISSION_FAILED_DETAIL:
        "Primary submission type failed. Select another submission type for this Placement.",
      TABLE_COLUMNS: {
        DECLINE_DESCRIPTION: "Declined Description",
        DECLINE_REASON: "Decline Reason",
        DECLINE_SUB_REASON: "Decline Sub-Reason",
        FUNDER_NAME: "Funder Name",
        PLACEMENT_STATUS: "Placement Status",
        PRODUCT_TYPE: "Product Type",
        RISK_TIER: "@:COMMON.RESULT Risk Tier",
        RISK_TIER_FUNDERS: "Funder Risk Tier",
        SCORECARD_KNOCKOUT: "@:COMMON.SCORECARD Knockout",
        SCORECARD_RESULT: "@:COMMON.SCORECARD @:COMMON.RESULT",
        SCORECARD_RISK_TIER: "@:COMMON.SCORECARD Risk Tier",
        SCORECARD_STATUS: "@:COMMON.SCORECARD Status",
        SUBMISSION: "Submission",
        SUBMISSION_TIME: "Submission Time",
        SUBMITTED_BY: "@:COMMON.SUBMITTED By",
        TRACKING: "Tracking #"
      }
    },
    PREVIOUS_RESULT: "Previous Result",
    PULL_DATA_FROM_SERVICE: "Update Record with {service} Data?",
    RECEIVED_DATA: "Received Data",
    RESTORING_DEAL: "Restoring Deal...",
    RESULT: "Result | Results",
    SENTILINK: {
      APP_ID: "Application ID: {id}",
      CREATED: "Created: {date}",
      MATCH: {
        ADDRESS_LINE_1: "@:GEOGRAPHY.ADDRESS_LINE_1",
        APPLICATION: "Application",
        APPLICATION_DATA: "Application Data",
        CATEGORY: "Individual Match",
        CITY: "City",
        CONFIDENCE: "Confidence",
        DOB: "DOB",
        DOB_MATCH: "DOB Match",
        FIRST_NAME: "First Name",
        ITIN: "ITIN",
        LAST_NAME: "Last Name",
        MANUAL_INPUT: "Manual Input",
        NO_UPDATE: "No Update Returned",
        PULL_DATA: "Update Record With Sentilink Data?",
        RECEIVED_DATA: "Received Data",
        RESULT: "Result",
        SSN: "SSN",
        SSN_MATCH: "SSN Match",
        STATE_CODE: "State Code",
        STATUS: "Status",
        STATUS_CHANGED: "Status will be changed to 'Match'",
        ZIPCODE: "Zipcode"
      },
      NO_DATA: "No Sentilink Data.",
      SERVICE_NAME: "Sentilink"
    },
    SERVICES: {
      API_FAILED_TITLE: "Failed to {action} API",
      API_RECENT_RESPONSE_SHOWN:
        "The most recent successful response is displayed."
    },
    SERVICES_DESCRIPTION: {
      BLU_COGNITION:
        "bluCognition provides machine learning, deep learning, artificial intelligence and big data services.",
      CLEAR_COURT_SEARCH:
        "Clear delivers critical information from the financial, legal, accounting, intellectual property, science, and media markets.",
      CLEAR_ID_BUSINESS_CONFIRM:
        "Clear delivers critical information from the financial, legal, accounting, intellectual property, science, and media markets.",
      DEFAULT:
        "{service} intended to collect customer data. For successful data transfer, please make sure the application step is filled then start parsing.",
      DNB: "Dun & Bradstreet provides a powerful source of global business information, knowledge, and insight, enabling companies to decision with confidence.",
      DOCUSIGN:
        "DocuSign provides electronic signature technology and digital transaction management services for facilitating electronic exchanges of contracts and signed documents.",
      EKATA: "Ekata provides global identity verification data via APIs.",
      EQUIFAX:
        "Equifax is a global data, analytics, and technology company. They help individuals gain financial independence by increasing access to capital for small businesses.",
      EXPERIAN:
        "Experian is a global information services company that provides information, and analytical tools to help clients manage their commercial and financial decisions.",
      FMCSA:
        "The Federal Motor Carrier Safety Administration (FMCSA) is the lead federal government agency responsible for regulating and providing safety oversight of commercial motor vehicles (CMVs).",
      HERON:
        "Heron Data's cash flow analysis turns raw financial transactional data into clear and concise data with descriptions, merchants, and custom categories.",
      INSCRIBE:
        "Inscribe helps companies detect fraudulent documents so they can trust their customers and grow faster.",
      LEXISNEXIS:
        "LexisNexis offers content-enabled workflow solutions in the legal, risk management, corporate, government, accounting, and academic markets.",
      MIDDESK:
        "Middesk helps businesses conduct background checks on other businesses. It centralizes the information necessary to conduct risk and compliance assessments on each of your customers.",
      MONEYTHUMB:
        "MoneyThumb simplifies your financial accounting through easy-to-use financial file converting and analysis tools.",
      OCRULUS:
        "Ocrolus is the leading document automation platform in financial services, powering the digital lending ecosystem with trusted data.",
      PERSONA:
        "Persona is an identity platform that helps business verify customer identities that brings trust to online interactions.",
      PLAID:
        "Plaid provides companies with the tools and access needed for the development of a digitally-enabled financial system.",
      SCORLEY:
        "Scorely makes information on businesses available to everyone so you know who to trust.",
      SENTILINK:
        "Sentilink is an identity verification technology company that helps in detecting and blocking synthetic identities.",
      SOCURE:
        "Socure is a predictive analytics platform for digital identity verification of consumers.",
      TAXSTATUS:
        "TaxStatus develops an IRS account monitoring that delivers IRS transcripts for any business or individual.",
      ENIGMA:
        "Enigma provides reliable data and insights on the identity, activity, and risk profile of every small business.",
      REPAY:
        "Repay is a full-service payment technology and processing provider that enables the expedient and secure collection of payments through any channel at any time.",
      TRANSUNION:
        "TransUnion provides total credit protection all in one place from credit alerts, credit reports and credit scores."
    },
    SERVICE_RUN_SUCCESSFULLY: "Service ran successfully",
    SERVICE_UNDERWRITING_TOOLTIP: "Data Provider Ran Manually",
    SOURCE: {
      API: "API",
      DASHBOARD: "Dashboard",
      PLACEMENT_API: "API (Placement)",
      PLACEMENT_EMAIL: "Email (Placement)",
      PLACEMENT_LENDFLOW: "Lendflow (Placement)",
      PLACEMENT_MANUAL: "Manual (Placement)",
      RENEWAL: "Renewal",
      TITLE: "Source",
      WIDGET: "Widget"
    },
    STAGE_GATES: {
      CONFIRM_SERVICE_REFRESH:
        "Current data is from {date}. Are you sure you want to run the service again?",
      CONFIRM_SERVICE_START: "Are you sure you would like to run this service?",
      START: "Start Stage Gates",
      START_CONFIRM:
        "The Stage Gates may have already been started or are in progress. Are you sure you want to continue?"
    },
    SUBCOLUMNS: {
      APPLICATION_DATE: "Application Date",
      DECLINE_REASON: "Decline Reason",
      FUNDED_DATE: "Funded Date",
      FUNDER: "Funder",
      PRODUCT_TYPE: "Product Type",
      RISK_TIER: "Risk Tier",
      STATUS: "Placement Status",
      SUBMISSION: "Submission",
      SUBMISSION_DATE: "Submission Date"
    },
    SUBTITLE: "Get our widget on your website in minutes",
    TABLE_HEADERS: {
      ACTUAL_MONTHLY_REVENUE: "Actual Monthly Revenue",
      ADDITIONAL_TRACKING_TOKENS: "Additional Tracking Tokens",
      ANALYST: "Analyst",
      ANALYSTS: "Analysts",
      APP_COMPLETED: "App Completed",
      APP_STARTED: "App Started",
      APP_STATUS: "Deal Progress",
      AUTOMATION: "Automation",
      BANK_DATA: "Bank Data",
      BANK_STATEMENTS: "Bank Statements",
      BUSINESS_OWNER: "Business Owner",
      CALL_DISPOSITION: "Call Disposition",
      CAPITAL_USE_CASE: "Capital Use Case",
      CLIENT_UUID: "Customer UUID",
      CONTACT: "Contact",
      DEAD_DESCRIPTION: "Dead Description",
      DEAD_REASON: "Dead Status",
      DEAD_SUB_REASON: "Dead Sub-Status",
      DEALS: "Deals",
      DEAL_MODE: "Deal Mode",
      DEAL_MODES: "Deal Modes",
      DEAL_STAGE: "Deal Stage",
      DEAL_STAGES: "Deal Stages",
      DISCOVERY: "Discovery",
      EMAIL_VALIDATION_MESSAGE: "Email Validation Message",
      FIRST_PLACEMENT_TIME: "First Placement Time",
      FUNDERS_WITH_OFFERS: "Funders With Offers",
      FUNDERS_WITH_PLACEMENTS: "Funders With Placements",
      FUNDED_AMOUNT: "Funded Amount",
      FUNDED_DATE: "Funded Date",
      FUNDER: "Funder",
      FUNDING_ADVISOR: "Onboarding",
      FUNDING_ADVISORS: "Onboarding",
      FUNDING_AMOUNT_REQUESTED: "Funding Amount Requested",
      FUNDING_LENDERS: "Funding Lenders",
      GENERAL_INDUSTRY: "General Industry",
      HAS_OFFERS: "Has Offers",
      HAS_PLACEMENTS: "Has Placements",
      HIGHEST_OFFER_AMOUNT: "Highest Offer Amount",
      INDUSTRY_OTHER: "Industry Details",
      LAST_PLACEMENT_TIME: "Last Placement Time",
      LAST_UPDATED: "Last Updated",
      LAST_CALL: "Last Call",
      LAST_EMAIL_SENT: "Last Email Sent",
      LAST_EMAIL_SENT_NAME: "Last Email Sent Name | Last Email Sent Names",
      LATEST_OFFER_CREATED: "Latest Offer Created",
      LENDFLOW_ANALYST: "@:COMMON.LENDFLOW @:DEALS.TABLE_HEADERS.ANALYST",
      LENDFLOW_FUNDING_ADVISOR:
        "@:COMMON.LENDFLOW @:DEALS.TABLE_HEADERS.FUNDING_ADVISOR",
      LENDFLOW_UNDERWRITER:
        "@:COMMON.LENDFLOW @:DEALS.TABLE_HEADERS.UNDERWRITER",
      LENDFLOW_UUID: "Lendflow UUID",
      NUMBER_OF_EMAILS_SENT: "Number of Emails Sent | No. of Emails Sent",
      NUMBER_OF_EMAILS_RECEIVED:
        "Number of Emails Received | No. of Emails Received",
      NUMBER_OF_EMPLOYEES: "Number of Employees",
      NUMBER_OF_OFFERS: "Number of Offers",
      NUMBER_OF_OFFERS_DECLINED: "Number of offers declined",
      NUMBER_OF_PLACEMENTS: "Number of Placements",
      OFFERS: "Offers",
      ORGANIZATION: "Organization",
      ORGANIZATIONS: "Organizations",
      ORIGIN_PARTNER: "Lead Origin Partner",
      PARTNER: "Partner",
      PARTNERS: "Partners",
      PIN_COLUMN: "Pin Column",
      PLACED_WITH_FUNDER: "Placed with Funder",
      PLACEMENTS: "Placements",
      PLACEMENT_STAGE: "Placement Stage",
      PLAID_CONNECTION: "Plaid Connection",
      RANGE: "Range:",
      SCORECARD_STATUS: "Scorecard Status",
      SCORECARD_RESULT_STATUS: "Scorecard Result Status",
      SENT_BY: "Sent by",
      SIC_CODE: "SIC Code",
      SIGNED_APPLICATION: "Signed Application",
      SOFTWARE_USER: "Software User",
      SOURCES: "Sources",
      STAGE_CATEGORY: "Stage Category | Stage Categories",
      STAGE_CONTROL: "Stage Control",
      STAGE_NAME: "Stage Name",
      STATE: "State",
      STATED_MONTHLY_REVENUE: "Stated Monthly Revenue",
      SUBMITTED_TO_UNDERWRITING: "Submitted to Underwriting",
      SUBMISSION_PARTNER: "Submission Partner",
      SUB_INDUSTRY: "Sub-Industry",
      TIME_IN_CURRENT_STAGE: "Time in Current Stage",
      TIME_SINCE_FIRST_SUBMISSION: "Time since first submission",
      TIME_SINCE_LAST_SUBMISSION: "Time since last submission",
      TIME_TO_OFFER_CREATION: "Time to Offer Creation",
      TIME_IN_BUSINESS: "Time in Business",
      TYPE: "Type",
      UNDERWRITER: "Processor",
      UNDERWRITERS: "Processors",
      UNDERWRITING_STATUS: "Orchestration Status",
      UNDERWRITING_STATUSES: "Orchestration Statuses",
      UNPIN_COLUMN: "Unpin Column",
      WORKFLOW_CATEGORIES: "Workflow Categories",
      WORKFLOW_CATEGORY: "Workflow Category",
      WORKFLOW_NAME: "Workflow Name | Workflow Names"
    },
    TABLE_MULTISELECT: {
      NO_SELECTED_DEALS_MESSAGE: "Please select at least one deal to update",
      UPDATE_MESSAGE: "{field} will be updated"
    },
    TAXSTATUS: {
      SERVICE_NAME: "TaxStatus",
      VERIFY: "Verify",
      VERIFY_SSN: "@:DEALS.TAXSTATUS.VERIFY @:COMMON.SSN",
      VERIFY_EIN: "@:DEALS.TAXSTATUS.VERIFY @:COMMON.EIN"
    },
    TITLE: "Deals",
    TITLE_ALL: "All Deals",
    TOTAL_SCORE: "Total Score",
    TRACKING: {
      ACTUAL_CREDIT_SCORE: "Actual Credit Score",
      ACTUAL_MINUS_STATED_CREDIT_SCORE: "Actual minus Stated Credit Score",
      ACTUAL_MONTHLY_REVENUE: "Actual Monthly Revenue",
      ADDITIONAL_UUID: "Additional tracking token | Additional tracking tokens",
      AMOUNT_REQUESTED: "Amount Requested",
      APP_TO_OFFER_STAGE_TIME: "Application to Offer Stage Time",
      BUSINESS_ANALYST: "Business Analyst",
      BUSINESS_LEAD_PROFILE: "Business/Lead Profile",
      BUSINESS_START_DATE: "Business Start Date",
      CLIENT_UUID: "Customer UUID",
      CLOSING_DT: "Closing Date/Time",
      DEAD_EMAIL_SENT: "Dead email sent",
      DEAL_KILLED_DATE_TIME: "Deal killed date/time",
      DEAL_TIMELINE: "Deal Timeline",
      DNB_DUNS_NUMBER: "D&B Duns Number",
      FUNDED_DT: "Funded Date/Time",
      FUNDED_STATUS: "Funded Status",
      FUNDING_AGENT: "Funding Agent",
      INDUSTRY: "Industry",
      INFO_PROVIDER_SUMMARY: "Information Provider Summary",
      INTEGRATION_CLIENT: "Integration Client",
      LEAD_ASSIGNMENT_DT: "Lead Assignment Date/Time",
      LEAD_ASSIGNMENT_TO_UW_DT: "Lead Assignment to Underwriter Date/Time",
      LEAD_CREATED_DT: "Lead Created Date/Time",
      LEAD_QUALIFIED_DT: "Lead Qualified Date/Time",
      LENDFLOW_BUSINESS_UUID: "Lendflow Business UUID",
      LENDFLOW_INDIVIDUAl_UUID: "Lendflow Individual UUID",
      LENDFLOW_UUID: "Lendflow's Organization UUID",
      MAX_LENGTH_ERROR: "Client UUID must not be longer than 50 characters.",
      OFFER_ACCEPTANCE_STATUS: "Offer Acceptance Status",
      OFFER_OUT_DT: "Offer Out Date/Time",
      OFFER_STATUS: "Offer Status",
      PLACEMENT_STAGE_DT: "Placement Stage Date/Time",
      PLACEMENT_STAGE_TIME: "Placement Stage Time",
      PLAID_CONNECTION: "Plaid Connection",
      PRIMARY_LANGUAGE_SPOKEN: "Primary Language Spoken",
      QUALIFICATION_STATUS: "Qualification Status",
      REFERRER_URL: "Referrer URL",
      SAME_UUID_ERROR: "Client UUID cannot be the same as Lendflow UUID",
      STATED_CREDIT_SCORE: "Stated Credit Score",
      STATED_MONTHLY_REVENUE: "Stated Monthly Revenue",
      TIME_IN_APP_STAGE: "Time in Application Stage",
      TIME_IN_CLOSING_STAGE: "Time in Closing Stage",
      TIME_IN_OFFER_OUT_STAGE: "Time in Offer Out Stage",
      TIME_IN_PLACEMENT_STAGE: "Time in Placement Stage",
      TIME_IN_SCORECARD_STAGE: "Time in Scorecard Stage",
      TIME_IN_UW_STAGE: "Time in Underwriting Stage",
      TITLE_ADD: "Add Client UUID",
      TITLE_EDIT: "Edit Client UUID",
      TYPE_OF_APPLICANT: "Type of Applicant",
      UNDERWRITER: "Processor",
      UNDERWRITING_STAGE: "Underwriting Stage",
      USER_WHO_KILLED_THE_DEAL: "User who killed the deal",
      UUID: "UUID",
      UW_STAGE_DT: "Underwriting Stage Date/Time",
      UW_TO_OFFER_STAGE_TIME: "Underwriting to Offer Stage Time",
      WHEN_FUNDS_NEEDED: "When Funds Needed",
      WIDGET_INFO: "Widget Information",
      WORKFLOW_UUID: "@:COMMON.WORKFLOW @:DEALS.TRACKING.UUID"
    },
    TRANSACTION: {
      ADD: "Add Transaction",
      AMOUNT: "Amount",
      CREATE_CUSTOM_SCHEDULE: "Create custom schedule",
      DATE: "Transaction Date",
      DELETE_WARNING: "Are you sure you want to delete this transaction?",
      DETAILS: "Transaction Details",
      DRAW_AMOUNT: "Draw Amount",
      DRAW_DATE: "Draw Date",
      EDIT: "Edit Transaction",
      HISTORY: "Transaction History",
      ID: "Transaction #",
      INVOICE_AMOUNT: "Invoice Amount",
      INVOICE_DATE: "Invoice Date",
      INVOICE_DUE_DATE: "Invoice Due Date",
      INVOICE_UPLOAD_TEXT: "Drag and drop invoice here or",
      NET: "Net",
      PAYMENT_TERMS: "Payment Terms",
      TYPE: "Transaction Type",
      UPLOAD_INVOICE: "Upload Invoice"
    },
    TRANSUNION: {
      ALL_MODELS: "All Models",
      CREDIT_ALERT: "Credit Alerts",
      CREDIT_ALERT_PLACEHOLDER: "Select @:DEALS.TRANSUNION.CREDIT_ALERT",
      CREDIT_REPORT: "Credit Reports",
      CREDIT_REPORT_PLACEHOLDER: "Select @:DEALS.TRANSUNION.CREDIT_REPORT",
      CONSUMER_SCORE: "Credit Scores",
      CONSUMER_SCORE_PLACEHOLDER: "Select @:DEALS.TRANSUNION.CONSUMER_SCORE",
      OTHER: "Other",
      OTHER_PLACEHOLDER: "Select @:DEALS.TRANSUNION.OTHER",
      P12_FILE: "Certificate (p12) File",
      PEM_FILE: "Key (pem) File",
      SELECT_SCORE_MODELS: "Select score models to run",
      SELECTED_MODELS: "Selected Models",
      SERVICE_NAME: "TransUnion",
      SHOW_REQUEST_PARAMETERS: "Show Request Parameters",
      TRENDED: "TruVision Trended Algorithms",
      TRENDED_PLACEHOLDER: "Select @:DEALS.TRANSUNION.TRENDED"
    },
    UCC: {
      BUSINESS_TITLE: "Business UCC Filings",
      INDIVIDUAL_TITLE: "Individual ucc debtor search",
      UCCS: "UCCs"
    },
    VIEW_ALL_DEALS: "View all deals"
  },
  EMAIL_INTEGRATION: {
    ADD_ALIAS: "@:COMMON.ADD @:EMAIL_INTEGRATION.ALIAS",
    ADD_DOMAIN: "@:COMMON.ADD a @:EMAIL_INTEGRATION.DOMAIN",
    ADD_NEW_ALIAS: "@:COMMON.ADD a @:COMMON.NEW @:EMAIL_INTEGRATION.ALIAS",
    ADD_SUBDOMAIN: "Add Sub-Domain",
    ALIAS: "Alias",
    ALIASES_ASSIGNMENT: "Aliases Assignment",
    ASSIGNED_DEAL: "Assigned deal",
    ASSIGN_TO_DEAL: "Assign to Deal",
    ASSOCIATE_USERS_ACCOUNTS: "Associate users accounts",
    AUTOMATION: {
      PRODUCT: "Product",
      TRANSACTIONAL: "Transactional"
    },
    BCC: "BCC",
    CAN_NOT_ADD_ALIAS_MESSAGE: "Save the domain first to add an alias",
    DELETE_ITEM_PROMPT: "{item} will be removed. Are you sure?",
    DELETE_DNS_CONFIRMATION: "Are you sure you want to delete DNS?",
    DELETE_DNS_TITLE: "Delete DNS",
    DOMAIN: "Sub-Domain",
    DOMAIN_NAME: "Sub-Domain Name",
    EMPTY_STATE_BUTTON: "Add Your Sub-Domain",
    EMAIL_ADDRESS: "@:COMMON.EMAIL @:COMMON.ADDRESS | @:COMMON.EMAIL Addresses",
    EMAIL_SIGNATURE: "@:COMMON.EMAIL Signature",
    EMPTY_STATE_SUBTITLE:
      "Email integration will allow communication sent to your customers to be white-labeled",
    EMPTY_STATE_TITLE: "Your email sub-domain is not set up yet",
    ENABLE_EMAIL_SIGNATURE:
      "Enable a unique signature for selected email account",
    USE_EMAIL_SIGNATURE_FOR_ALIASES: "Use this signature for all aliases",
    FILTERS: {
      ACTIVITY_STATUS: "Activity Status",
      COMPLAINED_ABOUT: "Complained About",
      SENT_SUCCESSFULLY: "Sent Successfully"
    },
    ENTER_DOMAIN_NAME: "Enter Your Sub-Domain Name",
    FROM_EMAIL: "From Email",
    FROM_PERSON: "From Person",
    HELP_TIPS_1:
      "To configure DKIM, the following records must match what’s in your domain’s DNS settings. Detection of these records may take up to 72 hours.",
    HELP_TIPS_2:
      "Add the CNAME records to the DNS configuration for your domain. To update the DNS records for your domain:",
    HELP_TIPS_3:
      "Different providers have different procedures for updating DNS records. The following table lists links to the documentation for several common providers. This list isn't exhaustive and inclusion in this list isn't an endorsement or recommendation of any company's products or services. If your provider isn't listed in the table, you can probably use the domain with Amazon SES.",
    INCOMING: "Incoming",
    LAST_UPDATED: "Last updated {date}",
    LINKS_CLICKED: "Links Clicked",
    NO_ALIAS_SET_MESSAGE_1: "No alias has been added yet.",
    NO_ALIAS_SET_MESSAGE_2:
      "Add and set up an alias for the organization's domain using the '+' button.",
    NO_DEAL_ASSIGNED: "No Deal Assigned",
    NO_DOMAINS_SET_MESSAGE_1: "No domains added here.",
    NO_DOMAINS_SET_MESSAGE_2: "Add and set up a domain using the '+' button.",
    NUMBER_OF_EMAILS_IN_THREAD: "Number of emails in the thread",
    OUTGOING: "Outgoing",
    RE: "Re",
    REASSIGN: "Reassign",
    RECORDINGS: "Recordings",
    REPLY_TO: "@:COMMON.REPLY @:COMMON.TO",
    STATUSES: {
      BOUNCED: "Email was bounced at {date}",
      COMPLAINED_AT: "Email was complained about at {date}",
      DELIVERED: "Email was delivered at {date}",
      OPENED: "Email was opened at {date}",
      SENT: "Sent Successfully"
    },
    SUBTITLE:
      "This page will allow you to integrate a sub-domain to white-label customer email communication. Reference our {link} to get started and reach out to your customer success manager to receive necessary DNS records.",
    SUBTITLE_LINK: "guide",
    TITLE: "Email Integration",
    TODAY_AT_TIME: "Today at {time}",
    UNIQUE_EMAIL_WARNING: "Email address must be unique",
    USE_IN_COMMUNICATION_EMAILS: "Use in Communication Emails",
    USE_IN_EMAILS_PRODUCT: "Use in Emails Product",
    USE_PRIMARY_SIGNATURE: "Use primary signature"
  },
  EMPLOYEES: {
    ADD_BTN: "Add Employee",
    DEAL_VISIBILITY: {
      ANALYSTS: "Analysts",
      ASSIGNED_ENABLE: "Enable visibility of all assigned deals",
      ASSIGNED_SUBTITLE:
        "{roleName} will also see any deals assigned to other {roleName}",
      DEFAULT_SUBTITLE: "Can see their assigned deals and...",
      FUNDING_ADVISORS: "Onboarding",
      TITLE: "Deal Visibility",
      UNASSIGNED_ENABLE: "Enable visibility of unassigned deals",
      UNASSIGNED_SUBTITLE: "{roleName} will also see all unassigned deals",
      UNDERWRITERS: "Processors"
    },
    DELETE_EMPLOYEE: "Delete Employee",
    EDIT_EMPLOYEE: "Edit Employee",
    NO_DATA_MESSAGE: "No employee found",
    SUBTITLE: "This page will allow you to manage your employees.",
    TITLE: "Employees",
    USER_MANAGEMENT: "User Management"
  },
  ERRORS: {
    NUMBER_RANGE_ERROR: "The value must be between {min} and {max}",
    NUMERIC_ERROR: "The value must be numeric"
  },
  EXTERNAL: {
    CLOSING_DOCS: {
      CLOSING_DOCS: "Closing Docs",
      DESCRIPTION: "Please upload documents from the list below",
      GET_A_LOAN: "Get a Loan",
      SECURITY:
        "We take your privacy very seriously. We use bank level security to protect your personal information. Applying will not impact your credit score. We only use a soft pull to check your credit.",
      TITLE: "Required Closing Docs",
      UPLOAD_TEXT_1: "Drag & Drop files here or ",
      UPLOAD_TEXT_LINK: "Upload Manually"
    }
  },
  FIELD_DICTIONARY: {
    boolean: "True/False",
    draw_amount: "Draw Amount, $",
    end_date: "End Date",
    equal: "Equal to (=)",
    funded_date: "Funded Date",
    funding_amount: "Funding Amount, $",
    funding_commission: "Commission, %",
    funding_end_date: "Funding End Date",
    funding_start_date: "Funding Start Date",
    funding_term_loan_commission: "Commission, %",
    greater_than: "Greater than (>)",
    greater_than_or_equal: "Greater than or equal to (≥)",
    in: "In",
    invoice_amount: "Invoice Amount, $",
    is_not_null: "Is Not Null",
    is_null: "Is Null",
    less_than: "Less than (<)",
    less_than_or_equal: "Less than or equal to (≤)",
    not_equal: "Not equal to (≠)",
    not_in: "Not In",
    range: "Range",
    start_date: "Start Date",
    string_contains: "String contains",
    string_equal: "String equal to (=)",
    string_not_contains: "String does not contain",
    string_not_equal: "String not equal to (≠)"
  },
  FILE_TYPES: {
    adverse_media_report: "Adverse Media Report",
    ap_document_1: "Accounts Receivable Aging Report (Current Year)",
    ap_document_2: "Accounts Payable Aging Report (Current Year)",
    ap_document_3: "Accounts Receivable Aging Report (Prior Year)",
    ap_document_4: "Accounts Payable Aging Report (Prior Year)",
    ap_document_5: "Accounts Receivable Aging Report (2 Years Ago)",
    ap_document_6: "Accounts Payable Aging Report (2 Years Ago)",
    application_stip: "Application Stip",
    bank_statements_10: "October's Bank Statement",
    bank_statements_11: "November's Bank Statement",
    bank_statements_12: "December's Bank Statement",
    bank_statements_1: "January's Bank Statement",
    bank_statements_2: "February's Bank Statement",
    bank_statements_3: "March's Bank Statement",
    bank_statements_4: "April's Bank Statement",
    bank_statements_5: "May's Bank Statement",
    bank_statements_6: "June's Bank Statement",
    bank_statements_7: "July's Bank Statement",
    bank_statements_8: "August's Bank Statement",
    bank_statements_9: "September's Bank Statement",
    contract: "Contract",
    debt_schedule: "Debt Schedule",
    drivers_license_back: "Driver's License Back",
    drivers_license_front: "Driver's License Front",
    financial_document_1: "Income Statement (P & L) (Current Year)",
    financial_document_2: "Income Statement (P & L) (Prior Year)",
    financial_document_3: "Income Statement (P & L) (2 Years Ago)",
    financial_document_4: "Balance Sheet (Current Year)",
    financial_document_5: "Balance Sheet (Prior Year)",
    financial_document_6: "Balance Sheet (2 Years Ago)",
    funding_invoice: "Funding Invoice",
    funding_invoice_purchase_order: "Invoice/Purchase Order",
    funding_irs_form_ss4_ein: "IRS Form-SS4 (EIN Letter)",
    funding_payoff_balance_letter: "Payoff/Balance Letter",
    funding_proof_of_ownership: "Proof of Ownership",
    funding_voided_check: "Voided Check",
    inquiries: "Inquiries",
    insurance_certificate: "Insurance Certificate",
    iso_application: "ISO",
    offer_stip: "Offer Stip",
    payroll_report: "Payroll Report (Q1 2020)",
    payroll_report_1: "Payroll Report (Q2 2020)",
    payroll_report_2: "Payroll Report (Q3 2020)",
    payroll_report_3: "Payroll Report (Q4 2020)",
    payroll_report_4: "Payroll Report (Q1 2021)",
    payroll_report_5: "Payroll Report (Q2 2021)",
    payroll_report_6: "Payroll Report (Q3 2021)",
    payroll_report_7: "Payroll Report (Q4 2021)",
    plaid_statement: "Plaid Statement",
    raw_cfa_data: "Raw CFA Data",
    recovered_document: "Recovered Verification Documents",
    risk_inform_report: "Clear S2S Risk Inform Report",
    signed_application_doc: "Signed Application Document",
    tax_exemption: "Tax Exemption",
    tax_return_1: "Business Tax Return - Last Year",
    tax_return_2: "Business Tax Return - 2 Years Ago",
    tax_return_3: "Business Tax Return - 3 Years Ago",
    tax_return_4: "Personal Tax Return - Last Year",
    tax_return_5: "Personal Tax Return - 2 Years Ago",
    tax_return_6: "Personal Tax Return - 3 Years Ago",
    tax_return_7: "Business Tax Extension",
    tax_return_8: "Personal Tax Extension",
    verification_doc: "Verification Document",
    xlsx_cfa_data: "XLSX CFA Data"
  },
  FINANCES: {
    PERSONAL_INCOME: "Personal Income",
    TOTAL_DEBT: "Total Debt"
  },
  FUNDERS: {
    ADD_FIRST_FUNDER: "Add First Funder",
    ADD_NEW_FUNDER: "Add New Funder",
    ADD_NEW_FUNDER_SUCCESS:
      "The New Funder has been sent over to a Super Admin for review",
    APPROVE_CARD_DESCRIPTION:
      "This funder is currently inactive and needs to be approved. Do you want to approve this funder?",
    APPROVE_FUNDER: "Approve Funder",
    AVERAGE_DAILY_BALANCE: "Average Daily Balance",
    BIWEEKLY_PAYMENT: "Biweekly Payment",
    CHARACTERISTICS: {
      APPROVAL_DECLINE_FLOW: "Approval/Decline Flow",
      EDIT: "Edit Funder's characteristics",
      EMAIL: "Email address",
      MAX_NEGATIVE_DAYS: "Max Negative Balance Days M1",
      MAX_NEGATIVE_DAYS_2: "Max Negative Days",
      MIN: "Min",
      MIN_AVG_DAILY_BALANCE: "Min Avg Daily Balance",
      MIN_DEPOSITS: "Min Number of Monthly Deposits",
      MIN_MONTHLY_GROSS: "Min Monthly Gross Deposits",
      MIN_MONTHLY_REVENUE: "Min Monthly Revenue",
      MIN_TIME_IN_BUSINESS: "Min Time in Business",
      MIN_TIME_IN_BUSINESS_MONTHS: "Min Time in Business [months]",
      POSTPONE_APPROVAL: "Postpone Approval",
      POSTPONE_APPROVAL_DESC:
        "API submission will be made after Data Orchestration is passed and offers are generated",
      POSTPONE_DECLINE: "Postpone Decline",
      POSTPONE_DECLINE_DESC:
        "API submission will be made after Data Orchestration fails",
      SUBMISSION_TYPE: "Choose Submission Type",
      TARGET: "Target",
      TITLE: "Characteristics"
    },
    CONTACTS: {
      DELETE_WARNING:
        " will be permanently removed from the list of your contacts.",
      RESPONSIBLE_FOR_PT: "Responsible for Product Types",
      RESPONSIBLE_FOR_SINGLE_PT: "Responsible For Product Type"
    },
    CONVERSION_RATES: "Conversion Rates",
    CORPORATE_STRUCTURE: "Corporate Structure",
    CRITERIA: "Criteria",
    DAILY_PAYMENT: "Daily Payment",
    DEBT_SERVICE_COVERAGE: "Debt Service Coverage",
    DELETE_FUNDER: "Delete Funder",
    DELETE_FUNDER_WARNING:
      "This funder will be permanently deleted. Are you sure?",
    DUPLICATE_EMAIL_ERROR: "Duplicate email",
    EDIT_CORPORATE_STRUCTURE: "Edit Corporate Structure",
    EDIT_CRITERIA: "Edit Criteria",
    EDIT_EQUIPMENT_RESTRICTIONS: "Edit Equipment Restrictions",
    EDIT_FINANCIALS: "Edit Financials",
    EDIT_FUNDER: "Edit Funder",
    EDIT_FUNDER_OFFERS: "Edit Funder Offers",
    EDIT_INDUSTRIES: "Edit Industries",
    EDIT_PRODUCT_TYPES: "Edit Product Types",
    EMPTY_BODY: "Contact Lendflow support to get a list of funders.",
    EMPTY_HEADING: "Funders list is empty",
    EQUIPMENT_RESTRICTIONS: "Equipment Restrictions",
    FICO: "FICO",
    FICO_MIN: "FICO (min)",
    FICO_TARGET: "FICO (target)",
    FINANCIALS: "Financials",
    FUNDER_DESCRIPTION: "Funder Description",
    FUNDER_METRICS: "Funder Metrics",
    FUNDER_NAME: "Funder Name",
    FUNDER_OFFERS: "Funder Offers",
    FUNDER_ROLE: "Funder Role",
    FUNDER_WEBSITE: "Website",
    FUNDING_AMOUNT_MAX: "Funding Amount (max)",
    FUNDING_AMOUNT_MIN: "Funding Amount (min)",
    FUNDING_COUNTRIES: "Funding Countries",
    HARD_PULL_FUNDING: "Hard Pull for Funding",
    HARD_PULL_OFFERS: "Hard Pull for Offers",
    HOME_OFFICE_RESTRICTION: "Home Office Restriction",
    INDUSTRIES: {
      ACCEPTED: "Accepted",
      ACCEPTED_INDUSTRIES: "Accepted Industries",
      HIGH_RISK: "High Risk",
      PREFERRED_INDUSTRIES: "Preferred industries",
      RESTRICTED: "Restricted",
      RESTRICTED_INDUSTRIES: "Restricted Industries",
      TITLE: "Industry & Geographic Restrictions"
    },
    INFO: "Funder Information",
    METRICS: "Metrics",
    MIN_NUM_OF_TRUCKS: "Min # of Trucks",
    MONTHLY_DEPOSITS_MAX: "Monthly Deposits (max)",
    MONTHLY_DEPOSITS_MIN: "Monthly Deposits (min)",
    MONTHLY_PAYMENT: "Monthly Payment",
    MONTHLY_REVENUE: "Monthly Revenue",
    MONTHLY_REVENUE_MIN: "Monthly Revenue (min)",
    MONTHLY_REVENUE_TARGET: "Monthly Revenue (target)",
    MONTHS_IN_BUSINESS: "Months in Business",
    MONTHS_IN_BUSINESS_MIN: "Months in Business (min)",
    MONTHS_IN_BUSINESS_TARGET: "Months in Business (target)",
    MONTHS_SINCE_BK_DISCHARGE: "Months Since BK Discharge",
    MONTHS_SINCE_BK_DISMISSED: "Months Since BK Dismissed",
    NEGATIVE_DAYS_LM: "Negative Days Last Month (max)",
    NEGATIVE_DAYS_TMG: "Negative Days 3 Month Avg (max)",
    NEW_FUNDER: "New Funder",
    NONE_SELECTED: "None selected.",
    NO_DATA_MESSAGE: "No funder found",
    PAST_BANKRUPTCY_RESTRICTION: "Past Bankruptcy Restriction",
    POSITION_FUNDED: "Position funded",
    PRIMARY_METHOD: "Primary Method",
    PRIMARY_METHOD_REQUIRED: "Please select primary submission method",
    PRODUCT_SPECIFIC_FUNDER_METRICS: "Product-Specific Funder Metrics",
    PRODUCT_TYPES: "Product Types",
    RESTRICTED_CORPORATE_STRUCTURES: "Restricted Corporate Structures",
    RESTRICTED_STATES: "Restricted States/Provinces",
    RISK_TIER: "Risk Tier | Risk Tiers",
    TERM_MAX: "Term (max)",
    TERM_MIN: "Term (min)",
    TITLE: "Funders",
    TITLED_EQUIPMENT: "Titled Equipment",
    TOTAL_INITIAL_PAYMENT_FIELD_NAME:
      "Total Initial Payment (Advance Payments + Fee)",
    UNTITLED_EQUIPMENT: "Untitled Equipment",
    UPLOAD_FUNDER_LOGO: "Upload Funder Logo",
    USER_LIST_EMPTY: "Verified users will appear here",
    WEEKLY_PAYMENT: "Weekly Payment"
  },
  FUNDER_OFFERS: {
    CONTACTED_VIA: "Contacted via",
    FUNDED: "Funded Offers",
    MANUAL_STATUS: "Manual status",
    OFFERS_RECEIVED: "Offers Received",
    OFFER_TAB_ALL: "All",
    OFFER_TAB_BUSINESS_INFO: "Business Information",
    OFFER_TAB_DOCUMENTS: "Deal Documents",
    OFFER_TAB_OFFER: "Offer",
    OFFER_TAB_PERSONAL_INFO: "Personal Information",
    PRODUCT_TYPE_VIEW: "Product Type View",
    SIMPLE_TABLE_VIEW: "Simple Table View",
    TIME: "time(s)"
  },
  GENERAL: {
    APPLICATIONS: "Applications",
    BUSINESSES: "Businesses",
    CUSTOM: "Custom",
    DATA_PROVIDERS: "Data Providers",
    DEALS: "Deals",
    TRACKING: "Tracking"
  },
  GEOGRAPHY: {
    ADDRESS: "Address",
    CITY: "City",
    COUNTRIES: "Countries",
    COUNTRY: "Country",
    COUNTY: "County",
    LINE_1: "Address Line 1",
    LINE_2: "Address Line 2",
    POSTAL_CODE: "Postal Code",
    PROVINCE: "Province",
    STATE: "State",
    STATE_OR_PROVINCE: "State/Province",
    STREET_PRE_DIRECTION: "Street Pre-Direction",
    UNIT_DESIGNATION: "Unit Designation",
    UNIT_NUMBER: "Unit Number",
    ZIP: "ZIP",
    ZIP4: "ZIP4",
    ZIP5: "ZIP5"
  },
  HTTP: {
    ERRORS: {
      "400": "Bad Request",
      "403": "You don't have permission to do this action.",
      "422": "Validation failed."
    },
    REQUEST: {
      CREATE: "Creating...",
      DELETE: "Deleting...",
      UPDATE: "Updating..."
    },
    RESPONSE: {
      CREATE: "Successfully created.",
      DELETE: "Successfully deleted.",
      UPDATE: "Successfully updated."
    }
  },
  IMPERSONATE: {
    YOU_ARE_BACK: "You are back to your account",
    SUCCESSFULLY: "Successfully Impersonated"
  },
  INDIVIDUAL_PROFILES: {
    INDIVIDUAL_INFO: "Individual info",
    MAIN_ROUTE_NAME: "Individuals",
    NO_RESULTS_TITLE: "Individuals list is empty",
    OWNER_ADDITIONAL_INFORMATION: "Additional Individual Information",
    OWNER_INFORMATION: "Individual Information",
    PERSONAL_INFO: "Personal Info",
    SEARCH_TERM: "for individuals",
    TITLE: "Individual Profiles"
  },
  INTEGRATIONS: {
    ACTIVE: "Active",
    ADD_CUSTOM_TOKEN: "@:COMMON.ADD @:COMMON.CUSTOM @:COMMON.TOKEN",
    ADD_INTEGRATION_SCRIPT: "1. Add the integration script to your website",
    ADD_IP_DESCRIPTION:
      "Add new IP address to restrict traffic to this specific IP",
    ADD_IP_TO_WHITELIST: "Add IP Address to whitelist",
    ADD_ISO_ID: "Add ISO ID",
    ADD_ISO: "Add an ISO to connect with MCA Track",
    ADD_WIDGETS_DESCRIPTION: {
      END: "section of this page, configure and generate the link to the widget, then use this link on any page that has the above integration script added. Add the link to any button and the widget will pop up automatically when the button is clicked!",
      START: "Go to"
    },
    ADD_WIDGETS_TITLE: "Add Widgets To Your Website",
    ADD_WIDGETS_TO_YOUR_WEBSITE: "Add The Integration Script To Your Website",
    AIRWALLEX_DESC:
      "Airwallex is a global payments fintech that enables businesses to operate anywhere, anytime.",
    ALL_SERVICES: "Lending Service Providers",
    API_BEARER_TOKEN: "API Bearer Token",
    BEARER_TOKEN: "Bearer Token",
    BORROWER_PLATFORM: {
      STEPS: {
        GET_LINK: {
          TITLE: "Your Borrower Platform Link"
        },
        GET_SNIPPET: {
          SUBTITLE:
            'Copy and paste the code snippet below before the "</head>" tag on every page you would like your Borrower Platform to appear.',
          TITLE: "Add The Integration Script To Your Website"
        },
        SET_BRANDING: {
          TITLE: "@:COMMON.SET @:COMMON.BRANDING",
          SUBTITLE:
            "Customize logo, colors, and email brand in the Customize tab or use default Lendflow branding by selecting 'None'."
        }
      },
      SUB_LINK: "@:COMMON.BORROWER @:COMMON.PLATFORM",
      SUBTITLE:
        "The Borrower Platform allows applicants to check the status of their application, contact their dedicated funding advisor and more. Copy the integration script to embed the Borrower Platform on your landing page.",
      TITLE: "@:COMMON.BORROWER @:COMMON.PLATFORM @:COMMON.INTEGRATION"
    },
    BRAND: "Brand",
    CANOPY_DESC:
      "Canopy Servicing is the most configurable loan management and servicing platform available.",
    COMING_SOON: "Coming Soon",
    CONFIGURATION: "Configuration",
    CONFIGURED: "Configured",
    CONNECT: "Connect",
    CONNECTED_SERVICES: "Connected services",
    CONTACT_YOUR_CUSTOMER_SUCCESS_MANAGER:
      "Contact your customer success manager",
    CONTRACT: "Contract Template | Contract Templates",
    CONTRACT_TEMPLATE: "Choose Contract Template To Be Used For Signature",
    CONTRACT_TEMPLATE_DESCRIPTION:
      "Choose a contract template; select 'None' for the default Lendflow DocuSign contract, or choose an alternative from the dropdown list.",
    COPY_TEXT:
      'Copy and paste the code snippet below before the "</head>" tag on every page you would like your widget to appear.',
    YOUR_WIDGET_LINK: "Your Widget Link",
    CUSTOM_ADDRESS_DESCRIPTION:
      "Choose the address for a domain you would like displayed on widget links your clients view. Ensure the integration script is already embedded on this page.",
    CUSTOM_ADDRESS_TITLE:
      "Use a custom address for your widget links (optional)",
    DATA_PROVIDERS: {
      CONNECTED: "Connected Data Providers",
      DESCRIPTION:
        "Enter your credentials for Data Providers to activate your existing contracts and engage Data Providers services through Lendflow.",
      TITLE: "Data Provider | Data Providers"
    },
    EMAIL_BRAND: "Email Brand",
    ENTER_IP_ADDRESS: "Enter IP Address",
    LENDER: {
      APPLY_CREDENTIALS_TO_ALL: "Apply Credentials to all products",
      APPLY_TEMPLATE_ID_TO_ALL: "Apply Workflow Template ID to all products",
      BHG: {
        TITLE: "BHG",
        DESCRIPTION:
          "BHG Financial creates financial solutions for business executives, consumers, and financial institutions."
      },
      BITTY_ADVANCE: {
        TITLE: "Bitty Advance",
        DESCRIPTION:
          "Since 2018, Bitty Advance is dedicated to helping small businesses with funding amounts from $2,000-$25,000."
      },
      BIZ_2_CREDIT: {
        TITLE: "Biz2Credit",
        DESCRIPTION:
          "Biz2Credit is a digital-first provider of small business funding and offers its Biz2X Platform to help financial institutions manage risk."
      },
      CAN_CAPITAL: {
        TITLE: "CAN Capital",
        DESCRIPTION:
          "CAN Capital provides capital to small and medium-sized businesses, using its own real-time platform and risk-scoring models."
      },
      CLICKLEASE: {
        TITLE: "ClickLease",
        DESCRIPTION:
          "ClickLease offers financing solutions using advanced technology to provide instant approvals and funding for business owners."
      },
      CONNECTED: "Connected Lenders",
      CREDIBLY: {
        TITLE: "Credibly",
        DESCRIPTION:
          "Credibly is a Fintech platform that uses data science and technology to offer finance for small businesses."
      },
      DESCRIPTION:
        "Enter your credentials for Lenders to activate your existing contracts and manage deal submissions via the Lendflow API.",
      ELEVATE: {
        TITLE: "Elevate",
        DESCRIPTION:
          "Elevate specializes in Merchant Cash Advance, a boutique alternative finance product that helps small businesses take care of their urgent needs."
      },
      FORA_FINANCIAL: {
        TITLE: "Fora Financial",
        DESCRIPTION:
          "Fora Financial provides financial services to businesses including merchant funding and small business loans."
      },
      FORWARD_FINANCING: {
        TITLE: "Forward Financing",
        DESCRIPTION:
          "Forward Financing is a fintech company that provides fast, flexible working capital to small businesses nationwide."
      },
      FUNDBOX: {
        TITLE: "Fundbox",
        DESCRIPTION:
          "Fundbox is an AI-powered financial platform for small businesses."
      },
      FUNDING_CIRCLE: {
        TITLE: "Funding Circle",
        DESCRIPTION:
          "Funding Circle is a lending platform for small businesses."
      },
      GIGGLE_FINANCE: {
        TITLE: "Giggle Finance",
        DESCRIPTION:
          "Giggle Finance is the nation's first financing platform to exclusively focus on gig workers, independent contractors and freelancers."
      },
      HEADWAY_CAPITAL: {
        TITLE: "Headway Capital",
        DESCRIPTION:
          "Headway Capital’s mission is to provide access to the credit you need to run your business."
      },
      IDEA_FINANCIAL: {
        TITLE: "Idea Financial",
        DESCRIPTION:
          "Idea Financial is a team of experienced business advisors and financial services."
      },
      KAPITUS: {
        TITLE: "Kapitus",
        DESCRIPTION:
          "Kapitus is a business loan provider dedicated to helping small and medium-sized businesses in their growth."
      },
      LENDINI: {
        TITLE: "Lendini",
        DESCRIPTION:
          "Lendini offers financial services such as business cash advance, merchant cash advance and funding solutions to various businesses."
      },
      LENDIO: {
        TITLE: "Lendio",
        DESCRIPTION:
          "Lendio is an online lending marketplace that develops financial applications to offer online loan services."
      },
      NEWITY: {
        TITLE: "Newity",
        DESCRIPTION:
          "NEWITY is the #1 facilitator of SBA 7(a) loans up to $500,000. Help your clients get funded in as little as three weeks."
      },
      ONDECK: {
        TITLE: "Ondeck",
        DESCRIPTION:
          "Ondeck is a technology-enabled financial platform that provides loan financing to small and medium-sized businesses."
      },
      PROVIDE_INFORMATION:
        "Please provide your credentials and workflow template ID",
      RAPID_FINANCE: {
        TITLE: "Rapid Finance",
        DESCRIPTION:
          "Rapid Finance provides working capital to small and mid-sized businesses in the United States."
      },
      REVENUED: {
        TITLE: "Revenued",
        DESCRIPTION:
          "Revenued is a card that cares less about FICO and more about your business."
      },
      SELECT_PRODUCT: "Select Product",
      STEARNS_BANK: {
        TITLE: "Stearns Bank",
        DESCRIPTION:
          "Stearns Bank has strength, stability and unmatched personal service."
      },
      YOULEND: {
        TITLE: "YouLend",
        DESCRIPTION:
          "YouLend is the preferred embedded financing platform for many of the world’s leading e-commerce platforms, tech companies and PSPs."
      },
      PEARL_CAPITAL: {
        TITLE: "Pearl Capital",
        DESCRIPTION:
          "Pearl Capital provides innovative alternative financial solutions to the underbanked and unbanked business sector."
      },
      TITLE: "Lender | Lenders"
    },
    FUNDER_KEY: "Funder Key",
    INTEGRATE_ADDITIONAL_SERVICE_PROVIDERS:
      "Integrate additional service providers",
    INTEGRATIONS: "Integrations",
    IP_ADDRESSES_IN_WHITELIST: "IP Addresses in whitelist",
    IP_ADDRESS_ALREADY_WHITELISTED: "This IP address is already whitelisted",
    IP_ADDRESS_IN_WHITELIST: "IP Address in whitelist",
    IP_HOST_ADDRESS_SUBTITLE:
      "If you specify whitelisted IP addresses users will only be able to login from the IP addresses listed",
    ISOS_ADDED: "ISO added",
    ISO_ID: "ISO ID",
    LENDFLOW_UW_SERVICES: "Lendflow Underwriting services",
    LENDING_SERVICE_PROVIDER: {
      CONNECTED: "Connected Lending Service Providers",
      DESCRIPTION:
        "Enter your credentials for Lending Service Providers to activate your existing contracts and access their services via Lendflow.",
      TITLE: "Lending Service Provider | Lending Service Providers"
    },
    LITHIC_DESC:
      "Lithic is a financial technology startup that provides virtual cards and a card issuing platform for businesses and consumers.",
    MAKE_CUSTOM_REQUIRED: "Make {label} required",
    MCA_TRACK_DESC:
      "As a fully hosted cloud based solution, MCAtrack has become the benchmark of CRM platforms in the Cash Advance industry.",
    NO_IP_ADDRESSES: "You have no IP address yet",
    NO_ISO: "No ISO added",
    ORIGINATION: {
      BUSINESSLOANS: {
        TITLE: "Businessloans.com",
        DESCRIPTION:
          "Businessloans.com offers short-term and long-term loan services."
      },
      CONNECTED: "Connected Origination Platforms",
      DESCRIPTION:
        "Enter your credentials for Origination Platforms to activate your existing contracts and access deal flows through Lendflow.",
      FUNDERA: {
        TITLE: "Fundera",
        DESCRIPTION:
          "Fundera is a marketplace for small business financial solutions."
      },
      INTUIT_QUICKBOOKS: {
        TITLE: "Intuit QuickBooks",
        DESCRIPTION:
          "Intuit QuickBooks is small business financial software helping start-ups track, organize, and manage their finances."
      },
      LENDIO: {
        TITLE: "Lendio",
        DESCRIPTION:
          "Lendio is an online lending marketplace that develops financial applications to offer online loan services."
      },
      PLATFORMS: "Origination Platforms",
      TITLE: "Origination"
    },
    PRODUCT_TYPE: "Product Type",
    REDIRECT_SETTING: "Choose a Redirect Option",
    REDIRECT_SETTING_DEFAULTS:
      "(Optional - if not specified, will default to ‘Borrower Platform’)",
    REDIRECT_SETTING_DESC:
      "Once the last screen of the widget is completed, the app can redirect to the borrower platform allowing applicants to check the status of their application or it can redirect to a specified URL. If you don’t need redirection select “None”.",
    REDIRECT_URL: "@:COMMON.REDIRECT @:COMMON.URL",
    REDIRECT_URL_APPLIED: "@:COMMON.REDIRECT @:COMMON.URL has been applied",
    QUESTIONS: "Questions",
    QUICKLY_AND_EASILY: "Quickly And Easily",
    SECURITY_SETTINGS: "Security Settings",
    SELECT_WFB_TEMPLATE: "Please select a Workflow Builder Template",
    SUBTITLE:
      "Quickly and easily integrate your capital program into your product.",
    TITLE: "Integrations",
    USE_BEARER_TOKEN: "Use this Bearer Token only for API.",
    USE_DEFAULT_VALIDATION: "Use default validation",
    WIDGET: {
      SUB_LINK: "@:COMMON.WIDGET @:COMMON.INTEGRATION",
      SUBTITLE:
        "Quickly and easily integrate your capital program into your product. Fill down sections to produce the relevant widget url and code to use in the right side block",
      TITLE: "@:COMMON.WIDGET @:COMMON.INTEGRATION"
    },
    WIDGET_BRAND: "Widget Brand",
    WIDGET_BRANDING_DEFAULTS: "(Optional - defaults to Lendflow branding)",
    WIDGET_BRANDING_DESCRIPTION:
      "Customize logo, colors, and email brand in the Customize tab or use default Lendflow branding by selecting 'None'.",
    WIDGET_KEY: "Integration Widget Key",
    WIDGET_LINK: "Widget Link",
    WIDGET_LINK_HEADING_1:
      "Choose Widget Name, Workflow Builder Template and Workflow Category",
    WIDGET_LINK_NOT_AVAILABLE:
      "Link to this widget can be generated on Application Links Hub page of your deal.",
    WIDGET_NAME: "Widget Name",
    WIDGET_TYPE: "Widget Type",
    WIDGET_TYPE_DESCRIPTION:
      "There are multiple types of application widgets. Reach out to your customer success manager if you are unsure of what to choose.",
    WIDGET_WORKFLOW_CATEGORY: "Widget Workflow Category",
    WIDGET_WORKFLOW_DESCRIPTION:
      "Choose workflow builder template. If you are processing your own applications, you can change this selection to use a custom workflow builder template."
  },
  LENDER_NETWORK: {
    CONTACT_SUPPORT: "Contact Your Support",
    NO_DATA:
      "Data is absent. For more detailed information, please contact support.",
    SELECT_ALL_TITLE: "Lender Name",
    SETTING_DESCRIPTION: {
      CLIENT: "Below is a list of lenders defined in your network.",
      LENDFLOW: "Select lenders who are part of this organization's network."
    },
    SETTING_SWITCH:
      "Turn on to configure the lender network for this organization",
    TITLE: "Lender Network"
  },
  LOGS: {
    CREATED: "Created",
    IP: "IP",
    LAST_UPDATED: "Last Updated",
    OCCURENCES: "Occurrences",
    REQUEST_METHOD: "Request Method",
    ROLES: "Roles",
    RUN_TIME: "Run Time",
    STATUS_CODE: "Status Code",
    URI: "URI",
    USER_ID: "User ID"
  },
  NOTIFICATIONS: {
    ACTIVITY_HUB_DESCRIPTION:
      "Allow users to view the Activity Hub in this Workflow.",
    ACTIVITY_HUB_NOTIFICATIONS:
      "Send {company} Activity Hub {type} notifications",
    ACTIVITY_HUB_WORKFLOW_BUILDER_DESCRIPTION:
      "Configure access to the Activity Hub, select which data will be available.",
    APPLICATION: "Application",
    CLOSING: "Closing",
    DO_STATUS_NOTIFICATIONS:
      "Send {company} webhook notifications about data orchestration statuses",
    EMAIL_NOTIFICATIONS: "Email Notifications",
    FUNDED: "Funded",
    MY_NOTIFICATIONS: "My Notifications",
    NOTIFICATIONS_DO:
      "Send me email notifications about data orchestration statuses",
    OFFER: "Offer",
    PLACEMENT: "Placement",
    PROGRESS_NOTIFICATIONS:
      "Send {company} webhook notifications about application progress",
    SAVED: "Notifications saved",
    SMARTVIEW_TOGGLE_LABEL:
      "Send a notification when a deal matches the parameters of the smartview",
    SMS_NOTIFICATIONS: "SMS Notifications",
    SUBTITLE:
      "This page will allow you to configure notifications you and your clients receive.",
    TITLE: "Notifications",
    UNDERWRITING: "Underwriting",
    WEBHOOK_NOTIFICATIONS: "Webhook Notifications",
    WIDGET_PROCESS: "Send me email notifications about application progress",
    WIDGET_PROCESS_DESCRIPTION:
      "When one of your customers moves to the following stages, you will receive an update email. These customizations will be reflected only on your account."
  },
  OFFERS: {
    COLUMNS: {
      DISCOUNT_RATE_PER_DAY: "{value} Day Discount Rate",
      ADVANCE_PAYMENTS: "Advance Payments",
      ADVANCE_RATE: "Advance Rate",
      BASE_RATE: "Base Rate",
      FACTOR_RATE: "Factor Rate",
      BUY_FACTOR_RATE: "Buy Factor Rate",
      BUY_INTEREST_RATE: "Buy Interest Rate",
      COMMISSION: "Commission",
      CREDIT_AMOUNT: "Credit @:COMMON.AMOUNT",
      DECLINE_REASON: "Offer Decline Reason",
      EXPENSE_DEPOSIT: "Expense Deposit",
      FACILITY_AMOUNT: "Facility @:COMMON.AMOUNT",
      FEE: "Fee",
      FILING_DATE: "Filing Date",
      FIRST_DRAW_COMMISSION: "First Draw Commission",
      FUNDED_DATE: "Funded Date",
      FUNDING_AMOUNT: "@:COMMON.FUNDING @:COMMON.AMOUNT",
      ID: "ID",
      INTEREST: "Interest",
      INTEREST_CHARGED: "Interest Charged",
      INTEREST_AMOUNT: "Interest Amount",
      INTEREST_AND_FEE: "Interest and Fees",
      APR: "APR",
      TIME_TO_FUNDING: "Time To Funding",
      TIME_TO_FUNDING_TIMEFRAME: "Time To Funding Timeframe",
      TERM_LENGTH_TIMEFRAME: "Term Length Timeframe",
      LOC: "Line of Credit",
      MAX: "Max {suffix}",
      MERCHANT_STATUS: "Merchant Status",
      MIN: "Min {suffix}",
      NUM_OF_PAYMENTS: "Number of Payments",
      OFFER_AMOUNT: "@:COMMON.OFFER @:COMMON.AMOUNT",
      OFFER_DATE: "@:COMMON.OFFER @:COMMON.DATE",
      OFFER_EXPIRATION_DATE: "Offer Expiration Date",
      ONBOARDING: "Onboarding",
      ORIGINATION_FEE: "Origination Fee",
      PAYBACK_AMOUNT: "Payback @:COMMON.AMOUNT",
      PAYMENT_AMOUNT: "@:COMMON.PAYMENT @:COMMON.AMOUNT",
      PAYMENT_FREQUENCY: "Payment Frequency",
      RENEWAL_FEE: "Renewal Fee",
      SELL_FACTOR_RATE: "Sell Factor Rate",
      SELL_INTEREST_RATE: "Sell Interest Rate",
      SENT_BY: "Sent by",
      SUBMISSION_DATE: "Submission Date",
      SUBSEQUENT_DRAW_COMMISSION: "Subsequent Draw Commission",
      TERM: "Term",
      TOTAL_COMMISSION: "Total Commission",
      VISIBILITY_ON_BP: "Visibility on BP"
    },
    DECLINE_REASON_MODAL_TITLE: "Why Was the Offer Declined?",
    EMPTY_HEADING: "Create an offer to send to applicant",
    EMPTY_SUBHEADING:
      "Create an offer by specifying product type and other details, including amounts, rates and funding calendar",
    NOTES: {
      ADDED: "@:OFFERS.NOTES.TITLE Added",
      DELETED: "@:OFFERS.NOTES.TITLE Deleted",
      DISPLAYED_IN_BP: "@:OFFERS.NOTES.TITLE (Displayed In Borrower Platform)",
      PROMPT_DESCRIPTION: "Borrower can see offer note in Borrower Platform",
      PROMPT_TITLE: "{funderName} @:OFFERS.NOTES.TITLE",
      TITLE: "Offer Note",
      TRIGGER_DESCRIPTION:
        "Borrower can see offer note in Borrower platform. Click on the icon to manage the note.",
      UPDATED: "Changes in @:OFFERS.NOTES.TITLE Saved"
    }
  },
  ORCHESTRATION: {
    ALL_CHECKS_FAILED: "all checks failed",
    ALL_FAILED_STEP: "All failed",
    APPLICATION_DATA: "Application Data",
    APPLY_TO_ALL: "APPLY TO ALL",
    ATTRIBUTE: "Attribute",
    ATTRIBUTES: "Attributes",
    CHOOSE_ATTRIBUTES: "Choose Attributes",
    CHOOSE_WORKFLOW: "Choose Workflow",
    CONDITIONAL_ATTRIBUTES_DELETED:
      "All conditional attributes will be deleted",
    CONDITION_MET: "Met Condition",
    CONDITION_NOT_MET: "Condition Not Met",
    CONFIDENCE_VALUE: "ID Completion Confidence Value",
    CONFIGURE: "Configure Orchestration",
    COPIED_PROVIDERS:
      "Copied {count} data provider | Copied {count} data providers",
    CREATE_TEMPLATE: "Create Template",
    CUSTOM_SELECTION: "Custom selection",
    DATA_SECTION: "Data section",
    DATA_SERVICE_DETAIL: "Data Service Detail",
    DEAL_FAILED: "Deal Failed",
    DEAL_FAILED_TO_COMPLETE:
      "Orchestration failed to complete. No outcome matched.",
    DEAL_MANUAL_REVIEW: "Deal Is On Manual Review",
    DEAL_PASSED: "Deal Passed",
    DELETE_TEMPLATE_TITLE: "Delete Orchestration Template",
    DELETE_TEMPLATE_WARNING:
      "{templateName} template will be permanently deleted. Are you sure?",
    DESCRIPTION: "Description (Optional)",
    DRAFT_MESSAGE: "Your draft has been saved.",
    DUPLICATE_TEMPLATE: "Duplicate Template",
    DUPLICATE_TEMPLATE_CONFIRM_MSG:
      "Are you sure you want to duplicate this template?",
    EDIT_TEMPLATE: "Edit Template",
    EDIT_TEMPLATE_FLOW: "Edit Template Flow",
    EDIT_TEMPLATE_INFO: "Edit Template Info",
    EVALUATED_ATTRIBUTES: "{attrs} attribute evaluated",
    EVALUATED_ATTRIBUTES_PLURAL: "{attrs} attribute evaluated",
    EXPAND_TOOLTIP: "Expand to see attribute details",
    EXPECTED_VALUE: "Expected Value",
    FAIL: "Fail",
    FAILED: "Failed:",
    FAILED_ATTRIBUTES: "Failed Attributes: {attributes}",
    FAILED_STEP: "Failed",
    FAIL_RESUME: "Fail-resume",
    FUNDER_RISK_TIERS: "Funder Risk Tiers",
    IN_PROGRESS: "In Progress",
    LAST_BLOCK_RAN: "Last Block Ran",
    LAST_NUMBER_MONTHS: "Last {months} months",
    "MANUAL REVIEW": "Review",
    MANUAL_STATUS: "Underwriting Status Updated Manually",
    MASTER: "Master",
    MASTER_STATUS_DESCRIPTION:
      "Master: This is current state of the orchestration flow as it moves to the end master status state. The master status will automatically map to one of six options depending on which point the orchestration is at. A user can manually override the overall result of the orchestration flow.",
    MAX_PLACEMENTS_COUNT: "Max number of placements",
    MONTHPICKER_ERROR: "Please select months range",
    MONTHPICKER_ERROR_MONTH: "Please select a month",
    MONTH_TO_DATE: "Month-to-date",
    NEW_TEMPLATE: "New Template",
    NOT_EVALUATED: "Not Evaluated",
    NOT_STARTED: "Orchestration Run is Not Started Yet",
    NO_DATA_TIP:
      "Check the spelling of the attribute or try to find it in another data service",
    NO_MET_STEPS: "There are no steps with met conditions.",
    NO_STEPS: "There are no steps.",
    NO_TEMPLATES: "No templates available",
    NO_UNMET_STEPS: "There are no steps with unmet conditions.",
    NO_WORKFLOW: "No Workflow",
    NUMBER_OF_BANK_STATEMENTS: "Number Of Bank Statements",
    OCROLUS_BOOK_SOURCE: "Ocrolus Book Source",
    ORCHESTRATION: "Orchestration|Orchestrations",
    ORCHESTRATION_OUTCOME: "Orchestration Outcome:",
    ORCHESTRATION_PAUSED: "Orchestration Paused",
    OUTCOME_NOT_AVAILABLE: "Outcome Not Available",
    OUTCOME_STATUS: "Outcome Status",
    PASS: "Pass",
    PASSED: "Passed:",
    PASSED_ATTRIBUTES: "Passed Attributes: {attributes}",
    PASS_RESUME: "Pass-resume",
    PASTED_PROVIDERS:
      "{count} data provider pasted | {count} data providers pasted",
    PAUSED: "Paused",
    PUBLISH: "Publish",
    PUBLISH_MESSAGE:
      "Your draft is being published. You will no longer have access to the draft.",
    REASON: "Reason: {reason}",
    REMOVE_AUTOMATIC: "@:COMMON.CLICK_TO Remove Automatic Run",
    REPLACE_DATA_SERVICE_TITLE: "Select Data Service to {action}",
    REPULL_DATA_MESSAGE: "Re-pull the data if it’s older than",
    REPULL_DATA_TOOLTIP:
      "The data will be refreshed if any record is older than the selected period of time.",
    RERAN_BY: "reran by",
    RESTART_ORCHESTRATION: "Restart @:ORCHESTRATION.ORCHESTRATION",
    RESUME: "Resume",
    RETRY_STEP_ACTION: "{stepName} step",
    RETURNED_VALUE: "Returned Value",
    REVIEW_STEP: "Review",
    RE_RUN_CONDITION: "Re-run Last Condition",
    RUN_BY: "run by",
    RUN_NEW: "Run New @:ORCHESTRATION.ORCHESTRATION",
    RUN_ORCH: "Run Orchestration",
    RUN_ORCH_PROMPT: "You’ve selected to run data orchestration manually.",
    SAVE_AS_DRAFT: "Save as Draft",
    SAVE_TEMPLATE: "Save Template",
    SELECT_MONTH: "Select Month",
    SELECT_RANGE: "Select Range",
    SELECTED_MONTHS: "Select prior months",
    SERVICE_IMAGE_ALT: "Data Service Image",
    SET_ATTRIBUTES: "Set Data Attributes",
    SET_AUTOMATIC: "Set as Automatic Run",
    SOURCE: "Source",
    STAGE_TRANSITIONS: "stage transitions",
    SUBTITLE: "Manage your orchestration templates here",
    SYNC: {
      ADD_SECONDARY: "Add Secondary",
      ADDITIONAL_BUSINESS_OPERATING_EXPENSE: "{action} Operating Expense",
      ADDITIONAL_BUSINESS_STATED_AVERAGE_BALANCE:
        "{action} Stated Average Balance",
      ADDRESS_LINE: "{action} @.lower:GEOGRAPHY.ADDRESS_LINE_1",
      ADDRESS_LINE2: "{action} @.lower:GEOGRAPHY.ADDRESS_LINE_1",
      AUTHORITY_TYPE: "{action} authority type",
      BASED_ON: "based on",
      BASED_ON_CONFIDENCE: "@:ORCHESTRATION.SYNC.BASED_ON ID confidence",
      BASED_ON_SCORE: "@:ORCHESTRATION.SYNC.BASED_ON score",
      BUSINESS_NAME: "{action} Business Name {addendum}",
      CARGO_CARRIED: "{action} cargo carried",
      CITY: "{action} city",
      DOB: "{action} date of birth {addendum}",
      DOT_NUMBER: "{action} USDOT number",
      EIN: "{action} EIN",
      ENTITY_TYPE: "{action} entity type",
      FIRST_NAME: "{action} first name",
      FULL_NAME: "{action} full name",
      FULL_ADDRESS: "{action} full address",
      FUNDING_ACTUAL_AVERAGE_MONTHLY_REVENUE:
        "{action} average monthly revenue",
      INSURANCE_ON_FILE: "{action} insurance on file",
      INSURANCE_REQUIRED: "{action} insurance required",
      LAST_NAME: "{action} last name",
      NAICS_CODE: "{action} NAICS Code",
      OPERATION_CLASSIFICATION: "{action} operation classification",
      OVERWRITE: "Overwrite",
      POWER_UNITS: "{action} power units",
      SET_AS_PRIMARY: "Set As Primary",
      SIC_CODE: "{action} SIC Code",
      SSN: "{action} SSN {addendum}",
      STATE: "{action} state",
      TELEPHONE: "{action} telephone",
      UPDATE: "Update",
      USDOT_STATUS: "{action} USDOT status",
      ZIP: "{action} zip"
    },
    TEMPLATE: "Orchestration Template",
    TEMPLATES: "Orchestration Templates",
    TEMPLATE_CREATED: "Template created",
    TEMPLATE_DELETED: "Template deleted",
    TEMPLATE_DUPLICATED: "Template duplicated",
    TEMPLATE_UPDATED: "Template updated",
    TRIGGER: "Trigger",
    UNCONDITIONAL_FAILURE:
      "Step ended in a default Failed state.  All user defined conditions failed.",
    UNDETERMINED: "Undetermined",
    UNUSABLE_STEP: {
      TITLE: "This block isn't usable anymore.",
      DESCRIPTION: "Please replace it."
    },
    USER_DEFINED_FAILURE:
      "Step ended in a user defined Failed state. User defined condition for Fail have been met.",
    USER_DEFINED_REVIEW:
      "Step ended in a user defined Review state. User defined condition for Review have been met.",
    VIEW_TEMPLATE_FLOW: "View Template Flow",
    WARNING_TEMPLATE_HAS_BLOCK_WITH_NO_FETCHING_DATA_ATTRS:
      "The block {blockName} does not have the required attributes specified to successfully run the service. Do you want to proceed?"
  },
  ORGANIZATION: {
    ADD_COUNTRY: "Add Country",
    ADD_OR_REMOVE_COUNTRIES: "Add or remove countries from your account",
    ADD_SALES_REP: "Add Sales Rep",
    ADD_USER: "Add User",
    API_CONFIG: {
      ACTIVITY_HUB_PLACEHOLDER: "Enter Activity Hub Link Here",
      ACTIVITY_HUB_WEBHOOK: "Webhook Activity Hub {type} Notifications",
      BANK_CONN_EMAIL_DESCRIPTION:
        "Lendflow sends emails to the applicant with a link to connect their bank account",
      BODY_DESCRIPTION:
        "Please enter the desired location that you would like to receive webhook-based notifications to.",
      CREDITDATA_PLACEHOLDER: "Enter Credit Data Link Here",
      CREDITDATA_WEBHOOK: "Webhook Credit Data",
      CREDIT_DATA: "Credit Application",
      DEALSTAGE_PLACEHOLDER: "Enter Deal Stage Link Here",
      DEALSTAGE_WEBHOOK: "Webhook Deal Stage Notifications",
      DEAL_OFFER: "Capital Application",
      DOCUSIGN_EMAIL_DESCRIPTION:
        "Lendflow sends emails to the applicant with a link to sign documents",
      DOCUSIGN_PLACEHOLDER: "Enter DocuSign Link Here",
      DOCUSIGN_WEBHOOK: "Webhook DocuSign Notifications",
      EMAIL_NOTIFICATIONS: "Email Notifications",
      SAVE_CHANGES: "Save Changes",
      SEND_EMAIL_DOCUSIGN: "Lendflow to fulfill e-signing of contract",
      SEND_EMAIL_PLAID:
        "Lendflow to send applicants emails for financial information",
      TITLE: "API Configuration",
      WEBHOOKS: "Webhook|Webhooks"
    },
    BUSINESS_CREDIT_DATA: {
      ALLOW_APPS_DATA: "Allow Business Credit applications",
      CONFIGURE:
        "Configure business credit data packages from third party platforms",
      CONFIGURE_ADDITIONAL:
        "Configure additional business credit data packages from third party platforms.",
      CONFIGURE_SERVICE: "Receive data packages from {service}",
      CONTACT:
        "Access to this service has not been enabled. Please reach out to {email} or your customer success manager for assistance.",
      CONTACT_ACCOUNT_MANAGER: "Contact Account Manager",
      CONTACT_CONFIGURE:
        "To configure additional business credit data please contact our account manager.",
      DAB: "D&B",
      DUNS: "DUNS Number",
      GIVE_ACCESS:
        "Give applications with Experian, D&B and Middesk Data access to marketplace.",
      INTELLISCORE: "Intelliscore",
      MAKE_APP_ACCESS: "Do not make applications access to marketplace",
      MIDDESK: "Middesk",
      RECEIVE_DAB: "Receive data packages from Dun & Bradstreet",
      RECEIVE_INTELLISCORE: "Receive data packages from Intelliscore",
      RECEIVE_MIDDESK: "Receive data packages from Middesk",
      SERVICES_SUBSCRIBED: "Business Credit services you are subscribed to:",
      TITLE: "Business Credit Data",
      TOTAL_MONTHLY_COST: "Total Monthly Cost:"
    },
    BYOC: {
      ENABLE: "Enable @:ORGANIZATION.BYOC.TITLE for the client",
      ENABLE_SUBTITLE:
        "Clients with @:ORGANIZATION.BYOC.TITLE enabled can use their own credentials to access the @:ORGANIZATION.BUSINESS_CREDIT_DATA.TITLE services.",
      TITLE: "Bring Your Own Credentials"
    },
    CLIENTS: "Clients",
    CLOSE_API: {
      API_KEY: "API key",
      DESCRIPTION: "Add API key to have separate organisation in Close",
      TAB_NAME: "Close API",
      TITLE: "Close configuration"
    },
    COMMISSIONS: {
      CONNECTED_PLAID: "Connected with Plaid",
      CONNECT_PLAID: "Connect using Plaid",
      FORM_W9: "Form W9",
      FORM_W9_NOT_UPLOADED: "Form W9 not uploaded",
      HEADLINE_BANK_ACCOUNT:
        "Business Bank Account for Commission Disbursement",
      HEADLINE_REVENUE_SHARE: "Revenue Share/Commission Payment details",
      PLAID_BANK_CONNECTION: "Plaid Bank Data",
      PLAID_CONNECTION_DESC: "Connect your data using Plaid Bank API.",
      REVENUE_SHARE_FROM_DEALS: "Revenue share from deals",
      TITLE: "Commissions",
      UPLOAD_FORM_W9: "Upload Form W9"
    },
    DATA_ORCHESTRATION: "Data Orchestration",
    DELETE_CLIENT: "Delete Client",
    DELETE_USER: "Delete User",
    DELETE_WARNING:
      " will be permanently removed from your organisation and won't be able to log in.",
    DO_ADD_ATTR: "Add attribute",
    DO_ADD_COND_GROUP: "Add conditions group",
    DO_ADD_DND_CONDITIONAL: "Add Conditional Argument",
    DO_ATTRIBUTES: "Attributes: {attrs}",
    DO_AUTO_POPULATE_OPPOSITE_CONDITION: "Add opposite condition",
    DO_CONDITIONAL_ARG: "Conditional Argument {num}",
    DO_CONNECT_TO_NEXT: "Connect to {step}",
    DO_DISCONNECT_FROM_NEXT: "Disconnect from {step}",
    DO_DND_SERVICE: "Drop Data service Here",
    DO_DND_SERVICE_EMPTY: "Click the + to add a data provider",
    DO_SAVE_TEMPLATE: "Save Template",
    EDIT_CLIENT: "Edit Client",
    EDIT_USER: "Edit User",
    EMAIL_TEMPLATES: {
      BODY: "Body",
      CC: "CC",
      CREATE_TITLE: "New @:ORGANIZATION.EMAIL_TEMPLATES.EMAIL_CUSTOMIZATION",
      DELETE_DESCRIPTION:
        "Are you sure you want to delete this @:ORGANIZATION.EMAIL_TEMPLATES.TITLE?",
      DELETE_TITLE: "Delete @:ORGANIZATION.EMAIL_TEMPLATES.EMAIL_CUSTOMIZATION",
      DETAIL: "Email Template Detail",
      DYNAMIC_FIELD_SYMBOL_EXPLANATION:
        "@:ORGANIZATION.DYNAMIC_FIELD_SYMBOL_EXPLANATION_SHORT for the subject, title, or body of the email",
      DYNAMIC_FIELD_SYMBOL_EXPLANATION_SHORT: "To enter a custom field",
      EDIT_TITLE: "Edit {name}",
      EMAIL_BRANDING: "Email Branding",
      EMAIL_CONTENT: "Email Content",
      EMAIL_CUSTOMIZATION: "Email Customization",
      EMAILS_INPUT_WARNING:
        "The {inputName} input contains invalid email addresses that will not be saved: {emails}",
      ENTER_LINK: "Enter Link",
      FROM_YOUR_ORGANIZATION: "From your organization",
      GLOBE_TOOLTIP: "Global",
      ORGANIZATION_CONTACTS: "Organization Contacts",
      ORGANIZATION_TOOLTIP: "Organization",
      MISSING_DATA: "Missing required data to send email.",
      MY_EMAIL: "My @:COMMON.EMAIL",
      NO_CONTENT_ADDED: "No Content Added.",
      SEND_EMAIL: "Send test email {address}",
      SEND_EMAIL_NO_DATA_MESSAGE:
        "The {fields} field is required to send an email | The {fields} fields are required to send an email",
      SET_AS_GLOBAL: "Set as global",
      SET_AS_ORGANIZATIONAL: "Set as organizational",
      SET_AS_PERSONAL: "Set as personal",
      SUBJECT: "Subject",
      SUBTITLE: "Preview and customize your emails",
      TEMPLATE_CONTENT: "Email Template Content",
      TITLE: "Email Template | Email Templates",
      UNSAVED_CHANGES_WARNING: "You have unsaved changes in the template",
      WIDGET_BRANDING: "Branding"
    },
    ENABLE_ATTRIBUTE_BUILDER: "Enable the Attribute Builder for the client",
    ENABLE_ATTRIBUTE_BUILDER_SUBTITLE:
      "Clients with enabled Attribute Builder can create custom attributes and offer templates.",
    ENABLE_BORROWER_PLATFORM: "Enable the Borrower Platform for the client",
    ENABLE_BORROWER_PLATFORM_SUBTITLE:
      "When enabled, this allows clients to access the BP integration page, redirect options and links.",
    ENABLE_CONTRACT_BUILDER: "Enable Contract Builder for the client",
    ENABLE_CONTRACT_BUILDER_SUBTITLE:
      "Clients with enabled Contract Builder can add documents from their DocuSign accounts to use them in the Lendflow system.",
    ENABLE_DATA_ORCHESTRATION: "Enable Data Orchestration for the client",
    ENABLE_DATA_ORCHESTRATION_SUBTITLE:
      "Clients with enabled Data Orchestration can create, edit, delete or run Data Orchestration templates.",
    ENABLE_EMAIL_TEMPLATES_BUILDER: "Enable Email Templates for the client",
    ENABLE_EMAIL_TEMPLATES_SUBTITLE:
      "Clients with enabled Email Templates can create, edit, delete or run Email templates.",
    ENABLE_SCORECARDS: "Enable the Scorecards for the client",
    ENABLE_SCORECARDS_SUBTITLE:
      "Clients with enabled Scorecards can create, edit, delete or run Scorecards templates.",
    ENABLE_WORKFLOW_BUILDER: "Enable the Workflow Builder for the client",
    ENABLE_WORKFLOW_BUILDER_SUBTITLE:
      "Clients with enabled Workflow Builder can create, edit, delete or run Workflow Builder templates.",
    EQUIPMENT_RENTAL: {
      ENABLE_RENTAL: "Enable Equipment Rental workflow for the client",
      HAS_GUARANTOR: "Has Guarantor",
      TAB_NAME: "Equipment Rental",
      TEMPLATE_ID: "Docusign Template ID",
      TYPE: "Type"
    },
    INQUIRIES: {
      BOP_SERVICES_SUBSCRIBED:
        "Business Owner Profile services you are subscribed to:",
      TITLE: "BOP Inquiries",
      TURN_ON_INQUIRIES: "Turn on all inquiries",
      VIEW_TITLE: "Business Owner Profile Inquiries"
    },
    NO_DATA_MESSAGE: "No client found",
    PASSWORD_SETTINGS: {
      PASSWORD_EXPIRATION_PLACHOLDER: "Password expiration time (days)",
      PASSWORD_EXPIRATION_TOGLE: "Enable password expiration",
      TAB_TITLE: "Password Settings",
      TOGGLE_SUBTITLE: "Set password expiration for accounts from your business"
    },
    SALES_REPRESENTATIVES: "Sales Representatives",
    SELECT_FUNDER: "Select Funder",
    SELF_FUNDING: "Client self-funding",
    SELF_FUNDING_SUB: "Select funder to be linked with this client",
    SSN_MASK: {
      BIG_TITLE: "Select the PII values to mask",
      TITLE: "Mask PII"
    },
    SUBTITLE:
      "This page will allow you to configure your organization settings.",
    TEST_MODE: {
      ALERT_MESSAGE: "You are in Test Mode!",
      DESCRIPTION: "Enable Test Mode for the client",
      EXIT_BUTTON_CANCEL_TEXT: "Stay in Test Mode",
      EXIT_BUTTON_CONFIRM_TEXT: "Go Live",
      EXIT_DESCRIPTION:
        "Once you leave test mode, your account will be live from that point forward.",
      EXIT_TEXT: "Go Live",
      EXIT_TITLE: "Do you want to leave Test Mode and Go Live?",
      TITLE: "Test Mode"
    },
    TITLE: "Organization",
    UNDERWRITING: "Underwriting",
    UNDERWRITING_DONE_BY_LENDFLOW:
      "The Underwriting process is completed by Lendflow",
    UNDERWRITING_DONE_BY_LENDFLOW_SUBTITLE:
      "When the toggle is off, clients are allowed to create underwriters. When the toggle is on, clients are not allowed to create underwriters.",
    USER_MANAGEMENT: "User Management",
    USER_MANAGEMENT_EMPTY: "No user found"
  },
  PERSON: {
    COUNTRY_OF_CITIZENSHIP: "Country of citizenship",
    DRIVERS_LICENSE_NUMBER: "Driver's License Number",
    DRIVERS_LICENSE_STATE: "Driver's LIcense State",
    INDIVIDUAL_TAXPAYER_IDENTIFICATION_NUMBER:
      "Individual Taxpayer Identification Number",
    ITIN: "ITIN",
    LEGAL_IMMIGRATION_STATUS: "Legal Immigration Status",
    NAME: "Name",
    OWNER: "Owner",
    PRIMARY_SPOKEN_LANGUAGE: "Primary Spoken Language",
    SIN: "SIN",
    SOCIAL_INSURANCE_NUMBER: "Social Insurance Number",
    SOCIAL_SECURITY_NUMBER: "Social Security Number",
    SSN: "SSN"
  },
  PERSONAL_CREDIT: {
    ACCOUNT_NUMBER: "Account Number",
    ADDRESSES: "Addresses",
    ADDRESS_COUNT: "Address Count",
    ADDRESS_DATE: "Address Date",
    ADDRESS_ERROR: "Address Error",
    ADJUSTMENT: "Adjustment",
    AMOUNT: "Amount",
    AMOUNT_PAST_DUE: "Amount Past Due",
    ASSET_AMOUNT: "Asset Amount",
    AVAILABLE_AMOUNT: "Available Amount",
    BALANCE_AMOUNT: "Balance Amount",
    BALANCE_DATE: "Balance Date",
    BANKRUPTCY: "Bankruptcy",
    BUSINESS_OWNER_WAS_NOT_FOUND: "Business Owner was not found.",
    CENSUS_GEO_CODE: "Census Geo code",
    CITY: "City",
    CODE: "Code",
    CONSUMER_IDENTITY: "Consumer Identity",
    COURT: "Court",
    DATE: "Date",
    DATE_OF_BIRTH: "Date of Birth",
    DATE_OF_DEATH: "Date of Death",
    DATE_REPORTED: "Date Reported",
    DEFINITION: "Definition",
    DELINQUENCIES_OVER_NINETY_DAYS: "Delinquencies Over 90 Days",
    DELINQUENCIES_OVER_SIXTY_DAYS: "Delinquencies Over 60 Days",
    DELINQUENCIES_OVER_THIRTY_DAYS: "Delinquencies Over 30 Days",
    DEROG_COUNTER: "Derog Counter",
    DETAIL: "Detail",
    DETAILS: "Details",
    DIFFERENT: "Different",
    ECOA: "Ecoa",
    EMPLOYER: "Employer",
    EMPLOYERS: "Employers",
    ENHANCED_PAYMENT_DATE: "Enhanced Payment Data",
    EVALUATION: "Evaluation",
    EXPERIAN_BOP: "BOP Regular",
    EXPERIAN_PDF: "BOP Report PDF",
    EXPERIAN_PREMIER_BOP: "BOP Premier",
    FILING_DATE: "Filing Date",
    FIRST_DELINQUENCY_DATE: "First Delinquency Date",
    FIRST_NAME: "First Name",
    FIRST_REPORTED_DATE: "First Reported Date",
    FRAUD_SERVICES: "Fraud Services",
    FULL_NAME: "Full Name",
    HARD_INQUIRY_TEXT:
      "The selected Experian model is a hard inquiry type. Hard inquiries may have an effect on users personal credit score. Do you still wish to proceed?",
    HARD_INQUIRY_TITLE: "Experian: Warning",
    INDICATORS: "Indicators",
    INQUIRIES: "Inquiries",
    INQUIRIES_DURING_LAST_SIX_MONTHS: "Inquiries During Last 6 Months",
    INQUIRY: "Inquiry",
    INSTALLMENT_BALANCE: "Installment Balance",
    KIND_OF_BUSINESS: "Kind of Business",
    LAST_NAME: "Last Name",
    LAST_PAYMENT_DATE: "Last Payment Date",
    LAST_REPORT_UPDATE: "Last Report Update",
    LAST_UPDATED_DATE: "Last Updated Date",
    LIABILITIES_AMOUNT: "Liabilities Amount",
    MAX_PAYMENT: "Max Payment",
    MIDDLE_NAME: "Middle name",
    MODEL_INDICATOR: "Model Indicator",
    MONTHLY_PAYMENT: "Monthly Payment",
    MONTHLY_PAYMENT_AMOUNT: "Monthly Payment Amount",
    MONTHLY_PAYMENT_PARTIAL: "Monthly Payment Partial",
    MONTHS_HISTORY: "Months History",
    NAME_TYPE: "Name Type",
    NAME_TYPES: "Name Types",
    OLDEST_TRADE_OPEN_DATE: "Oldest Trade Open Date",
    OPEN_DATE: "Open Date",
    ORIGINAL: "Original",
    ORIGINAL_CREDITOR_NAME: "Original Creditor Name",
    ORIGINATION: "Origination",
    PAID_ACCOUNTS: "Paid Accounts",
    PAYMENT_PROFILE: "Payment Profile",
    PERSONA: "Persona",
    PREPARED_AND_SAVED_DOCUMENT:
      "Already prepared and saved into your Documents",
    PROFILE_SUMMARY: "Profile Summary",
    PUBLIC_RECORD: "Public Record",
    REAL_ESTATE_BALANCE: "Real Estate Balance",
    REAL_ESTATE_PAYMENT: "Real Estate Payment",
    REAL_ESTATE_PAYMENT_PARTIAL: "Real Estate Payment Partial",
    RECORD: "RECORD",
    RECORDS: "RECORDS",
    REFERENCE_NUMBER: "Reference Number",
    REPAYMENT: "Repayment",
    REVOLVING_AVAILABLE_PARTIAL: "Revolving Available Partial",
    REVOLVING_AVAILABLE_PERCENT: "Revolving Available Percent",
    REVOLVING_BALANCE: "Revolving Balance",
    REVOLVING_OR_INSTALLMENT: "Revolving or Installment",
    RISK_LEVEL: "Risk Level",
    SAME: "Same",
    SATISFACTORY_ACCOUNTS: "Satisfactory Accounts",
    SCORE_FACTORS: "Score Factors",
    SECOND_DELINQUENCY_DATE: "Second Delinquency Date",
    SOCIAL_COUNT: "Social Count",
    SOCIAL_DATE: "Social Date",
    SOCIAL_ERROR: "Social Error",
    SSN: "SSN",
    SSN_POSSIBLE_ISSUANCE_YEAR: "SSN Possible Issuance Year",
    SSN_VARIATION_INDICATOR: "SSN Variation Indicator",
    STATE: "State",
    STATEMENT: "Statement",
    STATUS: "Status",
    STATUS_DATE: "Status Date",
    STREET_PREFIX: "Street prefix",
    STREET_SUFFIX: "Street suffix",
    SUBCODE: "Subcode",
    SUBSCRIBER: "Subscriber",
    SUBSCRIBER_ADDRESS: "Subscriber Address",
    SUFFIX: "Suffix",
    TERMS: "Terms",
    TIMES_REPORTED: "Times Reported",
    TOTAL_INQUIRIES: "Total Inquiries",
    TOTAL_TRADE_ITEM: "Total Trade Item",
    TOTAL_TRADE_ITEMS: "Total trade Items",
    TRADE_LINES: "Trade Lines",
    UNIT_ID: "Unit ID",
    ZIP: "ZIP",
    ZIP_EXTENSION: "ZIP extenstion"
  },
  PLACEMENT: {
    CHOOSE_FUNDERS: "Choose Funders and Send Application",
    PRODUCT_TYPE: "Product Type",
    RISK_TIER: "Risk Tier",
    SUBMIT_APPLICATION: "Submit Application",
    TITLE: "Placement"
  },
  PRESETS: {
    ADD_PRESET: "Add Preset",
    CATEGORIES: "Categories",
    CLIENTS: "Clients",
    DELETE_PRESET: "Delete Preset",
    DELETE_WARNING:
      "{preset} will be permanently removed from Lendflow. Are you sure?",
    DESCRIPTION_CLIENT:
      "This page will allow you to manage your presets for users.",
    DESCRIPTION_LENDFLOW:
      "This page will allow you to manage your presets for employees.",
    EDIT_PRESET: "Edit Preset",
    NO_PRESET: "No Preset",
    PRESET_NAME: "Preset Name",
    TITLE: "Presets"
  },
  PROODUCT_TYPES: {
    ARLOC: "ARLOC",
    EQUIPMENT_FINANCING: "Equipment Financing",
    LOC: "Line of Credit",
    REC_PURCHASE: "Receivables Purchase",
    TERM_LOAN: "Term Loan"
  },
  RELATIONS: {
    ADD_BUSINESS: "Add Business",
    ADD_BUSINESS_RELATION: "Add Business {relationType}",
    ADD_RELATION: "Add {relationType}",
    BUSINESS_ENTITY_NOT_AVAILABLE: "Business entity not available",
    COMPANY: "Company",
    ENTITY: "Entity",
    INFO: "Info",
    PARENT: "Parent",
    RELATIONSHIP: "relationship",
    REMOVE_RELATIONSHIP: "Remove relationship",
    TAB_NAME: "Relations",
    TITLE: "Relations",
    TREE_EMPTY: "There is no data for this profile at the moment.",
    TYPE_OF_BUSINESS_RELATIONSHIP: "Type of Business Relationship",
    TYPE_OF_RELATION: "Type of {relationType}",
    VIEW_PROFILE: "View Profile"
  },
  REPORTS: {
    CREDIT_DATA_USAGE: "Credit Data Usage",
    CUSTOMER_SUCCESS_NORTHSTAR_METRICS: "Customer Success Northstar Metrics",
    CUSTOM_REPORT: "Custom report",
    EMBEDDED_CLIENT_INTEGRATION: "Marketplace",
    EMBED_NORTHSTAR_METRICS: "EMBED Northstar Metrics",
    ERC_REPORTS: "ERC Reports",
    FLOW_NORTHSTAR_METRICS: "FLOW Northstar Metrics",
    FUNDING_SALES_NORTHSTAR_METRICS: "Funding Sales Northstar Metrics",
    FUSE_NORTHSTAR_METRICS: "FUSE Northstar Metrics",
    LENDER_RELATIONS: "Lender Relations And Underwriting",
    LENDFLOW_NORTHSTAR_METRICS: "Lendflow Northstar Metrics",
    MARKETING_NORTHSTAR_METRICS: "Marketing Northstar Metrics",
    ORIGINATION_REV: "Origination Revenue",
    PARTNER_SALES_NORTHSTAR_METRICS: "Partner Sales Northstar Metrics",
    UNDERWRITING_LENDER_RELATIONS_NORTHSTAR_METRICS:
      "Underwriting/Lender Relations Northstar Metrics"
  },
  ROLES: {
    ANALYST: "Analyst",
    FUNDING_ADVISOR: "Onboarding",
    UNDERWRITER: "Processor"
  },
  SCORECARDS: {
    ADD_ATTRIBUTES: "Add Attributes",
    ADD_BACKUP: "Add Backup",
    ADD_NEW_RISK_TIER: "Add New Risk Tier",
    ADD_NEW_SLICE: "Add New Slice",
    ADD_NEW_STATUS: "Add New Status",
    ADD_RULE: "+ Add Rule",
    AND_ABOVE: "And above...",
    ATTRIBUTE: "Attribute",
    ATTRIBUTES: "Attributes",
    ATTRIBUTE_POINTS: "@:SCORECARD.ATTRIBUTE Points",
    AUTO_DISTRIBUTION: "Automatic Distribution",
    AVAILABLE_PTS_RANGE: "Available Points Range {min}-{max}",
    BACKTEST_REPORT: {
      DELETE_DESCRIPTION:
        "Are you sure you want to delete this @:SCORECARDS.SC_BACKTEST_REPORTS?",
      DELETE_TITLE: "@:COMMON.DELETE @:SCORECARDS.SC_BACKTEST_REPORTS",
      PHASES: {
        "1": "Choose Scorecard",
        "2": "Select Deals",
        "3": "See Scorecard Report"
      },
      QUEUED:
        "The @:SCORECARDS.SC_BACKTEST_REPORTS has been queued. Please wait while it is generated."
    },
    BACKUP: "Backup",
    BACKUP_APPLIED: "@:SCORECARDS.BACKUP Applied",
    BACKUPS: "Backups",
    BACKUPS_RANGES: "Backups Ranges",
    BORROWER_TYPE: "Borrower Type",
    BORROWER_TYPE_OPTIONAL: "@:SCORECARDS.BORROWER_TYPE (Optional)",
    BUSINESS_ZIP_CODE_HINT: "Enter values separated by comma",
    CHOOSE_BT_REPORT: "Choose Backtest to See Report",
    CHOOSE_REPORT: "Choose Scorecard {group} to See Report",
    CHOOSE_TYPE: "Choose scorecard type",
    CREATE_CUSTOM_RISK_TIER: "Create Custom Risk Tier",
    CREATE_CUSTOM_STATUS: "Create Custom Status",
    CREATE_NEW: "Create New Scorecard",
    DATA_PROVIDER: "data provider",
    DEAD_STATUS: "Dead Status",
    DEALS_BY_RISK_TIERS: "Deals by Risk Tiers",
    DEALS_BY_STATUSES: "Deals by Statuses",
    DELETE_SC_DESCRIPTION: "Are you sure you want to delete this scorecard?",
    DELETE_SC_TITLE: "Delete Scorecard",
    DELETE_SLICE: "Delete Slice",
    DESCRIPTION: "Description",
    DESC_OPTIONAL: "Description (Optional)",
    DETAILS: "Scorecard Details",
    DISTRIBUTE_EQUALLY: "Distribute Equally",
    DISTRIBUTION: "Distribution",
    DRAFT: "Draft",
    DROP_HERE: "Add Attribute Here",
    EDIT_ATTRIBUTES_MODAL_TEXT_STEP_2:
      "Proceeding with these changes will reset all the updated attributes' customizations of the following steps:",
    EDIT_ATTRIBUTES_MODAL_TEXT_STEPS_1_3:
      "Proceeding with these changes will affect all customizations of the following steps:",
    EMPTY: "You don’t have any scorecards",
    FORM_FIELDS_AUTO: {
      DESCRIPTION: "Description (Optional)",
      NAME: "Scorecard Name"
    },
    FROM: "From",
    GROUP: "Group",
    GROUPS_REPORTS: "Group Reports",
    GROUP_PHASES: {
      "1": "Choose Scorecards",
      "2": "Set Order",
      "3": "Set Details",
      "4": "Set Weight",
      "5": "Scorecards",
      "6": "Add Rules"
    },
    GROUP_POINTS: "Group Points",
    GROUP_RISK_TIER: "Scorecard Group Risk Tier",
    GROUP_STATUS: "Scorecard Group Status",
    ID: "ID",
    INDICATOR: "Indicator",
    INVALID_VALUE: "The value is invalid",
    KILL_SCORECARD: "Kill Scorecard",
    KNOCKOUT: "Knockout",
    LOCK_ATTRIBUTE: "Lock Attribute",
    MANUAL: "Manual",
    MIN_MAX_SWAP_1: "Max Value is better than Min Value",
    MIN_MAX_SWAP_2: "Min Value is better than Max Value",
    NAME: "Scorecard Name",
    NEW_BT_REPORT: "New Backtest Report",
    NEW_SC_GROUP: "New Scorecard Group",
    NEW_TEMPLATE: "New Template",
    NOT_STARTED_YET: "Not started yet",
    NO_BACKUPS: "No backup assigned",
    NO_FUNDER_CLIENT: "There is no client attached to the selected funder",
    NO_POINTS_AVAILABLE: "Specify Points Amount to set points range",
    ORDER_SCORECARDS: "Order Selected Scorecards",
    PHASES: {
      "1": "Set Scorecard Details",
      "2": "Choose Attributes",
      "3": "Set Attributes Ranges",
      "4": "Add Backups",
      "5": "Set Backups Ranges"
    },
    POINTS: "Points",
    POINTS_AMT: "Points Amount",
    POINTS_PTS: "PTS",
    PRIMARY_ACCOUNTS_ONLY: "Primary Accounts Only",
    PROD_TYPE: "Product Type",
    PROD_TYPE_OPTIONAL: "@:SCORECARDS.PROD_TYPE (Optional)",
    PTS: "{points} PTS",
    PTS_RANGE: "Points Range",
    PTS_SPECIFIED: "{current}/{total} PTS specified",
    RANGE: "Range | Ranges",
    RERUN_SC: "Re-run Scorecard",
    RISK_TIER: "Risk Tier",
    RISK_TIERS: "Risk Tiers",
    RISK_TIER_DISPLAY: "{name} ({from}-{to})",
    RISK_TIER_NAME: "Risk Tier Name",
    RULE_ENTRY: {
      "1": "If",
      "2": "is",
      "3": "then apply",
      "4": "to scorecard group"
    },
    RUNS: "No. of Runs",
    RUN_ALL: "Run All",
    RUN_BT_REPORT: "Run Backtest Report",
    RUN_SC: "Run Scorecard",
    RUN_SPECIFIC: "Run Specific Scorecards",
    SAVE_BT_REPORT: "Save Backtest Report",
    SAVE_AS_DRAFT: "Save As Draft",
    SCG_SELECT_ONE: "Select at least one scorecard",
    SCORECARD: "Scorecard",
    SCORECARDS_MAIN_DESCRIPTION: "Manage your scorecards templates here",
    SCORECARDS_MAIN_TITLE: "Scorecards templates",
    SCORECARD_BUILDER_DESCRIPTION:
      "Define the score and run the calculations based on attributes and distribution.",
    SCORECARD_BUILDER_TITLE: "Scorecard Builder",
    SCORECARD_GROUP: "Scorecard Group",
    SCORECARD_GROUP_NAME: "Scorecard Group @:COMMON.NAME",
    SCORECARD_GROUP_REPORT: "Scorecard Group Report",
    SCORECARD_GROUP_STATUS: "Scorecard Group Status",
    SCORECARD_ID: "Scorecard ID",
    SCORECARD_POINTS: "Scorecard Points",
    SCORECARD_REPORT: "Scorecard Report",
    SCORECARD_RISK_TIER: "Scorecard Risk Tier",
    SCORECARD_VALUE_ERROR: "Scorecard '{name}' value is invalid",
    SCORE_MODEL: "Score Model",
    SC_BACKTEST_REPORTS: "Backtest Report | Backtest Reports",
    SC_GROUPS: "Scorecard Groups",
    SC_REPORTS: "Scorecard Reports",
    SC_SELECTED: "{total} Scorecards Selected",
    SEE_FULL_DEALS_LIST: "See these deals in full deals list",
    SEE_GROUP_DETAILS: "See Scorecard {group} Details",
    SELECT_ATTRIBUTES:
      "Select attributes from the left panel and set percentage for each one",
    SELECT_GROUP: "Select Scorecard {group}",
    SELECT_TO_RUN: "Pease select at least one scorecard to run",
    SET: "Set",
    SET_POINTS_FOR: "Set Points for: {min}-{max}",
    SERVICE: "Service",
    SLICE: "Slice",
    STATUS: "Status",
    STATUSES: "Statuses",
    STATUS_DISPLAY: "{status} ({from}-{to} PTS)",
    STATUS_NAME: "Status Name",
    SUBMIT_SCORE_DESCRIPTION:
      "Submit your own score based on the calculations you have run externally.",
    SUBMIT_SCORE_TITLE: "Submit Score",
    TILE_TOOLTIP:
      "The primary {type} is the {type} that defines the status and risk tier for all deals within a workflow",
    TITLE: "Scorecards",
    TO: "To",
    TOGGLE_AUTO_DISTRIBUTION: "Turn {value} Automatic Distribution",
    TOGGLE_KNOCKOUT:
      "Turn {value} Knockout: If category is absent kill scorecard",
    TOGGLE_MANUAL_RANGES: "Turn {value} Manual Ranges",
    TOTAL_PTS: "Total Points",
    TYPE: "Type",
    UNLOCK_ATTRIBUTE: "Unlock Attribute",
    UNSPECIFIED: "unspecified",
    UPDATE: "Edit Scorecard",
    VALUE: "Value",
    VALUE_BIGGER_THAN_TOTAL_ERROR:
      "The value should be less than or equal to {total}",
    WFB_TEMPLATE_REQUIREMENT:
      "Please select a funder and a product type to be able to select a workflow template"
  },
  SCORECARD_GROUPS: {
    ADD_RULES: "Add Scorecard Group Rules",
    ADD_RULES_SUBTITLE:
      "If scorecard has specific status or risk tier, then apply status to the whole scorecard group.",
    CREATE_NEW: "Create New Scorecard Group",
    DETAILS: "Scorecard Group Details",
    EDIT: "Edit Scorecard Group",
    EMPTY: "You don’t have any scorecard groups",
    GPTS: "{points} GPTS",
    GROUP_WEIGHT: "Group Weight",
    ID: "Scorecard Group ID",
    NAME: "Scorecard Group Name",
    POINTS_AMT: "Group Points",
    RERUN_SC_GROUP: "Re-run Scorecard Group",
    RULES: "Rules",
    RUN_ALL: "Run All",
    RUN_SC_GROUP: "Run Scorecard Group",
    RUN_SPECIFIC: "Run Specific Scorecard Groups",
    SCORECARDS_INCLUDED: "Scorecards Included",
    TOTAL_POINTS: "Total Group Points"
  },
  SERVICES: {
    OCROLUS: "Ocrolus",
    PLAID: "Plaid",
    REPAY: "Repay"
  },
  SIDEBAR: {
    BUSINESS_CREDIT_DEALS: {
      ALL: "All",
      DNB: "D&B",
      EXPERIAN: "Experian",
      MIDDESK: {
        DOCUMENT_SUBTITLE:
          "In sourcing filings directly from the source, Middesk also provides the ability to download documents related to these filings. Oftentimes, retrieving these documents can supersede the process of reaching out to an individual and requesting upload via a document capture flow.",
        DOCUMENT_TITLE: "Document",
        FOUND_ENTRIES: "Found Entries",
        REGISTRATIONS_SUBTITLE:
          "Many businesses will register to operate in multiple states. For every state that a business is registered in, Middesk returns a registration record.",
        REGISTRATIONS_TITLE: "Registration",
        REGISTRATIONS_UNKNOWN_TOOLTIP:
          "Although one or more records was returned in this section from Middesk, we aren't able to identify the current registration status",
        TITLE: "Middesk"
      },
      TITLE: "Business Credit Deals"
    },
    COMPANIES: "Companies",
    CUSTOMIZE: "Customize",
    DASHBOARD: "Dashboard",
    DEALS: {
      ACTIVITY: "Activity",
      DOCUMENTS: "Documents",
      FULL_APPLICATION: "Full Application",
      HISTORY: "History",
      PROGRESS: "Deal Progress",
      TITLE: "Deals"
    },
    DETAILS: "Details",
    DNB: {
      AVERAGE_HIGH_CREDIT_AMOUNTS: "Average High Credit Amounts",
      AVERAGE_OWING_AMOUNT: "Average Owing Amount",
      BAD_DEBT: "Bad Debt",
      BEFORE_TERMS: "Before Terms",
      BUSINESS_CREDIT: "Business Credit",
      BUSINESS_TRADING: "Business Trading",
      BUSINESS_TRADING_NORMS: "Business Trading Norms",
      COMMON: {
        ACCESS_RESTRICTED: "Access Restricted",
        FAILURE_INCIDENCE: "Failure Incidence",
        FINANCIAL_STRENGTH: "Financial Strength",
        HISTORY: "History",
        LEVEL_REQUIRED: "Level {levelRequired} is Required",
        LOADING_EXPERIENCE_CATEGORIES: "Loading experiencia categories",
        NATIONAL_PERCENTILE: "National Percentile",
        NET_EQUITY: "Net Equity",
        NO_EXPERIENCE_CATEGORIES_FOUND: "No experience categories found.",
        PEERS_PERCENTILE: "Peers Percentile",
        PROBABILITY: "Probability",
        RATING: "Rating",
        RATING_OVERRIDE_REASONS: "Rating Override Reasons",
        RATING_REASON: "Rating Reason",
        RAW_SCORE: "Raw Score",
        RISK_INCIDENCE: "Risk Incidence",
        RISK_SEGMENT: "Risk Segment",
        SCORE_COMMENTARY: "Score Commentary",
        SCORE_DATE: "Score Date",
        SCORE_MODEL: "Score Model",
        START_SERVICE: "Start Service"
      },
      CURRENT_MANNERED: "Current Mannered",
      DELINQUENCY_SCORE: {
        DESCRIPTION:
          "The details of the most recent scores assigned to the entity using a scoring model that helps predict the likelihood that the entity will pay its bills in a severely delinquent manner (90+ days past due) over the next 12 months.",
        NAME: "Delinquency Score"
      },
      DUNS_NUMBER: "Duns Number",
      EMERGING_MARKET_SCORE: {
        DESCRIPTION:
          "Records the output of the risk assessment model developed for emerging market countries which provides a score developed by data profiling approach and statistical molding, which predicts the possibility of a business in perilous status of instability or unreliability.",
        NAME: "Emerging Market Mediation Score"
      },
      EXPERIENCES: "Experience | Experiences",
      FAILURE_SCORE: {
        DESCRIPTION:
          "The details of the most recent scores assigned to the entity using a scoring model that helps predict the likelihood that the entity will seek legal relief from creditors or cease operations without paying all creditors in full within the next 12 months.",
        HISTORY: {
          NAME: "History"
        },
        NAME: "Failure Score"
      },
      FINANCIAL_CONDITION: "Financial Condition",
      FINANCIAL_STRENGTHS_INSIGHTS: "Financial Strengths Insights",
      HISTORY_RATING: "History Rating",
      INDUSTRY_NORMS: "Industry Norms",
      LAYOFF_SCORE: {
        DESCRIPTION:
          "Records information on the score that predicts the likelihood a business will lay-off a large number of employees in the next 6 months.",
        NAME: "Lay Off Score",
        PEERS_CLASS_SCORE: "Peers Class Score"
      },
      MAXIMUM_OWED_AMOUNT: "Maximum Owed Amount",
      MAXIMUN_HIGH_CREDIT_AMOUNT: "Maximum High Credit Amount",
      MAXIMUN_PAST_DUE_AMOUNT: "Maximum Past Due Amount",
      NEGATIVE: "Negative",
      PAST_DUE_EXPERIENCES: "Past Due Experience | Past Due Experiences",
      PAYDEX_LOWER_QUARTILE_SCORE: "Lower Quartile Score",
      PAYDEX_MEDIAN_SCORE: "Median Score",
      PAYDEX_SCORE: "Paydex Score",
      PAYDEX_SCORE_3_MONTHS_PRIOR: "Paydex Score 3 Months Prior",
      PAYDEX_SCORE_NORMS: "Paydex Score Norms",
      PAYDEX_UPPER_QUARTILE_SCORE: "Upper Quartile Score",
      PAYMENT_BEHAVIOR: "Payment Behavior",
      PAYMENT_BEHAVIOUR_NORMS: "Payment Behaviour Norms",
      PAYMENT_DAYS: "Payment Days",
      PAYMENT_INSIGHTS: "Payment Insights",
      PAYMENT_INSIGHT_LEVEL: "Payment Insights (Level 3)",
      PAYMENT_LOWER_QUARTILE_DAYS_QUANTITY: "Lower Quartile Days Quantity",
      PAYMENT_MEDIAN_DAYS_QUANTITY: "Median Days Quantity",
      PAYMENT_UPPER_QUARTILE_DAYS_QUANTITY: "Upper Quartile Days Quantity",
      PLACED_FOR_COLLECTION: "Placed For Collection",
      SATISFACTORY: "Satisfactory",
      SEVERE_NEGATIVE_EVENTS: "Severe Negative Events",
      SLOW: "Slow",
      SLOW_AND_NEGATIVE: "Slow or Negative",
      SLOW_AND_NEGATIVE_PAYMENTS: "Slow and Negative Payments",
      STANDARD_RATING: {
        DESCRIPTION:
          "The most current Standard Dun & Bradstreet Rating assigned to this entity.",
        NAME: "Standard Rating"
      },
      TABLE: {
        COUNT: "Count",
        DISTRIBUTION: "Distribution",
        EXPERIENCE_CATEGORIES: "Experience Categories",
        HIGHEST_CREDIT_AMOUNT: "Highest Credit Amount",
        TOTAL_AMOUNT: "Total Amount"
      },
      TOTAL_PAST_DUE_AMOUNT: "Total Past Due Amount",
      TOTAL_PAYMENT_AMOUNT: "Total Payment Amount",
      TOTAL_SLOW_NULL_DAYS_PAST_DUE_AMOUNT:
        "Total Slow Null Days Past Due Amount",
      TOTAL_SUPPLIERS: "Total Suppliers",
      UNFAVORABLE: "Unfavorable",
      VIABILITY_RATING: {
        AVAILABLE: "Available",
        BAD_RATE: "Bad Rate",
        CATEGORY: "Category",
        COMPANY_PROFILE: "Company Profile",
        DATA_DEPTH_INDICATOR: "Data Depth Indicator",
        DESCRIPTION:
          "The details of the most recent scores assigned to the entity using a scoring model compares the most predictive entity risk indicators to deliver a highly-reliable assessment of the probability that an entity will go out of business in the next 12 months.",
        FINAL_RATING: "Final Rating:",
        FINANCIAL_DATA: "Financial Data",
        INCIDENCE_PERCENTAGE: "Incidence Percentage",
        MODEL_SEGMENT: "Model Segment",
        NAME: "Viability Rating",
        NOT_AVAILABLE: "Not Available",
        ORG_SIZE: "Organization Size",
        OVERALL_BAD_RATE: "Overall Bad Rate",
        PORTFOLIO_COMPARISON: "Portfolio Comparison",
        RISK_LEVEL: "Risk Level",
        TRADE_DATA: "Trade Data",
        VIABILITY_SCORE: "Viability Score"
      }
    },
    EXPERIAN: {
      BIN: "BIN",
      BUSINESS_FACTS: {
        BIN: " BIN",
        BUSINESS_FACTS_TITLE: "Business Facts",
        BUSINESS_TYPE: "Business Type",
        CODE: "Code",
        CORPORATE_LINKAGE_TYPE: "Corporate Linkage Type",
        CUSTOMER_DISPUTE_INDICATOR: "Customer Dispute Indicator",
        DATE_OF_INCORPORATION: "Date of Incorporation",
        DBA_NAMES: "DBA Names",
        DESCRIPTION: "Description",
        EMPLOYEE_SIZE: "Employee Size",
        EXECUTIVE: "Executive",
        LEGAL_BUSINESS_NAME: "Legal Business name",
        NAICS_CODES: "NAICS Codes",
        NON_PROFIT_INDICATOR: "Non Profit Indicator",
        PHONE: "Phone",
        PUBLIC_INDICATOR: "Public Indicator",
        SALES_REVENUE: "Sales Revenue",
        SIC_CODES: "SIC Codes",
        STATE_OF_INCORPORATION: "State of Incorporation",
        TAX_ID: "Tax ID",
        WEBSITE_URL: "Website URL",
        YEARS_ON_FILE: "Years on File"
      },
      CODE: "Code",
      COMMERCIAL_SCORE: "Commercial Score",
      COMMERCIAL_SCORE_FACTORS: "Commercial Score Factors",
      COMMERCIAL_SCORE_TRENDS: "Commercial Score Trends",
      CUSTOM_MODEL_CODE: "Custom Model Code",
      DEFINITION: "Definition",
      DISPUTES: "Disputes",
      FINANCIAL_RISK_SCORE: "Financial Stability Risk Score",
      FINANCIAL_RISK_SCORE_V2: "Financial Stability Risk Score V2",
      FSR_SCORE: "FSR Score",
      FSR_SCORE_FACTORS: "FSR Score Factors",
      FSR_SCORE_TRENDS: "FSR Score Trends",
      FULL_ADDRESS: "Full Address",
      INTELLISCORE: "Intelliscore",
      LEGAL_NAME: "Legal Name",
      MODEL_CODE: "Model Code",
      MODEL_TITLE: "Model Title",
      PERCENTILE_RANKING: "Percentile Ranking",
      RECOMMENDED_CREDIT_LIMIT: "Recommended Credit Limit",
      RISK_CLASS: "Risk Class",
      SCORES: "Scores",
      SCORE_ERROR: {
        ERROR_998_MESSAGE:
          "The company or owner filed a bankruptcy in the last 24 months, and a score cannot be returned.",
        ERROR_998_TITLE: "998 score",
        ERROR_999_MESSAGE:
          "There was not enough information in the Experian databases to generate a score.",
        ERROR_999_TITLE: "999 score"
      },
      SUBMITED_DATA: "Submitted Data To Run API",
      TAX_ID: "Tax ID",
      TRADES: "Trades"
    },
    FUNDED_OFFERS: "Funded",
    FUNDERS: "Funders",
    INTEGRATIONS: "Integrations",
    NOTIFICATIONS: "Notifications",
    OFFERS: "Offers",
    PLATFORM_PARTNERS: "Platform Partners",
    PROFILE: {
      ACCOUNT: "Account",
      EMPLOYEES: "Employees",
      MY_ORGANIZATION: "My Organization",
      ORGANIZATION: "Organization",
      PIPELINE: "Pipeline",
      STIPS: "Stips",
      TITLE: "Profile"
    },
    RECENT_DEALS: "Recent Deals",
    REPORTS: "Reports",
    ROLE: {
      CURRENT: "Onboarding",
      REPORTING: "Reporting",
      SUB_TITLE: "Current Role",
      TITLE: "Role"
    },
    TASKS: "Tasks"
  },
  SMART_FILTERS: {
    ACTIVE_SMARTVIEWS: "Active Smartviews",
    ADD_FILTER: "New Smartview",
    ADD_ORGANIZATION_FILTER: "New Organization Smartview",
    ALL_SMARTVIEWS: "All Smartviews",
    ANALYST: "Analyst",
    APP_STARTED: "App Started",
    ASSIGNED_ONBOARDING_CALLS: "Assigned Onboarding Calls",
    AVAILABLE_FOR_ORG: "Available for organization",
    AVAILABLE_FOR_ORG_DESC:
      "This allows members of your organization to view the smartviews you have created.",
    BANK_DATA: "Bank Data",
    BANK_STATEMENTS: "Bank Statements",
    COLUMNS: "Columns",
    CONFIGURE: "Configure Deals Smartviews",
    DEAL_MODE: "Deal Mode",
    DEAL_STAGE: "Deal Stage",
    DELETE_FILTER: "Delete Filter?",
    DELETE_FILTER_DESC: "Are you sure you want to delete this filter?",
    DISCOVERY: "Discovery",
    DUPLICATE_FILTER: "Duplicate Smartview",
    EDIT_FILTER: "Edit Smartview",
    EDIT_ORGANIZATION_FILTER: "Edit Organization Smartview",
    EMAIL_HAS_ERROR: "Email has a validation error",
    EMPTY_ORGANIZATION: "No organization Smartviews have been created.",
    EMPTY_ORGANIZATION_ADMIN:
      "No organization Smartviews have been created. To create a new organization Smartview hit the 'New Organization Smartview' button.",
    EMPTY_PERSONAL:
      "You haven't created any Smartviews yet. To create a new Smartview hit the 'New Smartview' button.",
    EXCLUDE_MODE: "Exclude Mode",
    EXCLUDE_MODE_DESCRIPTION: "Exclude deals that match the filter criteria",
    FILTERS: "Filters",
    FILTER_REQUIRED: "at least one filter",
    FUNDED_DATE: "Funded Date",
    GLOBAL_LABEL: "global",
    HAS_OFFERS: "Deal Has Offers",
    HAS_PLACEMENTS: "Deal Has Placements",
    HAS_PLAID_CONNECTION: "Deal Has Plaid Connection",
    ID_REQUIRED: "Filter ID is required",
    IS_SIGNED: "Is Application Signed?",
    LENDFLOW_ANALYST: "Lendflow Analyst",
    LENDFLOW_ONBOARDING: "Lendflow Onboarding",
    LENDFLOW_PROCESSOR: "Lendflow Processor",
    NAME_REQUIRED: "name",
    NEW: "New",
    NOTIFICATIONS: "Notifications",
    NO_SMARTVIEWS_FOUND: "No Smartviews Found!",
    NUM_APPLIED: "{filterCount} Filter(s), {columnCount} Column(s)",
    NUMBER_OF_CALLS: "Number of Calls|No. of Calls",
    ONBOARDING: "Onboarding",
    ORGANIZATION: "Organization Smartviews",
    ORGANIZATION_DESCRIPTION:
      "Create and configure deals Smartviews for your organization here.",
    ORGANIZATION_FIELD: "Organization",
    ORGANIZATION_LABEL: "org",
    PARTNERS: "Partners",
    PERSONAL: "My Smartviews",
    PROCESSOR: "Processor",
    PROVIDE_FULL_CALLS_RANGE:
      "Please provide both min and max values for number of calls",
    SAVE_FILTER: "Save Smartview",
    SAVE_ORGANIZATION_FILTER: "Save Organization Smartview",
    SET_AS_ORG_SMARTVIEW: "Set as Organization Smartview",
    SET_CALLS_FILTER: "Set Calls Filter",
    SHOW: "Show",
    SMARTVIEWS: "Smartviews",
    SOURCE: "Source",
    STAGE_CONTROL: "Stage Control",
    STATED_MONTHLY_REVENUE: "Stated Monthly Revenue",
    STATES: "States",
    TAB_NAME: "Tab Name",
    TOTAL_ONBOARDING_CALLS: "Total Onboarding Calls",
    UNDERWRITING_STATUS: "Orchestration Status",
    UNSET_AS_ORG_SMARTVIEW: "Remove From Organization Smartview",
    WORKFLOW: "Workflow"
  },
  START_BUSINESS_CREDIT_APP: "Start Business Credit Application",
  STIPS: {
    ADD_DESCRIPTION: "Add description",
    ADD_STIPS: "Add stips",
    DELETE_ALL: "Delete All Stips",
    DELETE_FILE: "Delete document from stip?",
    DELETE_FILE_BODY:
      "{fileName} will be permanently removed from {stipName} stip.",
    DELETE_MODAL_BODY:
      "Stip {name} will be permanently removed from the system.",
    DELETE_MODAL_TITLE: "Delete Stip?",
    DELETE_REQUIRED_STIP: "Delete Required Stip",
    DELETE_WARNING: "This stip will be permanently deleted. Are you sure?",
    DESCRIPTION: "Description",
    DROP_TO_UPLOAD: "Drop file to upload",
    FILE_TYPE: "File type",
    MANAGE_DESCRIPTION: "@:COMMON.CLICK_TO manage Stip Description",
    NEW_STIP: "New Stip",
    NO_STIPS_SELECTED: "No stips selected",
    REQUIRED: "Required",
    SELECT_FILE_TYPE: "Select file type",
    STIP: "Stip",
    STIP_COLLECTION: {
      APPLICATION_STIPS: "Application Stips",
      AP_DOCUMENTS: "AP Documents",
      BANK_STATEMENTS: "Bank Statements",
      CFA_DATA: "CFA Data",
      CONTRACTS: "Contracts",
      DEBT_SCHEDULE: "Debt Schedule",
      DRIVERS_LICENCE: "Driver's Licence",
      FINANCIAL_DOCUMENTS: "Financial Documents",
      FUNDING_DOCUMENT: "Funding Document",
      INSURANCE_CERTIFICATES: "Insurance Certificates",
      OFFER_STIPS: "Offer Stips",
      OTHER: "Others",
      PAYROLL_REPORT: "Payroll Report",
      PLAID_STATEMENTS: "Plaid Statements",
      RECOVERED_DOCUMENT: "Recovered Verification Documents",
      RECOVERED_DOCUMENTS: "Recovered documents",
      SIGNED_APPLICATION: "Signed application",
      TAX_EXEMPTIONS: "Tax Exemptions",
      TAX_RETURNS: "Tax Returns",
      VERIFICATION_DOCUMENTS: "Verification Documents"
    },
    STIP_NAME: "Stip Name",
    SUBTITLE: "Edit stips and add descriptions",
    TITLE: "Stips",
    SHARE_STIPS_FUNDER: "Share Stips With Funder"
  },
  SUBDOMAIN_SETUP: {
    SAVE_BUTTON: "Save subdomain",
    SAVE_PLACEHOLDER: "Enter your subdomain address"
  },
  SUBMISSIONS: {
    CC: "Cc:",
    EMAIL_DEFINED_IN_WORKFLOW:
      "Email content is defined in the workflow settings.",
    ERROR: "Please select one or more emails",
    NO_DATA: "You don't have any submissions yet",
    SEND_TO: "Send to:",
    SUBJECT: "Subject:",
    TITLE: "Submissions"
  },
  TEMPORAL: {
    NINETY_DAYS: "90 Days",
    SIXTY_DAYS: "60 Days",
    THIRTY_DAYS: "30 Days"
  },
  TOUR: {
    DEALS: {
      ["1_DESCRIPTION"]: `Effortlessly tailor your queries with symbols like " * ", " ? ", " - ", " ~ " and " '' " for wildcard matching, single character search, exclusion, and approximate matching, respectively. Explore a smarter and more personalized search experience.`,
      ["1_TITLE"]: "Search Customization!",
      ["2_DESCRIPTION"]: `" * " represents any sequence of characters.<br>" ? " represents a single character in your search term.<br>" ~ "  indicating that the search should include similar terms.<br>" - " excludes certain words from the search results.<br>" ' ' "  performs an exact phrase match`,
      ["2_TITLE"]: "New Options",
      ["3_DESCRIPTION"]:
        "Hover over the symbol to view detailed instruction with example.",
      ["3_TITLE"]: "How to remember this?"
    }
  },
  TRADES: {
    EXPERIENCES: {
      COLUMNS: {
        ACCOUNT_BALANCE_AMOUNT: "Account Balance Amount",
        BUSINESS_CATEGORY: "Business Category",
        CURRENT_PERCENTAGE: "Current Percentage",
        CUSTOMER_DISPUTE_INDICATOR: "Customer Dispute Indicator",
        RECENT_HIGH_CREDIT_AMOUNT: "Recent High Credit Amount"
      },
      TYPES: {
        TRADE_ADDITIONAL: "Trade Additional",
        TRADE_NEW_AND_CONTINUOUS: "Trade New And Continuous"
      }
    },
    SUMMARY: {
      COLUMNS: {
        ALL_TRADELINE_BALANCE: "All Tradeline Balance",
        ALL_TRADELINE_COUNT: "All Tradeline Count",
        CURRENT_ACCOUNT_BALANCE: "Current Account Balance",
        CURRENT_DBT: "Current Dbt",
        CURRENT_TRADELINE_COUNT: "Current Tradeline Count",
        HIGHEST_DBT_FIVE_QUARTERS: "Highest Dbt 5 Quarters",
        HIGHEST_DBT_SIX_MONTHS: "Highest Dbt 6 Months",
        MEDIAN_CREDIT_AMOUNT_EXTENDED: "Median Credit Amount Extended",
        MONTHLY_AVERAGE_DBT: "Monthly Average Dbt",
        SINGLE_HIGH_CREDIT: "Single High Credit"
      }
    }
  },
  WORKFLOW: {
    ADD_TO_GLOBAL: "Add to Global",
    BILLING_TYPE: {
      TITLE: "Billing Type|Billing Types"
    },
    BLOCKS_DROPZONE_MESSAGE: "Drag & Drop Blocks Here",
    BLOCKS_DROPZONE_WITHIN_ANOTHER_BLOCK_MESSAGE:
      "Drag block here to add to the list",
    CATEGORIES: {
      CREDIT_REVIEW: {
        DESCRIPTION:
          "A workflow designed to allow you to assess personal or business credit data.",
        EXAMPLE:
          "Example: Company A is looking to review the credit history of a person, business, or affiliated entities in order to make a decision to approve an entity for a loan.",
        NAME: "Credit Review"
      },
      DATA_COLLECTION: {
        DESCRIPTION:
          "A workflow designed to allow the definition of the application stage of the workflow here.",
        EXAMPLE:
          "Example: Company A is looking to gather the business information of a company, the personal information of its owner(s), and any prequalifying information on the company as well.",
        NAME: "Data Collection"
      },
      INTERNAL_FUNDING: {
        DESCRIPTION:
          "A workflow designed to allow you to issue a credit or loan product.",
        EXAMPLE:
          "Example: Company A is a large company that is starting a lending program to provide its own capital to its business clients who want to purchase goods from them.",
        NAME: "Internal Funding"
      },
      MARKETPLACE: {
        DESCRIPTION:
          "A workflow designed for offering a number of different financial products and services that are delivered by third parties.",
        EXAMPLE:
          "Example: Company A submits a deal to the Lendflow marketplace to be matched with a lender to receive funding for their business. The New Design Company sends a deal to the Lendflow marketplace, and The West Lender offers funding for that deal from the business.",
        NAME: "Marketplace"
      },
      RENTAL: {
        DESCRIPTION:
          "A workflow designed to allow you to issue rental products.",
        EXAMPLE:
          "Example: Company A offers loans for the rental of equipment as a form of financing. For instance, this loan can be used to rent a concrete mixer.",
        NAME: "Rental"
      }
    },
    CHOOSE_WORKFLOW_TYPE: "Choose workflow type",
    CREATE_NEW_TEMPLATE: "Create New Template",
    CREATING: "Creating Template",
    DELETE_TITLE: "Delete Workflow Template",
    DELETE_WARNING: "{name} will be permanently deleted. Are you sure?",
    DOCUMENT_SYNC: {
      DESCRIPTION:
        "Any documents uploaded will be synced automatically from origin deal to destination deal if there's a maximum of 1 funder in the placement stage.",
      TITLE: "Document Syncing"
    },
    ENABLE_BP_CUSTOMIZATION: "Use Custom Borrower Platform Template",
    ERROR_MESSAGES: {
      AT_LEAST_ONE_BLOCK_IN_TAB:
        "• Tab {tab} in {stage} stage must contain at least one block",
      AT_LEAST_ONE_DEAD_STATUS_IN_STAGE:
        "• Workflow stage {stage} must contain at least one dead status",
      AT_LEAST_ONE_PRODUCT_TYPE:
        "At least one enabled product type is required",
      AT_LEAST_ONE_TAB_IN_STAGE:
        "• Workflow stage {stage} must contain at least one tab",
      FIRST_STAGE_MUST_BE_DATA_COLLECTION:
        "• First stage must be a data collection stage",
      NO_DATA_COLLECTION_STAGE:
        "• Workflow must contain at least one data collection stage",
      PRIMARY_CARD_IS_ONE_OF_CARDS:
        "• Primary Scorecard in {stage} must be the one from selected scorecards ",
      PRIMARY_GROUP_IS_ONE_OF_CARDS:
        "• Primary Scorecard Group in {stage} must be the one from selected scorecards groups \n",
      QUESTIONS_CANNOT_BE_EMPTY: "Discovery Question title cannot be empty",
      STATUS_CANNOT_BE_EMTPY:
        "• Dead statuses in stage {stage} cannot be empty. To delete, hit the trash icon",
      SUBSTATUS_CANNOT_BE_EMTPY:
        "• Dead sub-statuses in stage {stage} cannot be empty. To delete, hit the trash icon"
    },
    GLOBAL: "Global",
    GLOBAL_TOGGLE_DESCRIPTION:
      "A Global Template will be Available to All Users as a Guide",
    MANAGED_BY_LENDFLOW: "Set as Managed by Lendflow",
    MANAGED_BY_LENDFLOW_TOGGLE_DESCRIPTION:
      "Turn this toggle on if the WFB is controlled by the Lendflow Managed Marketplace",
    MANUALLY_SELECT_OFFERS:
      "Automatically hide Offers on the Borrower Platform",
    MANUALLY_SELECT_OFFER_DESCRIPTION:
      "Toggle on to automatically hide offers from the Borrower Platform. Users will have to manually expose each offer on the Borrower Platform",
    MODALS: {
      ACTIVE_STATUS: {
        DESCRIPTION:
          "Only one Workflow Builder template per SDK can be active. Click 'Update' to replace active template from [%s]  to %s",
        TITLE: "Active Status Update"
      }
    },
    NEW_TEMPLATE: "New Template",
    NOTIFICATIONS: {
      EMAIL_BRANDING: "Email Branding",
      EMAIL_DOMAIN: "Email Domain",
      NOTIFICATION_ENTRY: "Send {group} {type} notifications about {name}",
      EMAIL_TEMPLATE: "Email Template",
      NOTIFICATION_DESCRIPTION:
        "This page will allow you to configure {type} Notifications you and your clients receive.",
      SMS_BRANDING: "SMS Branding (coming soon)",
      SMS_TEMPLATE: "SMS Template (coming soon)",
      WEBHOOK_ENTRY: "Send me Webhook notifications about {name}",
      WEBHOOK_TITLE: "Webhook Notifications"
    },
    PLACEMENT_SETTINGS: {
      AUTOMATION: {
        SETTINGS: "Automation Settings",
        CRITERIA_DESCRIPTIONS:
          "This field along with 'Type' define the criteria at which the placements will be automated.",
        DESCRIPTION:
          "Ensure the appropriate product types are configured at the placement stage before configuring the automation criteria below.",
        PLACEMENTS_DESCRIPTION:
          "Maximum number of placements that will be sent in the automation. Automation will stop and no more placements will be sent if this number is reached.",
        RISK_TIERS_DESCRIPTION:
          "These risk tiers are sourced from the Funder record.",
        TITLE: "Scorecard to Placement Automation Criteria"
      },
      FUNDER_TO_PLACE_WITH: "Funder to Place the Deal with",
      FUNDER_WFB_TEMPLATE: "Funder WFB Template",
      PLACEMENT_AUTOMATION: "Place Deal with Funder upon Deal Creation",
      PLACEMENT_AUTOMATION_DESCRIPTION:
        "Upon deal creation, the deal will be moved to the Placement stage and a Placement will be sent to the specified funder.",
      PLACEMENT_AUTOMATION_DROP_OFF:
        "Place Deal with Funder upon Widget Drop-off",
      PLACEMENT_AUTOMATION_DROP_OFF_DESCRIPTION:
        "When the applicant drops off any widget, the deal will be moved to the Placement stage and a Placement will be sent to the specified funder. Drop off is defined as the applicant has not finished the widget, or the widget has not received any user activity in 10+ mins.",
      PLACEMENT_AUTOMATION_FINISHED:
        "Place Deal with Funder upon Widget Completion",
      PLACEMENT_AUTOMATION_FINISHED_DESCRIPTION:
        "When the applicant completes the final screen of the widget, the deal will be moved to the Placement stage and a Placement will be sent to the specified funder.",
      STAGES_REQUIRED: "Placement stage is required for Placement Automation"
    },
    PRODUCT_TYPES: {
      BEVEL_CUSTOM_FUNDING: "Funding Widget - Bevel Custom",
      EQUIPMENT_RENTAL: "Equipment Rental",
      ERC_WIDGET: "ERC Widget",
      ERC_WIDGET_LONG: "ERC Widget Long",
      INVOICE_FACTORING: "Invoice Factoring",
      INVOICE_FACTORING_CODAT: "Invoice Factoring (Codat)",
      INVOICE_FACTORING_RAILZ: "Invoice Factoring (Railz)",
      LEAD_CAPTURE: "Lead Capture",
      LENDING_WIDGET_WITHOUT_SIGNED_APP: "Lending Widget (Without Signed App)",
      LENDING_WIDGET_WITHOUT_SIGNED_APP_AND_BANKS:
        "Lending Widget (Without Signed App & Banks)"
    },
    REMOVE_FROM_GLOBAL: "Remove from Global",
    REQUIREMENTS_SETTINGS: {
      DESCRIPTION:
        "Select the information that is required on the application in order to initiate the automation. If multiple checkboxes are selected, ALL those pieces of information will be required to trigger the automation.",
      TITLE: "Application Automation Criteria"
    },
    SETTINGS: "Workflow Settings",
    STATUSES: {
      DEAD_TITLE: "Dead Statuses",
      NEW_STATUS_BUTTON: "Add {type} Status",
      NEW_STATUS_PLACEHOLDER: "+ Add Status",
      NEW_SUBSTATUS_PLACEHOLDER: "+ Add Sub-Status",
      SET_COLOR: "Select Status Color",
      TITLE: "Status | Statuses"
    },
    STEPS: {
      CATEGORY: "Category",
      COMPLETE: "Complete",
      CONFIGURE: "Configure",
      NAME_AND_DESC: "Name & Description"
    },
    SUBMISSION: {
      DESCRIPTION: "Central hub for managing Email Submission events.",
      TITLE: "Submission | Submissions",
      TOGGLE_TITLE: "Email Submission",
      TOGGLE_DESCRIPTION:
        "Detailed view of individual email submissions for current workflow."
    },
    SUBTITLE: "Manage your workflow templates here",
    TABLE_HEADERS: {
      ACTIVE: "Active Status",
      APPROVAL_STATUS: "Approval Status",
      CATEGORY: "Workflow Category",
      DESCRIPTION: "Description",
      MANAGED_BY: "Managed By",
      NAME: "Name",
      ORGANIZATION: "Organization",
      STAGES: "Stages",
      TEMPLATE_ID: "Template ID",
      TYPE: "Type",
      UPDATED: "Updated",
      WIDGET: "Widget"
    },
    TEMPLATES: "Workflow Template | Workflow Templates",
    TEMPLATE_BRANDING: "SDK Branding",
    TEMPLATE_CATEGORIES: "Workflow Categories",
    TEMPLATE_DESCRIPTION: "Description (optional)",
    TEMPLATE_DOMAIN: "Domain",
    TEMPLATE_EMAIL: "Email Branding",
    TEMPLATE_ID: "Workflow Template ID",
    TEMPLATE_NAME: "Workflow Template Name",
    TEMPLATE_WIDGET: "Widget",
    TURN_ON_ORCHESTRATION: "Automate Running Stages",
    TURN_ON_ORCHESTRATION_DESCRIPTION:
      'Deals with the "Application Complete" status will automatically move to the Underwriting stage. If the DO master status = PASS, the deal will move to the Scorecards stage. The primary scorecard & primary scorecard group will be run. If scorecard automation criteria is set and the conditions are met, the deal will move to the placement stage and the relevant placements will be sent to funders.',
    TYPES: {
      DYNAMIC: {
        DESCRIPTION:
          "All stages will be defined in the workflow builder. The order of the stages will be determined by the user when the live deal is being processed.",
        NAME: "Dynamic"
      },
      LINEAR: {
        DESCRIPTION:
          "All stages and the order of the stages will be defined in the workflow builder.",
        NAME: "Linear"
      }
    },
    UNKNOWN_BLOCK: "Unknown Block",
    WIDGET_AND_BRANDING: "Widget & Branding",
    WORKFLOW_TYPE: "Workflow Type",
    WORKFLOW_SEGMENT: "Workflow Segment"
  },
  WORKFLOWS: {
    WORKFLOW_BUILDER: {
      ACCEPT_MORE_THAN_ONE_OFFER:
        "Allow Applicants to Accept More Than One Offer",
      ADDITIONAL_DETAILS: "Additional details",
      ADD_NEW_QUESTION: "Add new question",
      ADD_TAB: "Add Tab",
      ASSIGN_DO: "Assign Data Orchestration Template",
      BRANDING: "Branding",
      BRANDING_TOOLTIP: "Change Domain, SDK, Email",
      CHANGE_TEMPLATE_CATEGORY: "Change Template Category",
      CHANGE_WORKFLOW_TYPE: "Change Workflow type",
      CHOSE_PRODUCT: "Choose product type for this workflow",
      CHOSE_PRODUCT_FOR_OFFER: "Choose product types for offers",
      CONFIRM_STAGE_CHANGE:
        "Are you sure you want to make changes to the template? The existing Borrower Platform customization will be reset.",
      CREATE_STAGE_TITLE: "Create New Stage",
      DATA_COLLECTION: "Data Collection",
      DATA_ENRICHMENT: "Data Enrichment",
      DELETE_BLOCK_DESCRIPTION: "{block} block will be removed from this tab",
      DELETE_BLOCK_TITLE: "Delete Workflow Block",
      DELETE_STAGE_TITLE: "Delete Stage",
      DELETE_STAGE_WARNING:
        "Are you sure you want to delete this stage? The existing Borrower Platform customization will be reset.",
      DELETE_TAB_TITLE: "Delete Tab",
      DELETE_TAB_WARNING: "Are you sure you want to delete this tab?",
      DISABLED_CLOSING_MESSAGE: "The Closing stage can be added just once",
      DISABLED_SCORECARDS_MESSAGE:
        "The Scorecards feature should be enabled for your account in order to add the stage",
      DISCOVERY_QUESTION: "Discovery Question",
      EDIT_STAGE: "Edit Stage",
      EDIT_WORKFLOW_DETAILS: "Edit",
      EDIT_WORKFLOW_NAME_TITLE: "Edit Workflow Name",
      FUNDER_NAME: "Funder Name",
      HIDE: "Hide",
      MASK_FUNDER_DETAILS: "Mask Funder Details",
      MASK_FUNDER_DETAILS_DESC:
        "If the toggle is enabled, the funder's details will be hidden from the offer on the borrower platform.",
      MIN_FIELD: "At least one field must be visible",
      MODAL_CHANGING_STAGE_CATEGORY:
        "Changing the stage category will result in losing all configured tabs and blocks. Are you sure you want to proceed?",
      MODAL_STAGE_CATEGORY: "Change Stage Category",
      NAME_OF_CUSTOM_DECLINE_REASON: "Name of Custom Decline Reason",
      NOT_ASSIGNED: "Not assigned yet",
      OFFER_DECLINE_REASON_REQUESTING_FEEDBACK:
        "Decline Reason Requesting Borrower Feedback",
      OFFER_DECLINE_REASONS: "Offer Decline Reasons",
      PRIMARY_SCORECARD_MESSAGE:
        "Make Primary: make the card a priority when deciding on a deal",
      PRODUCT_TYPE: "Product type",
      PRODUCT_TYPES: "Product Types",
      REQUIRED: "Required",
      SAVE_TEMPLATE: "Save Template",
      SCORECARDS_MAIN_TITLE: "Choose Default Scorecards Templates",
      SELECT_STAGE_MANAGER: "Select who is responsible to complete the stage",
      SERVICE_PROVIDERS: "Service Providers",
      SIGNED_APPLICATION: "Signed Application",
      STAGE_NAME: "Stage Name",
      TAB_NAME: "Tab Name",
      TEMPLATE_AS_DEFAULT: "The template will be used as the default.",
      TEMPLATE_AS_DEFAULT_CONFIRM_DESCRIPTION:
        'Currently, "{templateName}" is the default template. Do you want to set this template as the default instead?',
      TEMPLATE_AS_DEFAULT_CONFIRM_TITLE: "Change Default Template?"
    }
  }
};

export default EN;
